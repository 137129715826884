import { convertFile, downloadFile } from "@/utils/function";
import { ExcelBox } from "./Style";
import { common } from "@text";

const {
  excel: { img, text },
} = common;

const Excel = ({ link }) => {
  const fileName = link.split("/").pop();

  return (
    <ExcelBox onClick={() => downloadFile(convertFile(link), fileName)}>
      <img src={img} alt="excel" />
      <span>{text}</span>
    </ExcelBox>
  );
};

export default Excel;
