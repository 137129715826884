import styled from "styled-components";
import { CustomLink } from "@/styles/common";

export const MenuContainer = styled.menu`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  ${(props) => props.theme.sort.spaceBetween};
  ${(props) => props.theme.sort.center.height};
  flex-direction: column;

  width: ${(props) => props.theme.pxToRem(260)};
  height: 100vh;

  padding: ${(props) =>
    `${props.theme.pxToRem(76)} 0 ${props.theme.pxToRem(24)}`};
  border-right: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.layout};

  background: ${(props) => props.theme.colors.white};

  ${(props) => props.theme.medias.mobile} {
    width: ${(props) => (props.isOpen ? `100vw` : `0`)};
    overflow: hidden;
  }
`;

export const MenuAdminButton = styled.button`
  width: 200px;
  height: ${(props) => props.theme.pxToRem(48)};

  margin-top: ${(props) => props.theme.pxToRem(100)};
  margin-left: auto;
  margin-right: auto;

  border-radius: 10px;
  color: white;

  background-color: ${(props) => props.theme.colors.primary};
`;

export const MenuLogoImage = styled.img``;

export const MenuContent = styled.div`
  ${(props) => props.theme.sort.center.width};
  flex-wrap: wrap;

  ${(props) => props.theme.medias.mobile} {
    width: 100%;
    overflow: hidden;
  }
`;

export const MenuLink = styled(CustomLink)`
  width: auto;
`;

export const MenuCloseButton = styled.button`
  position: absolute;
  top: ${(props) => props.theme.pxToRem(24)};
  right: ${(props) => props.theme.pxToRem(24)};
  z-index: 100;

  display: none;

  ${(props) => props.theme.medias.mobile} {
    display: block;

    width: ${(props) => props.theme.pxToRem(24)};
    height: ${(props) => props.theme.pxToRem(24)};
  }
`;
