import { useFormContext } from "react-hook-form";
import { ContentArea, ContentContainer, ContentTitle } from "../Style";

const Button = ({ config: { title, required }, children }) => {
  const { watch } = useFormContext();
  const { isEdit } = watch();

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>{children}</ContentArea>
    </ContentContainer>
  );
};

export default Button;
