import styled from "styled-components";

export const ListTableItem = styled.td`
  padding: ${(props) => props.theme.pxToRem(13)} 0;
`;

export const ListTableButton = styled.button`
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.black};
`;
