import { FormProvider, useForm } from "react-hook-form";
import { admin } from "@/text";
import ArtistInput from "@/components/common/tableForm/artistCreateInput";
import { Form, FormSubmitButton } from "@/styles/common";
import { ArtistCreateContainer, ArtistCreateTitle } from "./Style";
import { useCreateArtistMutation } from "@/hooks/api/artist";

const {
  artist: {
    create: {
      title,
      button,
      alert: { check, success, fail },
      required,
    },
  },
} = admin;

const defaultValues = {
  id: "",
  idCheck: false,
  password: "",
  passwordConfirm: "",
  nameKor: "",
  nameEng: "",
  isEdit: true,
};

const useArtistForm = () => {
  const form = useForm({
    defaultValues,
  });
  const successCallback = () => {
    window.alert(success);
    form.reset(defaultValues);
  };

  const failCallback = () => {
    window.alert(fail);
  };

  const createArtistMutation = useCreateArtistMutation(
    successCallback,
    failCallback
  );

  const { handleSubmit, getValues } = form;

  const checkId = () => {
    const { idCheck } = getValues();

    if (!idCheck) {
      window.alert(check);
      return false;
    } else {
      return true;
    }
  };

  const submit = (data) => {
    if (checkId()) {
      createArtistMutation(data);
    }
  };

  return {
    form,
    handleSubmit: handleSubmit(submit),
  };
};

const ArtistCreate = () => {
  const { form, handleSubmit } = useArtistForm();

  return (
    <FormProvider {...form}>
      <ArtistCreateContainer>
        <ArtistCreateTitle>{title}</ArtistCreateTitle>

        <Form onSubmit={handleSubmit}>
          <ArtistInput required={required} />
          <FormSubmitButton type="submit">{button.submit}</FormSubmitButton>
        </Form>
      </ArtistCreateContainer>
    </FormProvider>
  );
};

export default ArtistCreate;
