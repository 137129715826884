export const ZERO = "0";
export const NUMBER_ZERO = 0;
export const BLANK = "";

export const UNSETTLEMENT = "미정산";

export const SETTLEMENT_TYPE = {
  regular: "REGULAR",
  request: "REQUEST",
};

export const STATUS = {
  alert: "alert",
  confirm: "confirm",
  prompt: "prompt",
  success: "success",
  fail: "fail",
};

export const TYPE = {
  object: "object",
  string: "string",
};

export const ROLE = {
  artist: "artist",
  admin: "admin",
};
