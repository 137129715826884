import useToggle from "@/hooks/utils/useToggle";
import {
  MenuIcon,
  MenuItemBox,
  MenuItemButton,
  MenuItemContainer,
  MenuLink,
  MenuSubItem,
  MenuSubList,
} from "./Style";
import useUrl from "@/hooks/utils/useUrl";

const MenuItem = ({ isSub, icon, whiteIcon, link, title, subList }) => {
  const { path } = useUrl();
  const isCurrent = path[1] === link;
  const [isOpen, toggleOpen] = useToggle(isCurrent);

  return (
    <MenuItemContainer isCurrent={isCurrent}>
      {isSub ? (
        <>
          <MenuItemButton onClick={toggleOpen}>
            <MenuItemBox isCurrent={isCurrent}>
              <MenuIcon
                src={isCurrent ? whiteIcon : icon}
                alt={`${title}-icon`}
              />
              {title}
            </MenuItemBox>
            {isOpen ? (
              <img
                src={`/assets/menus/${isCurrent ? "white_" : ""}up.svg`}
                alt="menu-icon"
              />
            ) : (
              <img
                src={`/assets/menus/${isCurrent ? "white_" : ""}down.svg`}
                alt="menu-icon"
              />
            )}
          </MenuItemButton>

          <MenuSubList isOpen={isOpen} length={subList.length}>
            {subList.map(({ title, link }) => (
              <MenuSubItem key={title}>
                <MenuLink
                  to={link}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {title}
                </MenuLink>
              </MenuSubItem>
            ))}
          </MenuSubList>
        </>
      ) : (
        <MenuLink to={link}>
          <MenuIcon src={isCurrent ? whiteIcon : icon} alt={`${title}-icon`} />
          {title}
        </MenuLink>
      )}
    </MenuItemContainer>
  );
};

export default MenuItem;
