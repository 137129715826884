import CalendarForm from "@/components/common/calendarForm";
import Radio from "@/components/common/form/radio";
import { sliceDate } from "@/utils/function";
import { useEffect } from "react";
import {
  useForm,
  useFormContext,
  FormProvider,
  useWatch,
} from "react-hook-form";
import { useLoaderData } from "react-router-dom";
import {
  MultiSelectContainer,
  MultiSelectItem,
  SearchButton,
  SearchCalendarBox,
  SearchContainer,
  SearchInputBox,
  SearchTable,
  SearchTableHead,
  SearchText,
  SearchWrapper,
} from "./Style";
import { common } from "@/text";
import Input from "../form/input";
import SelectBox from "../form/selectBox";
import { SearchSelectBox } from "./Style";
import dayjs from "dayjs";
import useUrl from "@/hooks/utils/useUrl";
import { pxToRem } from "@/styles/GlobalStyle";
import MultiSelectBox from "../form/multiSelectBox";

const {
  search: { defaultButton },
} = common;
export const SearchCalendar = ({
  title,
  isSale,
  endCalendarDate,
  checkStartDate,
  checkEndDate,
  isMonth,
}) => {
  const { register, setValue } = useFormContext();
  const { startDate, endDate } = useWatch(["startDate", "endDate"]);
  const { changeQuery } = useUrl();

  useEffect(() => {
    if (endCalendarDate) {
      setValue("endDate", endCalendarDate);
    }
  }, [endCalendarDate, setValue]);

  useEffect(() => {
    if (startDate && endDate) {
      if (!isSale && startDate > endDate) {
        setValue("startDate", endDate);
        setValue("endDate", startDate);
      } else if (isSale) {
        const current = dayjs();
        const lastDayOfLastMonth = current
          .subtract(1, "month")
          .endOf("month");

        let start = dayjs(startDate);
        let end = dayjs(endDate);

        if (end.isBefore(start)) {
          const temp = start;
          start = end;
          end = temp;
        }

        if (lastDayOfLastMonth.isBefore(end)) {
          end = lastDayOfLastMonth;
        }

        if (lastDayOfLastMonth.isBefore(start)) {
          start = lastDayOfLastMonth;
        }

        setValue("startDate", sliceDate(start.format("YYYY-MM-DD")));
        setValue("endDate", sliceDate(end.format("YYYY-MM-DD")));

        changeQuery({
          startDate: sliceDate(start.format("YYYY-MM-DD")),
          endDate: sliceDate(end.format("YYYY-MM-DD")),
        });
      }
    } else if (!isSale && !startDate && !endDate) {
      const min = dayjs("2024-01-01");
      const current = dayjs().isBefore(min) ? min : dayjs();
      const prev = current.subtract(1, "months").isBefore(min)
        ? min
        : current;

      // console.log(current, prev, min);

      setValue("startDate", sliceDate(prev.format("YYYY-MM-DD")));
      setValue("endDate", sliceDate(current.format("YYYY-MM-DD")));
    } else if (isSale) {
      const current = dayjs();

      const firstDayOfLastMonth = current
        .subtract(1, "month")
        .startOf("month");
      const lastDayOfLastMonth = current
        .subtract(1, "month")
        .endOf("month");

      setValue(
        "startDate",
        sliceDate(firstDayOfLastMonth.format("YYYY-MM-DD"))
      );
      setValue(
        "endDate",
        sliceDate(lastDayOfLastMonth.format("YYYY-MM-DD"))
      );
    }
  }, [startDate, endDate, isSale, setValue]);

  return (
    <tr>
      <SearchTableHead>{title}</SearchTableHead>
      <td style={{ padding: `${pxToRem(14)} ${pxToRem(16)}` }}>
        <SearchCalendarBox>
          <CalendarForm
            isMonth={isMonth}
            option={register("startDate", {
              required: true,
            })}
            checkDate={checkStartDate}
            value={startDate}
          />
          <span>~</span>
          <CalendarForm
            isMonth={isMonth}
            isLast
            option={register("endDate", {
              required: true,
            })}
            checkDate={checkEndDate}
            value={endDate}
          />
        </SearchCalendarBox>
      </td>
    </tr>
  );
};

const SearchRadio = ({ radioList, title, name }) => {
  const { register, watch, setValue } = useFormContext();
  const radio = watch(name);

  useEffect(() => {
    if (radio) {
      setValue(name, radio);
    } else {
      setValue(name, radioList[0].value);
    }
  }, [radio, radioList, name, setValue]);

  return (
    <tr>
      <SearchTableHead>{title}</SearchTableHead>
      <td>
        <Radio>
          {radioList.map((radio) => (
            <Radio.Item
              key={radio.value}
              value={radio.value}
              option={register(name, {
                required: true,
              })}
            >
              <SearchText>{radio.title}</SearchText>
            </Radio.Item>
          ))}
        </Radio>
      </td>
    </tr>
  );
};

const SearchInput = ({ title, typeList, width }) => {
  const { register, setValue, watch } = useFormContext();
  const { data } = watch();
  const selectType =
    typeList.find((type) => type.value === watch("type")) ??
    typeList[0];

  useEffect(() => {
    if (data) {
      setValue("data", data);
    } else {
      setValue("data", "");
    }
  }, [data, setValue]);

  return (
    <tr>
      <SearchTableHead>{title}</SearchTableHead>
      <td>
        <SearchInputBox>
          <Input>
            <Input.Title>
              <SelectBox select={selectType} width={width}>
                {typeList.map(({ title, value }) => (
                  <SelectBox.Option
                    value={value}
                    option={register("type")}
                    key={value}
                  >
                    {title}
                  </SelectBox.Option>
                ))}
              </SelectBox>
            </Input.Title>
            <Input.InputForm
              flexAuto
              small
              option={register("data")}
            />
          </Input>
        </SearchInputBox>
      </td>
    </tr>
  );
};

const SearchMultiSelect = ({
  title,
  artistList,
  name,
  multiName,
  blank = "전체",
}) => {
  const { register, setValue, watch } = useFormContext();
  const select = watch(name);
  const idList = watch("idList") || [];

  const selectList = [
    { value: "", title: blank },
    ...artistList.map(({ id, nameEng }) => {
      return { value: id, title: `${nameEng} ID:${id}` };
    }),
  ];

  const selectValue =
    selectList.find(({ value }) => value === select) ?? selectList[0];

  useEffect(() => {
    if (select) {
      setValue(name, select);

      if (idList.includes(select)) {
        setValue(multiName, [...idList.filter((v) => v !== select)]);
      } else {
        setValue(multiName, [...idList, select]);
      }
    }
  }, [select, name, setValue]);

  const handleMultiItem = (id) => {
    setValue(multiName, [...idList.filter((v) => v !== id)]);
  };

  return (
    <tr>
      <SearchTableHead>{title}</SearchTableHead>
      <td>
        <SearchSelectBox>
          <MultiSelectBox select={selectValue}>
            {selectList.map(({ title, value }) => (
              <MultiSelectBox.Option
                multiValue={select}
                setValue={setValue}
                name={name}
                value={value}
                option={register(name)}
                key={`${title}-${value}`}
              >
                {title}
              </MultiSelectBox.Option>
            ))}
          </MultiSelectBox>
        </SearchSelectBox>
        {idList?.length > 0 && (
          <MultiSelectContainer>
            {idList.map((id, i) => (
              <MultiSelectItem onClick={() => handleMultiItem(id)}>
                {id}
              </MultiSelectItem>
            ))}
          </MultiSelectContainer>
        )}
      </td>
    </tr>
  );
};

const SearchSelect = ({
  title,
  artistList,
  name,
  blank = "전체",
}) => {
  const { register, setValue, watch } = useFormContext();
  const select = watch(name);

  const selectList = [
    { value: "", title: blank },
    ...artistList.map(({ id, nameEng }) => {
      return { value: id, title: `${nameEng} ID:${id}` };
    }),
  ];

  const selectValue =
    selectList.find(({ value }) => value === select) ?? selectList[0];

  useEffect(() => {
    if (select) {
      setValue(name, select);
    }
  }, [select, name, setValue]);

  return (
    <tr>
      <SearchTableHead>{title}</SearchTableHead>
      <td>
        <SearchSelectBox>
          <SelectBox select={selectValue}>
            {selectList.map(({ title, value }) => (
              <SelectBox.Option
                value={value}
                option={register(name)}
                key={`${title}-${value}`}
              >
                {title}
              </SelectBox.Option>
            ))}
          </SelectBox>
        </SearchSelectBox>
      </td>
    </tr>
  );
};

const Search = ({ children, submit, button = defaultButton }) => {
  const searchQuires = useLoaderData();

  const form = useForm({
    defaultValues: {
      ...searchQuires,
    },
  });

  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <SearchContainer>
        <SearchWrapper onSubmit={handleSubmit(submit)}>
          <SearchTable>
            <tbody>{children}</tbody>
          </SearchTable>

          <SearchButton>{button}</SearchButton>
        </SearchWrapper>
      </SearchContainer>
    </FormProvider>
  );
};

Search.Calendar = SearchCalendar;
Search.Radio = SearchRadio;
Search.Input = SearchInput;
Search.Select = SearchSelect;
Search.MultiSelect = SearchMultiSelect;

export default Search;
