import { PopupContainer, PopupBody, PopupFooter } from "./Style";

const Body = ({ children }) => {
  return <PopupBody>{children}</PopupBody>;
};

const Footer = ({ children }) => {
  return <PopupFooter>{children}</PopupFooter>;
};

const Popup = ({ children }) => {
  return <PopupContainer>{children}</PopupContainer>;
};

Popup.Body = Body;
Popup.Footer = Footer;

export default Popup;
