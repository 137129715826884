import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginTitleLogo = styled.img``;

export const LoginDesc = styled.span`
  display: inline-block;
  padding: ${(props) =>
    `${props.theme.pxToRem(18)} 0 ${props.theme.pxToRem(60)}`};
  color: ${(props) => props.theme.colors.darkgray};
`;

export const LoginForm = styled.form`
  width: 100%;
  font-size: ${(props) => props.theme.pxToRem(12)} !important;
`;

export const LoginPasswordLine = styled(Link)`
  font-size: ${(props) => props.theme.pxToRem(12)};
  border-bottom: ${(props) => props.theme.line} solid;
`;

export const LoginInfo = styled.p`
  position: fixed;
  bottom: ${(props) => props.theme.pxToRem(40)};
  left: 50%;
  transform: translateX(-50%);

  text-align: center;
  color: ${(props) => props.theme.colors.gray2};

  font-size: ${(props) => props.theme.pxToRem(12)};
`;
