import { Button } from "@/styles/common";
import styled from "styled-components";

export const ProductTableBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: ${(props) => props.theme.pxToRem(13)};
`;

export const ProductTableButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(91)};
  padding: ${(props) =>
    `${props.theme.pxToRem(5)} ${props.theme.pxToRem(6)}}`};

  font-size: ${(props) => props.theme.pxToRem(12)};
`;

export const ProductSettingButton = styled(ProductTableButton)`
  width: ${(props) => props.theme.pxToRem(110)};

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.bolder};

  color: ${(props) => props.theme.colors.bolder};
`;
