import styled from "styled-components";

export const AuthContainer = styled.div`
  width: 100vw;
  height: 100vh;

  ${(props) => props.theme.sort.center.width};
  ${(props) => props.theme.sort.center.height};
  flex-direction: column;

  overflow: hidden;
`;

export const AuthInnerContainer = styled.div`
  ${(props) => props.theme.sort.center.height};
  flex-direction: column;

  width: ${(props) => props.theme.pxToRem(400)};

  ${(props) => props.theme.medias.mobile} {
    padding: 0 ${(props) => props.theme.pxToRem(20)};
  }
`;

export const AuthTitle = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.pxToRem(22)};
  font-weight: 500;
`;

export const AuthDescBox = styled.div`
  text-align: center;
  padding: ${(props) =>
    `${props.theme.pxToRem(8)} 0 ${props.theme.pxToRem(60)}`};
  color: ${(props) => props.theme.colors.line2};
  line-height: 20px;
`;

export const AuthDesc = styled.p`
  font-size: ${(props) => props.theme.pxToRem(12)};
`;

export const AuthForm = styled.form`
  width: 100%;
  font-size: ${(props) => props.theme.pxToRem(12)};
`;

export const AuthFormBox = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(24)};
`;

export const AuthFormTitle = styled.span`
  display: inline-block;
  margin-bottom: ${(props) => props.theme.pxToRem(5)};
`;

export const AuthInfo = styled.p`
  position: fixed;
  bottom: ${(props) => props.theme.pxToRem(40)};
  left: 50%;
  transform: translateX(-50%);

  width: 100%;

  text-align: center;
  color: ${(props) => props.theme.colors.gray2};

  font-size: ${(props) => props.theme.pxToRem(12)};
`;

export const AuthOption = styled.div`
  width: 100%;

  ${(props) => props.theme.sort.right.width};
  ${(props) => props.theme.sort.center.height}
  margin-top: ${(props) => props.theme.pxToRem(20)};
`;
