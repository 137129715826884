import styled from "styled-components";

export const HeaderContainer = styled.div`
  ${(props) => props.theme.sort.spaceBetween};

  padding: ${(props) =>
    `${props.theme.pxToRem(20)} ${props.theme.pxToRem(28)}`};
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};

  background: ${(props) => props.theme.colors.white};
`;

export const HeaderTitle = styled.h1`
  ${(props) => props.theme.sort.center.height};

  font-weight: 500;

  font-size: ${(props) => props.theme.pxToRem(20)};
`;

export const HeaderMenu = styled.div`
  position: relative;

  ${(props) => props.theme.sort.right.width};

  flex: auto;
`;

export const HeaderMenuButton = styled.button`
  position: fixed;
  bottom: ${(props) => props.theme.pxToRem(20)};
  right: ${(props) => props.theme.pxToRem(20)};
  z-index: 100;

  display: none;

  ${(props) => props.theme.medias.mobile} {
    display: block;

    width: 30px;
    height: 30px;
    background-color: white;
    border: ${(props) => props.theme.line} solid
      ${(props) => props.theme.colors.line};
    border-radius: 50%;
  }
`;
