import { useEffect, useState } from "react";

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $&는 일치하는 전체 문자열을 의미
};

const usePattern = (password = "") => {
  const [pattern, setPattern] = useState({
    password:
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-]).{8,16}|(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}|(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
    passwordConfirm: new RegExp(`^${escapeRegExp(password)}$`),
    email: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
    call: /^\d{2,3}-\d{3,4}-\d{4}$/,
  });

  useEffect(() => {
    setPattern((prev) => {
      return {
        ...prev,
        passwordConfirm: new RegExp(`^${escapeRegExp(password)}$`),
      };
    });
  }, [password]);

  return pattern;
};

export default usePattern;
