import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import Modal from "@/components/common/modal";
import {
  ProductModalButton,
  ProductModalCloseButton,
  ProductModalTitle,
  ProductModalTitleBox,
  TableHeadBox,
  ProductInput,
  ProductInputBox,
} from "./Style";
import Table from "@/components/common/table";

import { numberWithCommas, numberWithoutCommas } from "@/utils/function";
import useCheckbox from "@/hooks/utils/useCheckbox";
import Checkbox from "@/components/common/form/checkbox";
import { Strong } from "@/styles/common";
import { useEffect, useState } from "react";
import { admin } from "@/text";

const {
  product: {
    modal: {
      title,
      button,
      checkAll,
      checkBoxList,
      alert: { success, ask },
    },
  },
} = admin;

const ProductModal = ({ isShow, toggleModal, submitModal }) => {
  const { checkedList, rootChecked, updateAll, updateChecked, resetChecked } =
    useCheckbox({
      initialState: false,
      length: 3,
    });

  const [price, setPrice] = useState({
    nationSettlement: 0,
    abroadSettlement: 0,
    japanSettlement: 0,
  });

  const changeInput = (e, type) => {
    const { value } = e.target;
    const updateValue = numberWithoutCommas(value);

    if (isNaN(updateValue)) {
      return;
    }

    setPrice((prev) => {
      return {
        ...prev,
        [type]: updateValue,
      };
    });
  };

  const submit = () => {
    const data = {};

    checkedList.forEach((item, index) => {
      if (item) {
        switch (index) {
          case 0:
            data.nationSettlement = price.nationSettlement;
            break;
          case 1:
            data.abroadSettlement = price.abroadSettlement;
            break;
          case 2:
            data.japanSettlement = price.japanSettlement;
            break;
          default:
            break;
        }
      }
    });

    if (
      price.nationSettlement === 0 &&
      price.abroadSettlement === 0 &&
      price.japanSettlement === 0
    ) {
      alert("변경할 금액을 입력해주세요.");
      return;
    }

    if (Object.values(data).length === 0) {
      alert("변경할 항목을 선택해주세요.");
      return;
    }

    const result = window.confirm(ask);
    if (result) {
      submitModal(data, () => alert(success));
    }
  };

  useEffect(() => {
    if (isShow) {
      setPrice({
        nationSettlement: 0,
        abroadSettlement: 0,
        japanSettlement: 0,
      });

      resetChecked();
    }
  }, [isShow]);

  return (
    <Modal isShow={isShow} isFull>
      <ProductModalTitleBox>
        <ProductModalTitle>{title}</ProductModalTitle>
        <ProductModalCloseButton onClick={toggleModal}>
          <GrFormClose size={30} />
        </ProductModalCloseButton>
      </ProductModalTitleBox>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Colspan colspan={3}>
              <TableHeadBox>
                <Checkbox>
                  <Checkbox.Form
                    option={{ onChange: updateAll, checked: rootChecked }}
                  />
                  <Checkbox.Title>
                    <Strong>{checkAll}</Strong>
                  </Checkbox.Title>
                </Checkbox>
              </TableHeadBox>
            </Table.Colspan>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            {checkBoxList.map(({ title, width }, idx) => (
              <Table.Header width={width} key={title}>
                <Checkbox>
                  <Checkbox.Form
                    option={{
                      onChange: (e) => updateChecked(e, idx),
                      checked: checkedList[idx] ?? false,
                    }}
                  />
                  <Checkbox.Title>
                    <Strong>{title}</Strong>
                  </Checkbox.Title>
                </Checkbox>
              </Table.Header>
            ))}
          </Table.Row>

          <Table.Row>
            {checkBoxList.map(({ input }) => (
              <Table.Item key={input}>
                <ProductInputBox>
                  <ProductInput
                    value={numberWithCommas(price[input])}
                    onChange={(e) => changeInput(e, input)}
                  />
                </ProductInputBox>
              </Table.Item>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>

      <ProductModalButton onClick={submit}>{button}</ProductModalButton>
    </Modal>
  );
};

export default ProductModal;
