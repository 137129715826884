import { CustomLink } from "@/styles/common";
import styled from "styled-components";

export const MenuItemContainer = styled.li`
  width: 100%;

  font-size: ${(props) => props.theme.pxToRem(14)};

  ${(props) =>
    props.isCurrent &&
    `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.primary};
  `}
`;

export const MenuItemButton = styled.div`
  ${(props) => props.theme.sort.center.height};
  ${(props) => props.theme.sort.spaceBetween};
  width: 100%;

  padding: ${(props) =>
    `${props.theme.pxToRem(14)} ${props.theme.pxToRem(36)}`};

  cursor: pointer;
`;

export const MenuSubList = styled.ul`
  height: ${(props) =>
    props.isOpen ? `${props.theme.pxToRem(props.length * 42)}` : `0`};
  overflow: hidden;

  transition: height 0.8s;

  a {
    padding: ${(props) =>
      `${props.theme.pxToRem(14)} ${props.theme.pxToRem(68)}`};
  }
`;

export const MenuSubItem = styled.li`
  background-color: rgba(255, 255, 255, 0.9);

  color: ${(props) => props.theme.colors.subMenu};

  .active {
    color: ${(props) => props.theme.colors.line2};
  }
`;

export const MenuLink = styled(CustomLink)`
  display: flex;
  align-items: center;

  padding: ${(props) =>
    `${props.theme.pxToRem(14)} ${props.theme.pxToRem(36)}`};
`;

export const MenuItemBox = styled.div`
  ${(props) => props.theme.sort.center.height};
  ${(props) =>
    props.isCurrent &&
    `
    color: ${props.theme.colors.white};
  `}
`;

export const MenuIcon = styled.img`
  margin-right: ${(props) => props.theme.pxToRem(12)};
`;
