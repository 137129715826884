import { Button } from "@/styles/common";
import styled from "styled-components";

export const ResultPopupButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  padding: ${(props) => props.theme.pxToRem(10)} 0;
  margin-left: ${(props) => props.theme.pxToRem(6)};
`;

export const ResultPopupCancelButton = styled(ResultPopupButton)`
  color: ${(props) => props.theme.colors.primary};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.white};

  margin: 0;
  margin-right: ${(props) => props.theme.pxToRem(6)};
`;

export const ResultTable = styled.table`
  width: 100%;

  font-size: ${(props) => props.theme.pxToRem(13)};

  tr:first-child {
    td {
      border-top: ${(props) => props.theme.line} solid
        ${(props) => props.theme.colors.line};
    }

    td:first-child {
      border-top-left-radius: ${(props) => props.theme.radius.normal};
    }

    /* top-right border-radius */
    td:last-child {
      border-top-right-radius: ${(props) => props.theme.radius.normal};
    }
  }
`;

export const ResultTableHead = styled.td`
  width: ${(props) => props.theme.pxToRem(160)};
  padding: ${(props) => props.theme.pxToRem(15)};

  font-weight: 500;
`;

export const ResultTableBody = styled.td`
  width: ${(props) => `calc(100% - ${props.theme.pxToRem(160)})`};
  padding: ${(props) =>
    `${props.theme.pxToRem(15)} ${props.theme.pxToRem(13)}`};
`;

export const ResultFile = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: ${(props) => props.theme.pxToRem(480)};
  padding: ${(props) => props.theme.pxToRem(12)};

  background: ${(props) => props.theme.colors.gray5};

  font-size: ${(props) => props.theme.pxToRem(12)};
  cursor: pointer;
`;

export const ResultFileTitle = styled.h2`
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.black};
`;

export const ResultFileSize = styled.p`
  color: ${(props) => props.theme.colors.gray6};
`;
