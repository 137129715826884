import Image from "@/components/common/form/image";
import Info from "@/components/common/info";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentInfo,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";
import { ProfileImageBox, ProfileImageIcon } from "./Style";
import { createGrave } from "@/utils/function";

const ProfileImage = ({
  config: { title, name, info, required, errorMessage, isAdmin },
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { profileImg, isEdit } = watch();

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  const infoList = isAdmin ? [info[0]] : info;

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>
        <ContentMobileTitle isRequired>{title}</ContentMobileTitle>
        <ProfileImageBox>
          <Image
            option={register(name, {
              required,
            })}
            aspectRatio={"1 / 1"}
            error={error}
            isEdit={isEdit}
            image={profileImg}
          >
            <ProfileImageIcon src="/assets/profile/profile.png" />
          </Image>
        </ProfileImageBox>

        {isEdit && (
          <ContentInfo>
            <Info>{createGrave(infoList)}</Info>
          </ContentInfo>
        )}
      </ContentArea>
    </ContentContainer>
  );
};

export default ProfileImage;
