import { ContentWrapper } from "@/styles/common";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const DashboardContainer = styled(ContentWrapper)`
  text-align: center;
`;

export const DashboardTd = styled.td`
  padding: 14px 0;
`;

export const DashBoardLink = styled(Link)`
  display: inline-block;

  margin-top: 25px;
  padding: 10px 40px;

  background-color: ${(props) => props.theme.colors.primary};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(14)};
  color: ${(props) => props.theme.colors.white};
`;
