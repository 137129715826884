import Input from "@/components/common/form/input";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentDesc,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";

const Intro = ({
  config: {
    title,
    required,
    type: { typeName, name },
    errorMessage,
  },
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { isEdit } = watch();
  const desc = watch(name);

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>
        {title} {`(${typeName})`}
      </ContentTitle>

      <ContentArea>
        <Input>
          <ContentMobileTitle isRequired>
            {title} {`(${typeName})`}
          </ContentMobileTitle>
          {isEdit ? (
            <Input.AreaForm
              isError={error}
              option={{
                ...register(name, {
                  required,
                }),
              }}
            />
          ) : (
            <ContentDesc>{desc}</ContentDesc>
          )}

          {error && <Input.Error>{error}</Input.Error>}
        </Input>
      </ContentArea>
    </ContentContainer>
  );
};

export default Intro;
