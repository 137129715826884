import { Button, ContentWrapper } from "@/styles/common";
import styled from "styled-components";

export const ProfileContainer = styled(ContentWrapper)``;

export const ProfileButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  padding: ${(props) => `${props.theme.pxToRem(10)}`} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;
