import styled from "styled-components";

export const SingleInputBox = styled.div`
  width: ${(props) => props.theme.pxToRem(240)};
`;

export const SingleInputWrapper = styled.div`
  display: flex;
`;

export const SingleButton = styled.button`
  padding: 5px 20px;

  border: 1px solid;
  border-radius: 5px;

  margin-left: 6px;
`;
