import Checkbox from "@/components/common/form/checkbox";
import Table from "@/components/common/table";
import usePagination from "@/hooks/utils/usePagination";
import { SettingTableLink } from "./Style";
import { common } from "@text";

const { blank } = common;

const SettingResult = ({ admin, checkedList, updateChecked }) => {
  const { pageRender } = usePagination({
    totalPage: admin.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {admin.adminList.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan={6}>{blank}</Table.Colspan>
          </Table.Row>
        )}
        {admin.adminList.map(({ id, name, lastUpdateDate, level }, idx) => (
          <Table.Row key={id}>
            <Table.Item>
              <Checkbox>
                <Checkbox.Form
                  option={{
                    onChange: (e) => updateChecked(e, idx),
                    checked: checkedList[idx] ?? false,
                  }}
                />
              </Checkbox>
            </Table.Item>
            <Table.Item>
              <SettingTableLink to={`update/${id}`}>{id}</SettingTableLink>
            </Table.Item>
            <Table.Item>{name}</Table.Item>
            <Table.Item>{/* {sliceDate(lastUpdateDate)} */}</Table.Item>
            <Table.Item>{level}</Table.Item>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default SettingResult;
