import Table from "@/components/common/table";
import {
  SalesTableProductBox,
  SalesTableProductImage,
  SalesTableProductInfo,
  SalesTableProductOption,
  SalesTableProductTitle,
} from "./Style";
import { numberWithCommas } from "@/utils/function";
import usePagination from "@/hooks/utils/usePagination";

const ResultItem = ({ sellData }) => {
  const { pageRender, countingIndex } = usePagination({
    totalPage: sellData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {sellData.sellReport.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan="8">판매 내역이 없습니다.</Table.Colspan>
          </Table.Row>
        )}
        {sellData.sellReport.map(
          (
            {
              _id,
              name,
              option,
              paymentCurrency,
              price: { sell, settlement, total },
              sellCount,

              img,
            },
            idx
          ) => (
            <Table.Row key={idx}>
              <Table.Item>{countingIndex(idx)}</Table.Item>
              <Table.Item>
                <SalesTableProductBox>
                  <SalesTableProductImage src={img} alt="product" />
                  <SalesTableProductInfo>
                    <SalesTableProductTitle>{name}</SalesTableProductTitle>
                    {option && (
                      <SalesTableProductOption>
                        -{" "}
                        {option.map(({ name, value }) => `${name} : ${value} `)}
                      </SalesTableProductOption>
                    )}
                  </SalesTableProductInfo>
                </SalesTableProductBox>
              </Table.Item>
              <Table.Item>{paymentCurrency}</Table.Item>
              <Table.Item>{numberWithCommas(sell)}</Table.Item>
              <Table.Item>{numberWithCommas(settlement)}</Table.Item>
              <Table.Item>{numberWithCommas(sellCount)}</Table.Item>
              <Table.Item>{numberWithCommas(total.sell)}</Table.Item>
              <Table.Item>{numberWithCommas(total.settlement)}</Table.Item>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default ResultItem;
