import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import antd from "./antd";

export const pxToRem = (px, base = 16) => `${px / base}rem`;

const GlobalStyles = createGlobalStyle`
    ${reset}

    body {
      margin: 0;
    }

    a{
        text-decoration: none;
        color: inherit;
    }
    *{
        box-sizing: border-box;
        -webkit-tap-highlight-color : transparent !important;
        text-decoration:none;
        font-weight: 400;

        font-family: 'Noto Sans KR', sans-serif !important;
        color: ${(props) => props.theme.colors.black};
    }
    
    input, textarea { 
      -moz-user-select: auto;
      -webkit-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;

      -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    }
    input:focus, textarea:focus {
      outline: none;
    }

    input:read-only {
      background: ${(props) => props.theme.colors.gray3};
    }

    button {
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;
    }

    #root {
      display: flex;
    }

    table {
      border-collapse: separate;
     
    }
    table tr th,
    table tr td {
      border-right: ${(props) => props.theme.line} solid ${(props) =>
  props.theme.colors.line};
      border-bottom: ${(props) => props.theme.line} solid ${(props) =>
  props.theme.colors.line};

      background: ${(props) => props.theme.colors.white};
    }

    table tr td:first-child,
    table tr td:first-child {
      border-left: ${(props) => props.theme.line} solid ${(props) =>
  props.theme.colors.line};
    }

    table > thead > tr:first-child > td {
      border-top: ${(props) => props.theme.line} solid ${(props) =>
  props.theme.colors.line};
    }

    /* top-left border-radius */
    table > thead > tr:first-child > td:first-child {
      border-top-left-radius: ${(props) => props.theme.radius.normal};
    }

    /* top-right border-radius */
    table > thead > tr:first-child > td:last-child {
      border-top-right-radius: ${(props) => props.theme.radius.normal};
    }

    /* bottom-left border-radius */
    table > tbody > tr:last-child > td:first-child {
      border-bottom-left-radius: ${(props) => props.theme.radius.normal};
    }

    /* bottom-right border-radius */
    table > tbody > tr:last-child > td:last-child {
      border-bottom-right-radius: ${(props) => props.theme.radius.normal};

      ${(props) => props.theme.medias.mobile} {
        border-bottom-left-radius: ${(props) => props.theme.radius.normal}
      }
    }

    ${antd}
`;

export default GlobalStyles;
