import { FileButton, FileContainer } from "./Style";

const Input = ({ option, checkExtension, fileName, fileSize }) => {
  const upload = (e) => {
    const file = e.target.files[0];

    if (!file) return;
    if (checkExtension && !checkExtension(e)) return;

    option.onChange({
      target: {
        name: option.name,
        value: file,
      },
    });
    fileName.onChange({
      target: {
        name: fileName.name,
        value: file.name,
      },
    });
    fileSize.onChange({
      target: {
        name: fileSize.name,
        value: file.size,
      },
    });
  };

  return <input type="file" hidden {...option} onChange={upload} />;
};

const Button = FileButton;

const File = ({ children }) => {
  return <FileContainer>{children}</FileContainer>;
};

File.Button = Button;
File.Input = Input;

export default File;
