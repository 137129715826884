import { Strong } from "@/styles/common";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const RequestTableItem = styled.td`
  padding: ${(props) => props.theme.pxToRem(15)};
`;

export const RequestStatusFinish = styled(Link)`
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.black};
`;

export const RequestStatusReady = styled(Strong)`
  color: ${(props) => props.theme.colors.bolder};
`;
