import useExternalToggle from "@/hooks/utils/useExternalToggle";
import { createContext, useContext, useRef } from "react";
import {
  SelectBoxContainer,
  SelectBoxItem,
  SelectBoxList,
  SelectBoxButton,
  SelectBoxIcon,
  SelectBoxLabel,
} from "./Style";

const MultiSelectBoxContext = createContext();

const Item = ({ value, option, children }) => {
  const { openToggle, select } = useContext(MultiSelectBoxContext);

  return (
    <SelectBoxItem>
      <SelectBoxLabel isActive={value === select.value}>
        <input
          type="radio"
          hidden
          value={value}
          checked={value === select.value}
          {...option}
          onClick={openToggle}
        />
        {children}
      </SelectBoxLabel>
    </SelectBoxItem>
  );
};

const MultiSelectBox = ({ select, width, children }) => {
  const selectRef = useRef();

  const [toggle, openToggle] = useExternalToggle({
    defaultOpen: false,
    target: selectRef,
  });

  return (
    <MultiSelectBoxContext.Provider value={{ openToggle, select }}>
      <SelectBoxContainer ref={selectRef} width={width}>
        <SelectBoxButton type="button" onClick={openToggle}>
          {select.title}
          <SelectBoxIcon isShow={toggle}>
            <img src={`/assets/menus/up.svg`} alt="menu-icon" />
          </SelectBoxIcon>
          <SelectBoxIcon isShow={!toggle}>
            <img src={`/assets/menus/down.svg`} alt="menu-icon" />
          </SelectBoxIcon>
        </SelectBoxButton>

        <SelectBoxList isOpen={toggle}>{children}</SelectBoxList>
      </SelectBoxContainer>
    </MultiSelectBoxContext.Provider>
  );
};

MultiSelectBox.Option = Item;

export default MultiSelectBox;
