import { pxToRem } from "./GlobalStyle";

const theme = {
  pxToRem: pxToRem,
  colors: {
    primary: "#7AB9F3",
    bolder: "#007AEA",
    error: "#FF0000",
    white: "#FFFFFF",
    black: "#333333",
    darkgray: "#5A5A5A",
    gray: "#989898 ",
    gray2: "#979797 ",
    gray3: "#F8F9FB ",
    gray4: "#A4A4A4",
    gray5: "#F5F5F5",
    gray6: "#818181",
    line: "#D9DEE3",
    line2: "#333333",
    line3: "#CFCFCF",
    layout: "#E3E9F0",
    subMenu: "#7A7A7A",
  },
  fonts: {
    xs: pxToRem(12),
    sm: pxToRem(14),
    mid: pxToRem(16),
  },
  line: pxToRem(1),
  radius: {
    normal: pxToRem(3),
    round: pxToRem(999),
  },
  sort: {
    left: {
      width: `
display:flex;
justify-content:flex-start;
          `,
      height: `
display:flex;
align-items: flex-start;
          `,
    },
    center: {
      width: `
display:flex;
justify-content:center;
    `,
      height: `
display:flex;
align-items: center;
    `,
    },
    right: {
      width: `
display:flex;
justify-content:flex-end;
          `,
      height: `
display:flex;
align-items: flex-end;
          `,
    },
    spaceBetween: `
display:flex;
justify-content:space-between;
    `,
  },
  medias: {
    pc: "@media (min-width: 1200px)",
    tablet: "@media (min-width: 768px) and (max-width: 1459px)",
    mobile: "@media (max-width: 767px)",
  },
};

export default theme;
