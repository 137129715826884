import { GET, POST } from "../info";

export const getSellInfo = async (params) =>
  await GET(`/report/sell`, { params });

export const getUnsettlementInfo = async () =>
  await GET("/report/unsettlement");

export const getSettlementInfo = async (params) =>
  await GET("/report/settlement", { params });

export const createSettlement = async () => await POST("/report/settlement");
