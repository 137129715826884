import { numberWithCommas } from "@/utils/function";
import {
  TableBox,
  TableColspanTd,
  TableContainer,
  TableHeader,
  TablePageTd,
  TableSelectBoxWrapper,
  TableSortTd,
  TableStrong,
  TableTd,
  TableWrapper,
} from "./Style";
import SelectBox from "../form/selectBox";
import useUrl from "@/hooks/utils/useUrl";
import { common } from "@text";
import Loading from "../loading";

const {
  tableSort: {
    title: { first },
    limitList,
  },
} = common;

const TableColspan = ({ colspan, children }) => {
  return <TableColspanTd colSpan={colspan}>{children}</TableColspanTd>;
};

const TableSort = ({
  colspan,
  unit,
  length,
  sortWidth,
  sortList = [],
  isLimit = true,
}) => {
  const {
    changeQuery,
    query: { sort, limit },
  } = useUrl();

  const currSort = sortList.find(({ value }) => value === sort) ?? sortList[0];
  const currLimit =
    limitList.find(({ value }) => value === limit) ?? limitList[0];

  const isSort = sortList.length !== 0;

  return (
    <TableSortTd colSpan={colspan}>
      <TableBox>
        <TableBox>
          {first} <TableStrong>{numberWithCommas(length)}</TableStrong>
          {unit}
        </TableBox>

        <TableBox>
          <TableSelectBoxWrapper>
            {isSort && (
              <SelectBox select={currSort} width={sortWidth}>
                {sortList.map(({ title, value }) => (
                  <SelectBox.Option
                    key={value}
                    value={value}
                    option={{ onChange: () => changeQuery({ sort: value }) }}
                  >
                    {title}
                  </SelectBox.Option>
                ))}
              </SelectBox>
            )}
          </TableSelectBoxWrapper>

          <TableSelectBoxWrapper>
            {isLimit && (
              <SelectBox select={currLimit} width={"130"}>
                {limitList.map(({ title, value }) => (
                  <SelectBox.Option
                    key={value}
                    value={value}
                    option={{
                      onChange: () => {
                        changeQuery({ limit: value });
                      },
                    }}
                  >
                    {title}
                  </SelectBox.Option>
                ))}
              </SelectBox>
            )}
          </TableSelectBoxWrapper>
        </TableBox>
      </TableBox>
    </TableSortTd>
  );
};

const TableItem = ({ children }) => {
  return <TableTd>{children}</TableTd>;
};

const TableRow = ({ children, onClick }) => {
  return <tr onClick={onClick}>{children}</tr>;
};

const TableStatus = ({ isLoading, isError, isBlank }) => {
  const isStatus = isLoading || isError || isBlank;

  return (
    isStatus && (
      <tr>
        <td colSpan="100">
          <Loading {...{ isLoading, isError, isBlank }} />
        </td>
      </tr>
    )
  );
};

const Header = ({ width, children }) => {
  return <TableHeader width={width}>{children}</TableHeader>;
};

const Head = ({ children }) => {
  return <thead>{children}</thead>;
};

const Body = ({ children }) => {
  return <tbody>{children}</tbody>;
};

const TableLoading = ({ isError, isLoading, isBlank }) => {
  return (
    (isLoading || isError) && (
      <tbody>
        <tr>
          <TableColspan colspan={999}>
            <Loading
              isLoading={isLoading}
              isError={isError}
              isBlank={isBlank}
            />
          </TableColspan>
        </tr>
      </tbody>
    )
  );
};

const TablePagination = ({ children }) => {
  return (
    <tfoot>
      <tr>
        <TablePageTd colSpan={999}>{children}</TablePageTd>
      </tr>
    </tfoot>
  );
};

const Table = ({ children }) => {
  return (
    <TableContainer>
      <TableWrapper>{children}</TableWrapper>
    </TableContainer>
  );
};

Table.Item = TableItem;
Table.Row = TableRow;
Table.Status = TableStatus;
Table.Sort = TableSort;
Table.Colspan = TableColspan;
Table.Header = Header;
Table.Head = Head;
Table.Body = Body;
Table.Loading = TableLoading;
Table.Pagination = TablePagination;

export default Table;
