import { InitialContainer, InitialInfo, InitialButton } from "./Style";
import { useFormContext } from "react-hook-form";

const useInitial = () => {
  const { setValue } = useFormContext();

  const handleInitial = () => {
    setValue("isInitial", false);
    setValue("isEdit", true);
  };

  return { handleInitial };
};

const Initial = ({ form }) => {
  const { handleInitial } = useInitial();
  const { info, button } = form;

  return (
    <InitialContainer>
      <InitialInfo>
        {info.map((data) => (
          <p key={data}>{data}</p>
        ))}
      </InitialInfo>

      <InitialButton onClick={handleInitial}>{button}</InitialButton>
    </InitialContainer>
  );
};

export default Initial;
