import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentDesc,
  ContentTitle,
} from "../Style";
import { ConnectDateContainer } from "./Style";
import { sliceDate } from "@/utils/function";
import { common } from "@/text";

const { connectDateText } = common;

const ConnectDate = () => {
  const { watch } = useFormContext();
  const { lastLoginDate } = watch();

  return (
    <ConnectDateContainer>
      <tbody>
        <ContentContainer>
          <ContentTitle>{connectDateText}</ContentTitle>

          <ContentArea>
            <ContentDesc>
              {lastLoginDate ? sliceDate(lastLoginDate) : "-"}
            </ContentDesc>
          </ContentArea>
        </ContentContainer>
      </tbody>
    </ConnectDateContainer>
  );
};

export default ConnectDate;
