import {
  getProductSettlement,
  getProductUpdate,
  updateProductSettlement,
} from "@/api/product";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useProductSettlement = ({
  type = "productName",
  data = "",
  productSettlementSetting = "all",
  sort = "createAt",
  page = "0",
  limit = "10",
}) => {
  const {
    data: productSettlement,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [
      "productSettlement",
      type,
      data,
      productSettlementSetting,
      sort,
      page,
      limit,
    ],
    () =>
      getProductSettlement({
        type,
        data,
        productSettlementSetting,
        sort,
        page,
        limit,
      })
  );

  return {
    productSettlement,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useProductUpdateSettlementMutation = () => {
  const queryClient = useQueryClient();

  const productSettlement = useMutation(updateProductSettlement, {
    onSuccess: () => {
      queryClient.invalidateQueries("productSettlement");
    },
  });

  const submit = (data, successCallback) => {
    const filterData = data.filter(
      ({ abroadSettlement, japanSettlement, nationSettlement }) => {
        return (
          abroadSettlement !== 0 ||
          japanSettlement !== 0 ||
          nationSettlement !== 0
        );
      }
    );

    productSettlement.mutate(
      { data: filterData },
      {
        onSuccess: () => {
          successCallback();
        },
      }
    );
  };

  return submit;
};

export const useProductUpdate = () => {
  const updateMutation = useMutation(getProductUpdate);

  const submit = () => {
    updateMutation.mutate();
  };

  return { submit, loading: updateMutation.isLoading };
};
