import styled from "styled-components";
import { FormTitle } from "../Style";

export const InputContainer = styled.label`
  ${(props) => props.theme.sort.center.height};
  flex-wrap: wrap;

  cursor: pointer;
`;

export const InputTitle = styled(FormTitle)``;

export const InputForm = styled.input`
  flex: auto;
  ${(props) => (props.flexAuto ? `flex: auto` : `width: 100%`)};
  height: 100%;

  padding: ${(props) =>
    props.small
      ? `${props.theme.pxToRem(5)} ${props.theme.pxToRem(14)}`
      : `${props.theme.pxToRem(14)}`};
  border: ${(props) => props.theme.line} solid
    ${(props) =>
      props.isError ? props.theme.colors.error : props.theme.colors.line3};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const AreaForm = styled.textarea`
  flex: auto;
  max-width: ${(props) => props.theme.pxToRem(600)};
  height: ${(props) => props.theme.pxToRem(100)};

  padding: ${(props) => props.theme.pxToRem(14)};
  border: ${(props) => props.theme.line} solid
    ${(props) =>
      props.isError ? props.theme.colors.error : props.theme.colors.line3};
  border-radius: ${(props) => props.theme.radius.normal};

  resize: none;
`;

export const InputInfo = styled.p`
  color: ${(props) => props.theme.colors.gray};
  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(5)};

  ${(props) => props.theme.medias.mobile} {
    font-size: ${(props) => props.theme.pxToRem(10)};
  }
`;

export const InputError = styled(InputInfo)`
  color: ${(props) => props.theme.colors.error};
`;
