import { Strong } from "@/styles/common";
import styled from "styled-components";

export const EditInfoContainer = styled.td`
  font-size: ${(props) => props.theme.pxToRem(12)};
  padding: ${(props) =>
    `${props.theme.pxToRem(26)} ${props.theme.pxToRem(21)}`};

  color: ${(props) => props.theme.colors.bolder};
`;

export const EditInfoStrong = styled(Strong)`
  color: ${(props) => props.theme.colors.bolder};
`;
