import MenuItem from "./item";
import { MenuListContainer, MenuListWrapper, MenuListTitle } from "./Style";

const MenuList = ({ menu: { menuList, menuTitle } }) => {
  const renderMenuList = () => {
    const tempMenuList = [];
    let parentMenu;

    menuList.forEach((menu) => {
      if (menu.isSub) {
        menu.subList = [];
        parentMenu = menu;
      }

      if (menu.isChild) {
        parentMenu.subList.push(menu);
      } else {
        tempMenuList.push(menu);
      }
    });

    return tempMenuList;
  };

  return (
    <MenuListContainer>
      <MenuListTitle>{menuTitle}</MenuListTitle>
      <MenuListWrapper>
        {renderMenuList().map((item) => (
          <MenuItem {...item} key={item.title} />
        ))}
      </MenuListWrapper>
    </MenuListContainer>
  );
};

export default MenuList;
