import Table from "@/components/common/table";
import { ResultStrong, ResultTableTd } from "./Style";
import { numberWithCommas, sliceDate } from "@/utils/function";
import Excel from "@/components/common/excel";
import usePagination from "@/hooks/utils/usePagination";
import { SubTitle } from "@/styles/common";
import { artist, common } from "@text";

const { blank } = common;

const {
  settlement: { resultTitle, tableHeader },
} = artist;

const SettlementResult = ({ settlementInfo }) => {
  const totalLength = settlementInfo.totalItem;

  const { pageRender } = usePagination({
    totalPage: settlementInfo.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <SubTitle isMargin>
        <span>
          {resultTitle.title}{" "}
          <ResultStrong>{numberWithCommas(totalLength)}</ResultStrong>
          {resultTitle.unit}
        </span>
      </SubTitle>
      <Table>
        <Table.Head>
          <Table.Row>
            {tableHeader.map(({ name, width }) => (
              <Table.Header width={width} key={name}>
                {name}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {settlementInfo.settlementReport.length === 0 && (
            <Table.Row>
              <Table.Colspan colspan={tableHeader.length}>
                {blank}
              </Table.Colspan>
            </Table.Row>
          )}
          {settlementInfo.settlementReport.map(
            ({ date, period: { start, end }, amount, reportFile }, idx) => (
              <Table.Row key={idx}>
                <ResultTableTd>{sliceDate(date)}</ResultTableTd>
                <ResultTableTd>
                  {sliceDate(start)} ~ {sliceDate(end)}
                </ResultTableTd>
                <ResultTableTd>{numberWithCommas(amount)}</ResultTableTd>
                <ResultTableTd>
                  <Excel link={reportFile} />
                </ResultTableTd>
              </Table.Row>
            )
          )}
        </Table.Body>
        <Table.Pagination>{pageRender()}</Table.Pagination>
      </Table>
    </>
  );
};

export default SettlementResult;
