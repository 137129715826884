import styled from "styled-components";

export const SearchPopupItem = styled.td`
  padding: ${(props) => props.theme.pxToRem(15)} 0;
`;

export const SearchPopupRadio = styled.input`
  appearance: none;
  border: ${(props) => props.theme.line} solid lightgray;
  width: ${(props) => props.theme.pxToRem(15)};
  height: ${(props) => props.theme.pxToRem(15)};
  border-radius: 50%;

  :checked {
    background-color: ${(props) => props.theme.colors.bolder};
    border: ${(props) => props.theme.pxToRem(2)} solid white;
    box-shadow: 0 0 0 ${(props) => props.theme.line} lightgray;
  }

  cursor: pointer;
`;
