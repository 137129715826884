import Table from "@/components/common/table";
import Tooltip from "@/components/common/tooltip";
import {
  SalesListCategoryBox,
  SalesListCategoryTd,
  SalesListSortButton,
  SalesListTooltip,
  SalesListWrapper,
  SalesSubTitle,
} from "./Style";
import { artist } from "@/text";
import { createGrave, sliceDate } from "@/utils/function";
import { UpdateDate } from "@/styles/common";
import TotalItem from "./totalItem";
import ResultItem from "./Item";
import useUrl from "@/hooks/utils/useUrl";

const {
  sales: {
    totalTitle,
    totalTableHeader,
    listTitle,
    listUpdate,
    listSortButton,
    tableHeader,
    tooltip: { icon, content },
  },
} = artist;

const SalesResult = ({ sellData }) => {
  const {
    changeQuery,
    query: { sellReportType = "item" },
  } = useUrl();
  const lastUpdateDate = sliceDate(sellData.updateTime, "min");

  return (
    <>
      <SalesSubTitle isMargin>{totalTitle}</SalesSubTitle>
      <Table>
        <Table.Head>
          <Table.Row>
            {totalTableHeader.map(({ name, width }) => (
              <Table.Header width={width} key={name}>
                {name}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <TotalItem sellInfo={sellData.sellInfo} />
        </Table.Body>
      </Table>

      <SalesSubTitle isMargin>
        {listTitle}
        <UpdateDate>
          {listUpdate} {lastUpdateDate}
        </UpdateDate>
      </SalesSubTitle>
      <SalesListWrapper>
        <Table>
          <Table.Head>
            <tr>
              <SalesListCategoryTd colSpan={tableHeader.length}>
                <SalesListCategoryBox>
                  {listSortButton.map(({ value, name }) => (
                    <SalesListSortButton
                      key={value}
                      isActive={value === sellReportType}
                      onClick={() => changeQuery({ sellReportType: value })}
                    >
                      {name}
                    </SalesListSortButton>
                  ))}

                  <Tooltip
                    isToggle
                    type={["bottom", "fade", "smooth", "pre", "none"]}
                    tooltip={createGrave(content)}
                    width={480}
                    background={"white"}
                    color={"#333333"}
                  >
                    <SalesListTooltip>{icon}</SalesListTooltip>
                  </Tooltip>
                </SalesListCategoryBox>
              </SalesListCategoryTd>
            </tr>
            <Table.Row>
              {tableHeader.map(({ name, width }) => (
                <Table.Header width={width} key={name}>
                  {name}
                </Table.Header>
              ))}
            </Table.Row>
          </Table.Head>

          <ResultItem sellData={sellData} />
        </Table>
      </SalesListWrapper>
    </>
  );
};

export default SalesResult;
