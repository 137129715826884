import Image from "@/components/common/form/image";
import Info from "@/components/common/info";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentIcon,
  ContentInfo,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";
import { BannerImageBox } from "./Style";
import { createGrave } from "@/utils/function";

const Banner = ({
  config: { title, name, info, required, errorMessage, isAdmin },
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { storeBannerImg, isEdit } = watch();

  const error = errors[name] ? errorMessage[errors[name].type] : "";
  const infoList = isAdmin ? [info[0]] : info;

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>
        <BannerImageBox>
          <ContentMobileTitle isRequired>{title}</ContentMobileTitle>

          <Image
            option={register(name, {
              required,
            })}
            aspectRatio={"480 / 100"}
            error={error}
            isEdit={isEdit}
            image={storeBannerImg}
          >
            <ContentIcon src="/assets/profile/banner.png" />
          </Image>
        </BannerImageBox>

        {isEdit && (
          <ContentInfo>
            <Info>{createGrave(infoList)}</Info>
          </ContentInfo>
        )}
      </ContentArea>
    </ContentContainer>
  );
};

export default Banner;
