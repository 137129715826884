import { Outlet } from "react-router-dom";
import Header from "./header";
import Menu from "./menu";
import { ContentContainer } from "./Style";
import useToggle from "@/hooks/utils/useToggle";
import { menu } from "@/text";
import useUrl from "@/hooks/utils/useUrl";
import Denied from "@/pages/admin/denied";

const { list } = menu;

const access = {
  DASHBOARD: ["/"],
  ARTIST: ["artist/", "artist/create", "artist/update"],
  PRODUCT: ["product/"],
  SETTLEMENT_REQUEST: ["settlement/request"],
  SETTLEMENT_CREATE: ["settlement/create"],
  SETTLEMENT_REPORT: ["settlement/list"],
  OPERATION: ["setting/", "setting/create", "setting/update"],
};

const getMenuByUserType = (user) => {
  return user.level ? { ...list.admin, isAdmin: true } : list.artist;
};

const getRoute = (path) => `${path[1]}/${path[2]}`;
const getProfile = (user) => ({
  name: user.level ? user.name : user.nameEng,
  profileImg: user.profileImg,
  id: user.id,
});

const Layout = ({ userData }) => {
  const { path } = useUrl();
  const [toggle, setToggle] = useToggle(false);
  const menu = getMenuByUserType(userData);

  const getAccessRoutes = () => {
    const { accessCategory, level } = userData;
    const route = getRoute(path);

    if (level) {
      const findAccess = Object.entries(access).find(([key, value]) =>
        value.includes(route)
      );

      return accessCategory.includes(findAccess ? findAccess[0] : "");
    } else {
      return true;
    }
  };

  const accessRoutes = getAccessRoutes();
  const profile = getProfile(userData);

  return (
    <>
      <Menu isOpen={toggle} toggleMenu={setToggle} menu={menu} />
      <ContentContainer>
        <Header toggleMenu={setToggle} profile={profile} />
        {accessRoutes ? <Outlet /> : <Denied />}
      </ContentContainer>
    </>
  );
};

export default Layout;
