import Search from "@/components/common/search";
import { SettingContainer } from "./Style";
import useUrl from "@/hooks/utils/useUrl";
import Result from "@/components/admin/setting/result";
import { admin } from "@text";

const {
  setting: {
    search: { select },
  },
} = admin;

const Setting = () => {
  const { changeQuery } = useUrl();

  return (
    <SettingContainer>
      <Search submit={changeQuery}>
        <Search.Input
          title={select.title}
          typeList={select.selectList}
          width={"120"}
        />
      </Search>

      <Result />
    </SettingContainer>
  );
};

export default Setting;
