import { ResultContainer } from "./Style";
import Search from "@/components/common/search";
import { artist } from "@text";
import useUrl from "@/hooks/utils/useUrl";
import { SubTitle } from "@/styles/common";
import SettlementResult from "./item";
import { useSettlementInfo } from "@/hooks/api/report";
import Loading from "@/components/common/loading";

const {
  settlement: { searchTitle },
  common: {
    search: { calendar },
  },
} = artist;

const Result = () => {
  const { changeQuery, query } = useUrl();
  const { settlementInfo, isSuccess, ...status } = useSettlementInfo(query);

  return (
    <ResultContainer>
      <SubTitle isMargin>{searchTitle}</SubTitle>
      <Search submit={changeQuery}>
        <Search.Calendar title={calendar} isMonth />
      </Search>

      <Loading {...status} />
      {isSuccess && (
        <SettlementResult settlementInfo={settlementInfo.success.data} />
      )}
    </ResultContainer>
  );
};

export default Result;
