import { ResultPopupButton } from "./Style";
import Popup from "@/components/common/popup";
import { sendPopupMessage } from "@/utils/function";
import { admin } from "@/text";
import Result from "@/components/admin/settlement/list/result";
import useUrl from "@/hooks/utils/useUrl";
import Loading from "@/components/common/loading";
import { useSettlementDetail } from "@/hooks/api/settlement";

const {
  settlement: {
    list: {
      registration: {
        popup: {
          result: { button },
        },
      },
    },
  },
} = admin;

const ResultPopup = () => {
  const { query } = useUrl();
  const { settlementDetail, isSuccess, ...status } = useSettlementDetail(query);
  const submit = () => {
    sendPopupMessage("close");
  };

  return (
    <Popup>
      <Popup.Body>
        <Loading {...status} />
        {isSuccess && (
          <Result settlementDetail={settlementDetail.success.data} />
        )}
      </Popup.Body>

      <Popup.Footer>
        <ResultPopupButton onClick={submit}>{button.confirm}</ResultPopupButton>
      </Popup.Footer>
    </Popup>
  );
};

export default ResultPopup;
