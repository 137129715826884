import { useEffect } from "react";

const useScrollDisable = (isShow) => {
  useEffect(() => {
    if (isShow) document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  }, [isShow]);
};

export default useScrollDisable;
