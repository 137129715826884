import { useFormContext } from "react-hook-form";

import { ContentCheckButton, ContentContainer } from "../Style";
import EditInfo from "../../inputTable/editInfo";
import SingleInput from "../../inputTable/singleInput";
import InputList from "../../inputTable/inputList";

import usePattern from "@/hooks/utils/usePattern";
import { useArtistDuplication } from "@/hooks/api/artist";
import { common } from "@text";
import { useEffect, useState } from "react";

const {
  tableForm: { artist },
} = common;

const ArtistInput = ({ required }) => {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const currPassword = watch("password");
  const id = watch("id");
  const pattern = usePattern(currPassword);
  const [searchToggle, setSearchToggle] = useState(false);
  const { isSuccess, isError } = useArtistDuplication(id, searchToggle);

  const checkIdDuplication = () => {
    if (id) {
      setSearchToggle((prev) => !prev);
    } else {
      setError("id", { type: "required" });
    }
  };

  useEffect(() => {
    if (id) {
      clearErrors("id");
      setValue("idCheck", false);
      setSearchToggle(false);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccess) {
      setValue("idCheck", true);
      setSearchToggle((prev) => !prev);
      window.alert("사용 가능한 아이디입니다.");
    } else if (isError) {
      setValue("idCheck", false);
      setSearchToggle((prev) => !prev);
      window.alert("사용중인 아이디입니다.");
    }
  }, [isSuccess, isError]);

  return (
    <ContentContainer>
      <tbody>
        <EditInfo />
        <SingleInput
          config={{
            ...artist.id,
            required: required.id,
          }}
        >
          <ContentCheckButton type="button" onClick={checkIdDuplication}>
            {artist.id.checkButton}
          </ContentCheckButton>
        </SingleInput>
        <SingleInput
          config={{
            ...artist.password,
            required: required.password,
            pattern: pattern.password,
            option: {
              type: "password",
            },
          }}
        />
        <SingleInput
          config={{
            ...artist.passwordConfirm,
            required: required.passwordConfirm,
            pattern: pattern.passwordConfirm,
            option: {
              type: "password",
            },
          }}
        />
        <InputList config={{ ...artist.name, required: required.name }} />
      </tbody>
    </ContentContainer>
  );
};

export default ArtistInput;
