import { GET, PUT } from "../info";

export const getArtistProfile = async (params) =>
  await GET("/profile/artist", { params });

export const getAdminProfile = async (params) =>
  await GET("/profile/admin", { params });

export const getArtistUpdate = async () => await GET("/update");

export const updateArtistProfile = async (body) =>
  await PUT("/profile/artist", body);

export const updateAdminProfile = async (body) =>
  await PUT("/profile/admin", body);
