import styled from "styled-components";

export const SalesTableProductBox = styled.div`
  display: flex;

  padding: ${(props) => props.theme.pxToRem(8)};
`;

export const SalesTableProductImage = styled.img`
  width: ${(props) => props.theme.pxToRem(36)};
  height: ${(props) => props.theme.pxToRem(36)};

  border: ${(props) => props.theme.pxToRem(1)} solid
    ${(props) => props.theme.colors.gray3};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const SalesTableProductInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  text-align: left;

  margin-left: ${(props) => props.theme.pxToRem(7)};
`;

export const SalesTableProductTitle = styled.p`
  margin-bottom: ${(props) => props.theme.pxToRem(4)};

  font-size: ${(props) => props.theme.pxToRem(13)};
  color: ${(props) => props.theme.colors.line2};
`;

export const SalesTableProductOption = styled.p`
  font-size: ${(props) => props.theme.pxToRem(11)};
  color: ${(props) => props.theme.colors.bolder};
`;
