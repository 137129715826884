import { useEffect, useState } from "react";

const useCheckbox = ({ initialState = false, length }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [rootChecked, setRootChecked] = useState(false);

  const updateAll = (e) => {
    const { checked } = e.target;

    setCheckedList((prev) => prev.map(() => checked));
    setRootChecked(checked);
  };

  const updateChecked = (e, index) => {
    const { checked } = e.target;

    setCheckedList((prev) =>
      prev.map((item, i) => (i === index ? checked : item))
    );
  };

  const resetChecked = () => {
    setCheckedList(new Array(length).fill(initialState));
    setRootChecked(initialState);
  };

  useEffect(() => {
    setCheckedList(new Array(length).fill(initialState));
  }, [length, initialState]);

  return {
    checkedList,
    rootChecked,
    updateAll,
    updateChecked,
    resetChecked,
  };
};

export default useCheckbox;
