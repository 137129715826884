import styled from "styled-components";

export const MenuFooterContainer = styled.div``;

export const MenuInfo = styled.p`
  text-align: center;
  font-size: ${(props) => props.theme.pxToRem(10)};
  color: ${(props) => props.theme.colors.gray2};

  margin-top: ${(props) => props.theme.pxToRem(10)};
`;
