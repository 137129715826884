import { SearchPopupButton, SearchPopupCancelButton } from "./Style";
import useUrl from "@/hooks/utils/useUrl";
import { useState } from "react";
import Popup from "@/components/common/popup";
import { sendPopupMessage } from "@/utils/function";
import { admin } from "@/text";
import SearchResult from "@/components/admin/settlement/list/search";
import Loading from "@/components/common/loading";
import { useSettlementRequestCode } from "@/hooks/api/settlement";

const {
  settlement: {
    list: {
      registration: {
        popup: {
          search: { button },
        },
      },
    },
  },
} = admin;

const SearchPopup = () => {
  const {
    query: { id, limit, page },
  } = useUrl();
  const { settlementRequestCode, isSuccess, ...status } =
    useSettlementRequestCode({
      id,
      limit,
      page,
    });
  const [select, setSelect] = useState(null);

  const submit = () => {
    if (select) sendPopupMessage(select);
  };

  const cancel = () => {
    window.close();
  };

  const updateSelect = (data) => {
    setSelect(data);
  };

  const currSelect = select ?? {};

  return (
    <Popup>
      <Popup.Body>
        <Loading {...status} />
        {isSuccess && (
          <SearchResult
            currSelect={currSelect}
            updateSelect={updateSelect}
            requestData={settlementRequestCode.success.data}
          />
        )}
      </Popup.Body>

      <Popup.Footer>
        <SearchPopupCancelButton onClick={cancel}>
          {button.cancel}
        </SearchPopupCancelButton>
        <SearchPopupButton onClick={submit}>{button.confirm}</SearchPopupButton>
      </Popup.Footer>
    </Popup>
  );
};

export default SearchPopup;
