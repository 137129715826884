import {
  createArtist,
  deleteArtist,
  getArtist,
  getArtistDuplication,
  getArtistIdList,
} from "@/api/artist";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useArtist = ({
  type = "id",
  data = "",
  sort = "id",
  limit = "10",
  page = "0",
}) => {
  const {
    data: artist,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["artist", type, data, sort, limit, page], () =>
    getArtist({
      type,
      data,
      sort,
      limit,
      page,
    })
  );

  return {
    artist,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useArtistDuplication = (id, enabled) => {
  const {
    data: artistDuplication,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["artistDuplication", id], () => getArtistDuplication({ id }), {
    enabled,
  });
  return {
    artistDuplication,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useArtistIdList = () => {
  const {
    data: artistIdList,
    isLoading,
    isSuccess,
    isError,
  } = useQuery("artistIdList", getArtistIdList);

  return {
    artistIdList,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useCreateArtistMutation = (successCallback, failCallback) => {
  const queryClient = useQueryClient();

  const createArtistMutation = useMutation(createArtist, {
    onSuccess: () => {
      queryClient.invalidateQueries("artist");
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (data) => {
    createArtistMutation.mutate(data);
  };

  return submit;
};

export const useDeleteArtistMutation = (successCallback, failCallback) => {
  const queryClient = useQueryClient();

  const deleteArtistMutation = useMutation(deleteArtist, {
    onSuccess: () => {
      queryClient.invalidateQueries("artist");
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (data) => {
    deleteArtistMutation.mutate(data);
  };

  return submit;
};
