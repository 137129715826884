import styled from "styled-components";

import { FormTitle } from "../Style";

export const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RadioTitle = styled(FormTitle)``;

export const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RadioLabel = styled.label`
  ${(props) => props.theme.sort.center.height};

  margin-right: ${(props) => props.theme.pxToRem(30)};

  width: auto;
  cursor: pointer;
`;

export const RadioText = styled.p``;

export const RadioItem = styled.input`
  appearance: none;
  border: ${(props) => props.theme.line} solid lightgray;
  width: ${(props) => props.theme.pxToRem(15)};
  height: ${(props) => props.theme.pxToRem(15)};
  border-radius: 50%;

  :checked {
    background-color: ${(props) => props.theme.colors.bolder};
    border: ${(props) => props.theme.pxToRem(2)} solid white;
    box-shadow: 0 0 0 ${(props) => props.theme.line} lightgray;
  }

  cursor: pointer;
`;

export const RadioError = styled.p`
  color: red;
  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(5)};

  ${(props) => props.theme.medias.mobile} {
    font-size: ${(props) => props.theme.pxToRem(10)};
  }
`;
