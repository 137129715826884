import Table from "@/components/common/table";
import {
  UnsettledButton,
  UnsettledItem,
  UnsettledLink,
  UnsettledStrong,
} from "./Style";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { artist } from "@/text";

const {
  settlement: { unsettledButton },
} = artist;

const UnsettledResult = ({
  amount,
  unsettlementStartDate,
  settlementRequest,
  modalActive,
}) => {
  const changeLink = (type) => {
    const current = new Date();

    return `/artist/work/sales?shoppingMall=${type}&startDate=${sliceDate(
      unsettlementStartDate
    )}&endDate=${sliceDate(current)}`;
  };

  return (
    <Table.Row>
      <UnsettledItem>{sliceDate(unsettlementStartDate)} ~</UnsettledItem>
      <UnsettledItem>
        <UnsettledLink to={changeLink("ko")}>
          {numberWithCommas(amount.ko)}
        </UnsettledLink>
      </UnsettledItem>
      <UnsettledItem>
        <UnsettledLink to={changeLink("en")}>
          {numberWithCommas(amount.en)}
        </UnsettledLink>
      </UnsettledItem>
      <UnsettledItem>
        <UnsettledLink to={changeLink("jp")}>
          {numberWithCommas(amount.jp)}
        </UnsettledLink>
      </UnsettledItem>
      <UnsettledItem>
        <UnsettledStrong>{numberWithCommas(amount.total)}</UnsettledStrong>
      </UnsettledItem>
      <UnsettledItem>
        <UnsettledButton
          isDisabled={!settlementRequest || amount.total === 0}
          onClick={() => modalActive(settlementRequest, amount.total)}
        >
          {unsettledButton}
        </UnsettledButton>
      </UnsettledItem>
    </Table.Row>
  );
};

export default UnsettledResult;
