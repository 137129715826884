import { css } from "styled-components";

export default css`
  .ant-picker {
    padding: 0;
  }

  .ant-picker-content {
    border: none !important;
    box-shadow: none;

    th {
      position: relative;
      color: white !important;
    }

    th:nth-child(1)::before {
      content: "일";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(2)::before {
      content: "월";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(3)::before {
      content: "화";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(4)::before {
      content: "수";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(5)::before {
      content: "목";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(6)::before {
      content: "금";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th:nth-child(7)::before {
      content: "토";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: ${(props) => props.theme.colors.line2};
    }

    th,
    td {
      border: none !important;
    }
  }

  .ant-picker-input {
    input {
      width: ${(props) => props.theme.pxToRem(105)} !important;
      padding: ${(props) =>
        `${props.theme.pxToRem(6)} ${props.theme.pxToRem(12)}`} !important;

      font-size: ${(props) => props.theme.pxToRem(13)} !important;

      border: ${(props) => props.theme.line} solid
        ${(props) => props.theme.colors.line} !important;
      border-radius: ${(props) => props.theme.radius.normal} !important;
    }
  }

  .ant-picker-cell .ant-picker-cell-inner {
    color: ${(props) => props.theme.colors.gray} !important;
  }

  .ant-picker-cell-in-view .ant-picker-cell-inner {
    color: ${(props) => props.theme.colors.black} !important;
  }

  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner {
    color: ${(props) => props.theme.colors.white} !important;
  }
`;
