import { useEffect, useMemo, useState } from "react";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useUrl from "./useUrl";
// import { AiOutlineDoubleLeft } from "@react-icons/all-files/ai/AiOutlineDoubleLeft";
// import { AiOutlineDoubleRight } from "@react-icons/all-files/ai/AiOutlineDoubleRight";

const PageListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
`;

const PageItem = styled(({ isActive, ...props }) => <Link {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => props.theme.pxToRem(25)};
  height: ${(props) => props.theme.pxToRem(25)};

  margin: 0 5px;
  background: ${(props) =>
    props.isActive ? props.theme.colors.primary : "transparent"};
  border-radius: ${(props) => props.theme.radius.normal};

  line-height: ${(props) => props.theme.pxToRem(19)};
  color: ${(props) =>
    props.isActive ? props.theme.colors.white : props.theme.colors.line2};
`;

const PageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => props.theme.pxToRem(25)};
  height: ${(props) => props.theme.pxToRem(25)};

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  margin: 0 5px;
`;

const PageBlank = styled.div`
  width: ${(props) => props.theme.pxToRem(25)};
  height: ${(props) => props.theme.pxToRem(25)};
`;

const usePagination = ({ totalPage, pagePerBlock }) => {
  const { query: queries = {} } = useUrl();
  const { page: currPage = 0 } = queries;
  const [pages, setPages] = useState([]);
  const page = useMemo(() => parseInt(currPage), [currPage]);
  const router = useNavigate();

  const countingIndex = (idx) => {
    return pagePerBlock * currPage + idx + 1;
  };

  const nextPage = () => {
    if (page === totalPage) return;

    const query = {
      ...queries,
      page: page + 1,
    };

    router({ search: new URLSearchParams(query).toString() });
  };

  const beforePage = () => {
    if (page === 0) return;

    const query = {
      ...queries,
      page: page - 1,
    };

    router({ search: new URLSearchParams(query).toString() });
  };

  // const firstPage = () => {
  //   const query = {
  //     ...queries,
  //     page: 1,
  //   };

  //   router({ search: new URLSearchParams(query).toString() });
  // };

  // const lastPage = () => {
  //   const query = {
  //     ...queries,
  //     page: totalPage,
  //   };

  //   router({ search: new URLSearchParams(query).toString() });
  // };

  const pageRender = () => {
    const pageBlock = pages.find((p) => p.includes(page));

    const isBeforePage = page !== 0;
    const isNextPage = page !== totalPage - 1;

    if (!pageBlock) return null;

    return (
      <PageListWrapper>
        {/* <PageButton onClick={firstPage} aria-label="first-page-button">
          <AiOutlineDoubleLeft />
        </PageButton> */}

        {isBeforePage ? (
          <PageButton onClick={beforePage} aria-label="before-page-button">
            <FiChevronLeft />
          </PageButton>
        ) : (
          <PageBlank />
        )}

        {pageBlock.map((p) => {
          const query = {
            ...queries,
            page: p,
          };

          return (
            <PageItem
              to={`?${new URLSearchParams(query).toString()}`}
              key={p}
              isActive={page === p}
            >
              {p + 1}
            </PageItem>
          );
        })}

        {isNextPage ? (
          <PageButton onClick={nextPage} aria-label="next-page-button">
            <FiChevronRight />
          </PageButton>
        ) : (
          <PageBlank />
        )}

        {/* <PageButton onClick={lastPage} aria-label="last-page-button">
          <AiOutlineDoubleRight />
        </PageButton> */}
      </PageListWrapper>
    );
  };

  useEffect(() => {
    const pages = [];

    for (let i = 0; i <= totalPage - 1; i += pagePerBlock) {
      const pageBlock = [];

      for (let j = i; j < i + pagePerBlock; j++) {
        if (j > totalPage - 1) break;
        pageBlock.push(j);
      }

      pages.push(pageBlock);
    }

    setPages(pages);
  }, [pagePerBlock, totalPage]);

  return { page, countingIndex, pageRender };
};

export default usePagination;
