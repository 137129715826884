import {
  getAdminProfile,
  getArtistProfile,
  getArtistUpdate,
  updateAdminProfile,
  updateArtistProfile,
} from "@/api/profile";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { artist } from "@text";

const {
  profile: {
    alert: { error: artistProfileError },
  },
} = artist;

export const useProfile = (path) => {
  const {
    data: profile,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    "profile",
    path === "artist" ? getArtistProfile : getAdminProfile
  );

  return {
    profile,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useArtistProfile = (params = { id: "" }) => {
  const {
    data: profile,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["artistProfile", params], () => getArtistProfile(params));

  return {
    profile,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useAdminProfile = (params = { id: "" }) => {
  const {
    data: profile,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["adminProfile", params], () => getAdminProfile(params));

  return {
    profile,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useArtistUpdate = () => {
  const {
    data: artistData,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["artistUpdate"], getArtistUpdate);

  return {
    artistData,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useArtistProfileUpdateMutation = (successCallback) => {
  const queryClient = useQueryClient();

  const artistProfileMutation = useMutation(updateArtistProfile, {
    onSuccess: () => {
      successCallback();
      queryClient.invalidateQueries("artistProfile");
    },
    onError: () => {
      window.alert(artistProfileError);
    },
  });

  const submit = (data) => {
    const formData = new FormData();

    formData.append("targetArtistId", data.targetArtistId);
    formData.append("nameKor", data.nameKor);
    formData.append("nameEng", data.nameEng);
    formData.append("profileImg", data.profileImg);
    formData.append("storeBannerImg", data.storeBannerImg);
    formData.append("introKor", data.introKor);
    formData.append("introEng", data.introEng);
    formData.append("abroadSaleOption", data.abroadSaleOption);
    formData.append("phoneNum", data.phoneNum);
    formData.append("email", data.email);
    formData.append("snsInstagram", data.snsInstagram);
    formData.append("snsTwitter", data.snsTwitter);
    formData.append("snsBlog", data.snsBlog);
    formData.append("snsYoutube", data.snsYoutube);
    formData.append("snsGrafolio", data.snsGrafolio);
    formData.append("snsEtc1", data.snsEtc1);
    formData.append("snsEtc2", data.snsEtc2);

    formData.append("password", data.password);

    formData.append("bankName", data.bankName);
    formData.append("bankAccount", data.bankAccount);
    formData.append("bankAccountHolder", data.bankAccountHolder);
    formData.append("businessRegistration", data.businessRegistration);

    artistProfileMutation.mutate(formData);
  };

  return submit;
};

export const useAdminProfileUpdateMutation = (successCallback) => {
  const queryClient = useQueryClient();

  const adminProfileMutation = useMutation(updateAdminProfile, {
    onSuccess: () => {
      successCallback();
      queryClient.invalidateQueries("adminProfile");
    },
  });

  const submit = (data) => {
    adminProfileMutation.mutate({
      ...data,
      targetId: data.id,
      newPassword: data.password,
    });
  };

  return submit;
};
