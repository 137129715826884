import styled from "styled-components";

export const PopupContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.gray3};
`;

export const PopupBody = styled.div`
  height: ${(props) => `calc(100vh - ${props.theme.pxToRem(60 + 50)})`};

  padding: 0 ${(props) => `${props.theme.pxToRem(25)}`};
  overflow-y: auto;
`;

export const PopupFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${(props) => props.theme.pxToRem(60)};
  margin-top: ${(props) => props.theme.pxToRem(50)};

  background: ${(props) => props.theme.colors.gray5};
`;
