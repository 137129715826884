import Checkbox from "@/components/common/form/checkbox";
import Table from "@/components/common/table";
import { ArtistLink } from "./Style";
import usePagination from "@/hooks/utils/usePagination";
import { common } from "@text";
import { sliceDate } from "@/utils/function";

const { blank } = common;

const ArtistResult = ({ artistData, checkedList, updateChecked }) => {
  const { pageRender } = usePagination({
    totalPage: artistData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {artistData.artistList.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan={6}>{blank}</Table.Colspan>
          </Table.Row>
        )}
        {artistData.artistList.map(
          (
            { id, nameKor, nameEng, lastUpdateProfile, lastUpdateSettlement },
            idx
          ) => (
            <Table.Row key={id}>
              <Table.Item>
                <Checkbox>
                  <Checkbox.Form
                    option={{
                      onChange: (e) => updateChecked(e, idx),
                      checked: checkedList[idx] ?? false,
                    }}
                  />
                </Checkbox>
              </Table.Item>
              <Table.Item>
                <ArtistLink to={`update/${id}`}>{id}</ArtistLink>
              </Table.Item>
              <Table.Item>{nameEng}</Table.Item>
              <Table.Item>{nameKor}</Table.Item>
              <Table.Item>{sliceDate(lastUpdateProfile)}</Table.Item>
              <Table.Item>
                {lastUpdateSettlement ? sliceDate(lastUpdateSettlement) : "-"}
              </Table.Item>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default ArtistResult;
