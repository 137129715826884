import useExternalToggle from "@/hooks/utils/useExternalToggle";
import { useRef } from "react";
import {
  HeaderProfileButton,
  HeaderProfileItemButton,
  HeaderProfileImage,
  HeaderProfileMenu,
  HeaderProfileTitle,
  HeaderProfileContainer,
  HeaderProfileTitleItem,
} from "./Style";
import { CustomLink } from "@/styles/common";
import { header } from "@text";
import { useLogoutMutation } from "@/hooks/api/auth";
import useUrl from "@/hooks/utils/useUrl";
import { convertFile } from "@/utils/function";

const {
  profile: { none, profile, logout },
} = header;

const useProfileToggle = (defaultValue) => {
  const profileRef = useRef();
  const [toggle, toggleOpen] = useExternalToggle({
    defaultValue,
    target: profileRef,
  });

  return {
    profileRef,
    toggle,
    toggleOpen,
  };
};

const HeaderProfile = ({ id, name, profileImg, isProfile, isAdmin }) => {
  const { profileRef, toggle, toggleOpen } = useProfileToggle(false);
  const { path } = useUrl();
  const logoutMutation = useLogoutMutation(path[0]);

  const image = profileImg ? convertFile(profileImg) : none;

  return (
    <HeaderProfileContainer ref={profileRef}>
      <HeaderProfileButton onClick={toggleOpen} aria-label="profile-button">
        <HeaderProfileImage size={"32"} src={image} alt="profile" />
      </HeaderProfileButton>
      <HeaderProfileMenu isOpen={isProfile && toggle}>
        <HeaderProfileImage size={"72"} src={image} alt="profile" />
        <HeaderProfileTitle>
          <HeaderProfileTitleItem>{name}</HeaderProfileTitleItem>
          {isAdmin && <HeaderProfileTitleItem>({id})</HeaderProfileTitleItem>}
        </HeaderProfileTitle>

        {!isAdmin && (
          <CustomLink to={"/artist/profile"}>
            <HeaderProfileItemButton onClick={toggleOpen}>
              {profile.title}
            </HeaderProfileItemButton>
          </CustomLink>
        )}
        <HeaderProfileItemButton onClick={logoutMutation}>
          {logout.title}
        </HeaderProfileItemButton>
      </HeaderProfileMenu>
    </HeaderProfileContainer>
  );
};

export default HeaderProfile;
