import Checkbox from "@/components/common/form/checkbox";
import Table from "@/components/common/table";
import { numberWithCommas } from "@/utils/function";
import {
  ProductBox,
  ProductImage,
  ProductInfo,
  ProductTitle,
  ProductInput,
  ProductInputBox,
} from "./Style";
import { ZERO } from "@/utils/constants";
import usePagination from "@/hooks/utils/usePagination";

const getSettlementValue = (value) => {
  if (value === ZERO) {
    return "-";
  } else {
    return numberWithCommas(value);
  }
};

const ProductResult = ({
  productData,
  isEdit,
  checkedList,
  settlement,

  updateChecked,
  changeSettlement,
}) => {
  const { pageRender } = usePagination({
    totalPage: productData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {productData.productSettlement.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan="8">상품 내역이 없습니다.</Table.Colspan>
          </Table.Row>
        )}
        {productData.productSettlement.map(
          ({ productCode, img, artistNameEng, productName, price }, idx) => (
            <Table.Row key={`${productCode}-${idx}`}>
              <Table.Item>
                <Checkbox>
                  <Checkbox.Form
                    option={{
                      onChange: (e) => updateChecked(e, idx),
                      checked: checkedList[idx] ?? false,
                    }}
                  />
                </Checkbox>
              </Table.Item>
              <Table.Item>{artistNameEng}</Table.Item>
              <Table.Item>
                <ProductBox>
                  <ProductImage src={img} />

                  <ProductInfo>
                    <ProductTitle>{productName}</ProductTitle>
                  </ProductInfo>
                </ProductBox>
              </Table.Item>
              <Table.Item>{productCode}</Table.Item>
              <Table.Item>{numberWithCommas(price.nation)}</Table.Item>
              <Table.Item>
                {isEdit ? (
                  <ProductInputBox>
                    <ProductInput
                      value={numberWithCommas(settlement[idx].nationSettlement)}
                      onChange={(e) =>
                        changeSettlement(e, idx, "nationSettlement")
                      }
                    />
                  </ProductInputBox>
                ) : (
                  getSettlementValue(settlement[idx].nationSettlement)
                )}
              </Table.Item>
              <Table.Item>
                {isEdit ? (
                  <ProductInputBox>
                    <ProductInput
                      value={numberWithCommas(settlement[idx].abroadSettlement)}
                      onChange={(e) =>
                        changeSettlement(e, idx, "abroadSettlement")
                      }
                    />
                  </ProductInputBox>
                ) : (
                  getSettlementValue(settlement[idx].abroadSettlement)
                )}
              </Table.Item>
              <Table.Item>
                {isEdit ? (
                  <ProductInputBox>
                    <ProductInput
                      value={numberWithCommas(settlement[idx].japanSettlement)}
                      onChange={(e) =>
                        changeSettlement(e, idx, "japanSettlement")
                      }
                    />
                  </ProductInputBox>
                ) : (
                  getSettlementValue(settlement[idx].japanSettlement)
                )}
              </Table.Item>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default ProductResult;
