import { Button, SubTitle } from "@/styles/common";
import styled from "styled-components";

export const ProductModalTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

export const ProductModalTitle = styled(SubTitle)`
  margin: 0;
`;

export const ProductModalCloseButton = styled.button`
  width: auto;
`;

export const ProductModalTable = styled.table``;

export const ProductModalButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(185)};

  padding: ${(props) => `${props.theme.pxToRem(10)} 0`};
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;

export const TableHeadBox = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ProductInputBox = styled.div`
  padding: ${(props) => `0 ${props.theme.pxToRem(14)}`};
`;

export const ProductInput = styled.input`
  width: 100%;

  padding: ${(props) =>
    `${props.theme.pxToRem(7)} ${props.theme.pxToRem(20)}}`};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};
`;
