import styled from "styled-components";

export const InputListBox = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

export const InputListLine = styled.p`
  display: table-cell;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;
`;
