import { ErrorContainer, ErrorInfoBox, ErrorLink, ErrorTitle } from "./Style";
import { common } from "@text";

const {
  404: { title, descList, button },
} = common;

const Error = () => {
  return (
    <ErrorContainer>
      <ErrorTitle>{title}</ErrorTitle>

      <ErrorInfoBox>
        {descList.map((desc) => (
          <p key={desc}>{desc}</p>
        ))}

        <ErrorLink to={"/artist/login"}>{button}</ErrorLink>
      </ErrorInfoBox>
    </ErrorContainer>
  );
};

export default Error;
