import { GET, POST, PUT } from "../info";

export const getUserSettlementInfo = async (params) =>
  await GET("/settlement/info", { params });

export const getSettlementRequest = async (params) =>
  await GET("/settlement/request", { params });

export const getSettlementRequestCode = async (params) =>
  await GET("/settlement/request/code", { params });

export const getSettlementReport = async (params) =>
  await GET("/settlement/report", { params });

export const getSettlement = async (params) =>
  await GET("/settlement", { params });

export const getSettlementDetail = async (params) =>
  await GET("/settlement/detail", { params });

export const updateUserSettlementInfo = async (body) =>
  await PUT("/settlement/info", body);

export const createSettlementReport = async (body) =>
  await POST("/settlement/report", body);

export const createSettlement = async (body) => await POST("/settlement", body);
