import { numberWithCommas } from "@/utils/function";
import { SalesTotalPrice, SalesTotalSettlement } from "./Style";
import Table from "@/components/common/table";

const TotalItem = ({ sellInfo }) => {
  return (
    <Table.Row>
      <Table.Item>{sellInfo.paymentCurrency}</Table.Item>
      <Table.Item>
        <SalesTotalPrice>
          {numberWithCommas(sellInfo.sellTotal)}
        </SalesTotalPrice>
      </Table.Item>
      <Table.Item>
        <SalesTotalSettlement>
          {numberWithCommas(sellInfo.settlementTotal)}
        </SalesTotalSettlement>
      </Table.Item>
    </Table.Row>
  );
};

export default TotalItem;
