import {
  ArtistUpdateButton,
  ArtistUpdateContainer,
  ArtistUpdateForm,
  ArtistUpdateInfo,
  ArtistUpdateSubTitle,
} from "./Style";
import { FormProvider, useForm } from "react-hook-form";
import ProfileInput from "@/components/common/tableForm/profileInput";
import SettlementInput from "@/components/common/tableForm/settlementInput";
import { admin } from "@text";
import ConnectDate from "@/components/common/inputTable/connectDate";
import { useArtistProfileUpdateMutation } from "@/hooks/api/profile";
import { convertFile, sliceDate } from "@/utils/function";

const {
  artist: {
    update: {
      required,
      button: { edit, content },
      title: { connect, profile, settlement },
      profile: { update },
      alert: { success },
    },
  },
} = admin;

const defaultValues = {
  isUpdatePassword: false,
  id: "",
  password: "",
  passwordConfirm: "",
  nameKor: "",
  nameEng: "",
  profileImg: "",
  storeBannerImg: "",
  introKor: "",
  introEng: "",
  abroadSaleOption: "",
  phoneNum: "",
  email: "",
  snsInstagram: "",
  snsTwitter: "",
  snsBlog: "",
  snsYoutube: "",
  snsGrafolio: "",
  snsEtc1: "",
  snsEtc2: "",

  bankName: "",
  bankAccount: "",
  bankAccountHolder: "",
  businessRegistration: "",

  isEdit: false,
};

const useProfile = (artistDetail) => {
  const businessRegistration = artistDetail.businessRegistration;
  let businessRegistrationFileName = "";

  if (businessRegistration) {
    const businessRegistrationSplit = businessRegistration.split("/");
    businessRegistrationFileName = businessRegistrationSplit.pop().split("_");
    businessRegistrationFileName.shift();
    businessRegistrationFileName = businessRegistrationFileName.join("_");
  }

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...artistDetail,
      profileImg: convertFile(artistDetail.profileImg),
      storeBannerImg: convertFile(artistDetail.storeBannerImg),
      targetArtistId: artistDetail.id,
      businessRegistrationFileName,
    },
  });

  const successCallback = () => {
    changeEdit();
    alert(success);
  };

  const artistUpdateMutation = useArtistProfileUpdateMutation(successCallback);

  const changeEdit = () => {
    const isEdit = form.getValues("isEdit");
    form.setValue("isEdit", !isEdit);
  };

  const onSubmit = (data) => {
    const isEdit = form.getValues("isEdit");

    if (isEdit) {
      artistUpdateMutation(data);
    } else {
      changeEdit();
    }
  };

  return { form, submit: form.handleSubmit(onSubmit) };
};

const ArtistUpdate = ({ artistDetail }) => {
  const { form, submit } = useProfile(artistDetail);
  const { isEdit } = form.watch();

  const button = isEdit ? edit : content;

  return (
    <FormProvider {...form}>
      <ArtistUpdateContainer>
        <ArtistUpdateForm onSubmit={submit}>
          {!isEdit && (
            <>
              <ArtistUpdateSubTitle isMargin>{connect}</ArtistUpdateSubTitle>
              <ConnectDate />
            </>
          )}
          <ArtistUpdateSubTitle isMargin>
            {profile}
            {!isEdit && (
              <ArtistUpdateInfo>
                {update}
                {artistDetail.lastUpdateProfile
                  ? sliceDate(artistDetail.lastUpdateProfile, "min")
                  : "-"}
              </ArtistUpdateInfo>
            )}
          </ArtistUpdateSubTitle>
          <ProfileInput required={required} isAdmin />

          <ArtistUpdateSubTitle isMargin>
            {settlement}
            {!isEdit && (
              <ArtistUpdateInfo>
                {update}{" "}
                {artistDetail.lastUpdateSettlement
                  ? sliceDate(artistDetail.lastUpdateSettlement, "min")
                  : "-"}
              </ArtistUpdateInfo>
            )}
          </ArtistUpdateSubTitle>
          <SettlementInput required={required} />

          <ArtistUpdateButton type="submit">{button}</ArtistUpdateButton>
        </ArtistUpdateForm>
      </ArtistUpdateContainer>
    </FormProvider>
  );
};

export default ArtistUpdate;
