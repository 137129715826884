import {
  MenuAdminButton,
  MenuCloseButton,
  MenuContainer,
  MenuContent,
  MenuLink,
  MenuLogoImage,
} from "./Style";
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp";
import { common } from "@/text";
import MenuList from "./list";
import MenuFooter from "./footer";
import { useProductUpdate } from "@/hooks/api/product";
import Loading from "@/components/common/loading";

const Menu = ({ isOpen, toggleMenu, menu }) => {
  const isAdmin = menu.isAdmin;

  const { submit, loading } = useProductUpdate();

  return (
    <MenuContainer isOpen={isOpen}>
      <MenuCloseButton onClick={toggleMenu}>
        <IoCloseSharp size={20} />
      </MenuCloseButton>
      <MenuContent>
        <MenuLink to="">
          <MenuLogoImage alt="menu-logo" src="/assets/login/logo.png" />
        </MenuLink>

        <MenuList menu={menu} />

        {isAdmin && (
          <MenuAdminButton onClick={() => !loading && submit()}>
            {loading ? <Loading isLoading={true} /> : "동기화"}
          </MenuAdminButton>
        )}
      </MenuContent>

      <MenuFooter menuInfo={common} />
    </MenuContainer>
  );
};

export default Menu;
