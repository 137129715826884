import {
  AreaForm,
  InputContainer,
  InputError,
  InputForm,
  InputInfo,
  InputTitle,
} from "./Style";

const Title = ({ isFull, children }) => {
  return <InputTitle isFull={isFull}>{children}</InputTitle>;
};

const InputField = ({ option, flexAuto, small, isError }) => {
  return (
    <InputForm
      {...option}
      flexAuto={flexAuto}
      small={small}
      isError={isError}
    />
  );
};

const AreaField = ({ option, isError }) => {
  return <AreaForm {...option} isError={isError} />;
};

const Info = ({ children }) => {
  return <InputInfo>{children}</InputInfo>;
};

const Error = ({ children }) => {
  return <InputError>{children}</InputError>;
};

const Input = ({ children }) => {
  return <InputContainer>{children}</InputContainer>;
};

Input.Title = Title;
Input.InputForm = InputField;
Input.AreaForm = AreaField;
Input.Error = Error;
Input.Info = Info;

export default Input;
