import styled, { keyframes } from "styled-components";

const openAnimation = keyframes`
    from {
      opacity: 0;
      transform: scale(0);
    }

  to {
    opacity: 1;
    transform: scale(1);
  }

`;

export const CalendarContainer = styled.div`
  position: relative;
  display: flex;

  width: auto;

  cursor: pointer;
`;

export const CalendarInput = styled.input`
  position: relative;
  z-index: 15;

  width: ${(props) => props.theme.pxToRem(105)};
  padding: ${(props) => `${props.theme.pxToRem(6)} ${props.theme.pxToRem(12)}`};

  font-size: ${(props) => props.theme.pxToRem(13)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const CalendarIconBox = styled.div`
  ${(props) => props.theme.sort.center.height};

  margin-left: ${(props) => props.theme.pxToRem(5)};
`;

export const CalendarBox = styled.div`
  position: absolute;
  z-index: 10;
  top: ${(props) => `calc(100% + ${props.theme.pxToRem(5)})`};
  right: 0;

  display: ${(props) => (props.isOpen ? "block" : "none")};

  transform-origin: top right;

  animation: ${openAnimation} 0.2s ease-in-out;

  /* .react-calendar {
    width: ${(props) => props.theme.pxToRem(300)};
  }
  .react-calendar__navigation button {
    background: none !important;
  }

  .react-calendar__month-view__days__day {
    padding: 0%;
    aspect-ratio: 1/1;
  }

  .react-calendar__year-view__months__month {
    padding: 0;
    aspect-ratio: 1/1;
  }

  .react-calendar__tile--now {
    background: ${(props) => props.theme.colors.gray};
  }

  .react-calendar__month-view__days__day--weekend {
    abbr {
      color: ${(props) => props.theme.colors.error};
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    abbr {
      color: ${(props) => props.theme.colors.gray};
    }
  }

  .react-calendar__tile--active {
    background: ${(props) => props.theme.colors.primary};
    abbr {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .react-calendar__tile {
    :hover {
      background: ${(props) => props.theme.colors.gray};
    }
  }

  .react-calendar__month-view__weekdays__weekday:nth-child(1) {
    abbr {
      display: none;
    }

    ::before {
      content: "일";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(2) {
    abbr {
      display: none;
    }

    ::before {
      content: "월";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(3) {
    abbr {
      display: none;
    }

    ::before {
      content: "화";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(4) {
    abbr {
      display: none;
    }

    ::before {
      content: "수";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(5) {
    abbr {
      display: none;
    }

    ::before {
      content: "목";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(6) {
    abbr {
      display: none;
    }

    ::before {
      content: "금";
    }
  }
  .react-calendar__month-view__weekdays__weekday:nth-child(7) {
    abbr {
      display: none;
    }

    ::before {
      content: "토";
    }
  } */
`;
