import Information from "@/components/artist/information";
import { InformationContainer } from "./Style";
import Loading from "@/components/common/loading";
import { useUserSettlementInfo } from "@/hooks/api/settlement";

const InformationPage = () => {
  const { settlementInfo, isSuccess, ...status } = useUserSettlementInfo();

  return (
    <InformationContainer>
      <Loading {...status} />
      {isSuccess && <Information information={settlementInfo.success} />}
    </InformationContainer>
  );
};

export default InformationPage;
