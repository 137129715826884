import { MenuFooterContainer, MenuInfo } from "./Style";

const MenuFooter = ({ menuInfo: { version, copyright } }) => {
  return (
    <MenuFooterContainer>
      <MenuInfo>{version}</MenuInfo>
      <MenuInfo>{copyright}</MenuInfo>
    </MenuFooterContainer>
  );
};

export default MenuFooter;
