import usePagination from "@/hooks/utils/usePagination";
import { SearchPopupItem, SearchPopupRadio } from "./Style";
import { admin } from "@/text";
import { SubTitle } from "@/styles/common";
import Table from "@/components/common/table";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { ResultStatus } from "../../request/item";

const {
  settlement: {
    list: {
      registration: {
        popup: {
          search: {
            tableTitle,
            table: {
              sort: { unit },
              header,
            },
          },
        },
      },
    },
  },
} = admin;

export const SearchResult = ({ requestData, currSelect, updateSelect }) => {
  const { pageRender } = usePagination({
    totalPage: requestData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <SubTitle isMargin>{tableTitle}</SubTitle>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={7}
              unit={unit}
              length={requestData.totalItem}
            />
          </Table.Row>

          <Table.Row>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {requestData.settlementRequestList.length === 0 && (
            <Table.Row>
              <Table.Colspan colspan={7}>요청한 정산이 없습니다.</Table.Colspan>
            </Table.Row>
          )}
          {requestData.settlementRequestList.map((settlementRequest) => {
            const {
              requestCode,
              artistNameEng,
              requestDate,
              settlementPeriod,
              settlementAmount,
              settlementStatus,
            } = settlementRequest;
            return (
              <Table.Row
                key={requestCode}
                onClick={() => {
                  updateSelect(settlementRequest);
                }}
              >
                <SearchPopupItem>
                  <SearchPopupRadio
                    type="radio"
                    checked={requestCode === currSelect.requestCode}
                    onChange={() => updateSelect(settlementRequest)}
                  />
                </SearchPopupItem>
                <SearchPopupItem>{requestCode}</SearchPopupItem>
                <SearchPopupItem>{artistNameEng}</SearchPopupItem>
                <SearchPopupItem>{sliceDate(requestDate)}</SearchPopupItem>
                <SearchPopupItem>
                  {sliceDate(settlementPeriod.start)} ~{" "}
                  {sliceDate(settlementPeriod.end)}
                </SearchPopupItem>
                <SearchPopupItem>
                  {numberWithCommas(settlementAmount)}
                </SearchPopupItem>
                <SearchPopupItem>
                  <ResultStatus status={settlementStatus} />
                </SearchPopupItem>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Pagination>{pageRender()}</Table.Pagination>
      </Table>
    </>
  );
};

export default SearchResult;
