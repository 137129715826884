import {
  ContentArea,
  ContentContainer,
  ContentTitle,
} from "@/components/common/inputTable/Style";

import { SubTitle } from "@/styles/common";
import { admin } from "@/text";
import {
  ResultFile,
  ResultFileSize,
  ResultFileTitle,
  ResultTable,
} from "./Style";
import {
  convertByte,
  convertFile,
  downloadFile,
  numberWithCommas,
  sliceDate,
} from "@/utils/function";

const {
  settlement: {
    list: {
      registration: {
        status: { request, regular },
        popup: {
          result: { infoTitle, info, creatorTitle, creator },
        },
      },
    },
  },
} = admin;

const Result = ({ settlementDetail }) => {
  const businessRegistrationSplit = settlementDetail.settlementFile.split("/");
  const businessRegistrationFileName = businessRegistrationSplit.pop();

  return (
    <>
      <SubTitle isMargin>{infoTitle}</SubTitle>
      <ResultTable>
        <tbody>
          <ContentContainer>
            <ContentTitle>{info.date}</ContentTitle>
            <ContentArea>
              {sliceDate(settlementDetail.date ?? "2023-01-03")}
            </ContentArea>
          </ContentContainer>
          <ContentContainer>
            <ContentTitle>{info.artist}</ContentTitle>
            <ContentArea>{settlementDetail.artist}</ContentArea>
          </ContentContainer>

          <ContentContainer>
            <ContentTitle>{info.type}</ContentTitle>
            <ContentArea>
              {settlementDetail.requestCode ? request : regular}
            </ContentArea>
          </ContentContainer>

          <ContentContainer>
            <ContentTitle>{info.requestCode}</ContentTitle>
            <ContentArea>{settlementDetail.requestCode}</ContentArea>
          </ContentContainer>

          <ContentContainer>
            <ContentTitle>{info.price}</ContentTitle>
            <ContentArea>
              {numberWithCommas(settlementDetail.totalCost)}
            </ContentArea>
          </ContentContainer>
          <ContentContainer>
            <ContentTitle>{info.period}</ContentTitle>
            <ContentArea>
              {sliceDate(settlementDetail.period.start)} ~{" "}
              {sliceDate(settlementDetail.period.end)}
            </ContentArea>
          </ContentContainer>

          <ContentContainer>
            <ContentTitle>{info.file}</ContentTitle>
            <ContentArea>
              <ResultFile
                onClick={() =>
                  downloadFile(
                    convertFile(settlementDetail.settlementFile),
                    businessRegistrationFileName
                  )
                }
              >
                <ResultFileTitle>
                  {businessRegistrationFileName}
                </ResultFileTitle>
                <ResultFileSize>
                  ({convertByte(settlementDetail.settlementFileSize)}mb)
                </ResultFileSize>
              </ResultFile>
            </ContentArea>
          </ContentContainer>
        </tbody>
      </ResultTable>

      <SubTitle isMargin>{creatorTitle}</SubTitle>
      <ResultTable>
        <tbody>
          <ContentContainer>
            <ContentTitle>{creator.name}</ContentTitle>
            <ContentArea>
              {settlementDetail.register.name} ({settlementDetail.register.id})
            </ContentArea>
          </ContentContainer>

          <ContentContainer>
            <ContentTitle>{creator.createdDate}</ContentTitle>
            <ContentArea>
              {sliceDate(settlementDetail.registDate, "min")}
            </ContentArea>
          </ContentContainer>
        </tbody>
      </ResultTable>
    </>
  );
};

export default Result;
