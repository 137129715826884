import styled from "styled-components";
import { ContentIcon } from "../Style";

export const ProfileImageBox = styled.div`
  width: ${(props) => props.theme.pxToRem(136)};

  ${(props) => props.theme.medias.mobile} {
    width: ${(props) => props.theme.pxToRem(100)};
  }
`;

export const ProfileImageIcon = styled(ContentIcon)`
  ${(props) => props.theme.medias.mobile} {
    width: ${(props) => props.theme.pxToRem(80)};
  }
`;
