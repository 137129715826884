import { Button, Strong } from "@/styles/common";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const UnsettledItem = styled.td`
  padding: ${(props) => props.theme.pxToRem(16)} 0;
`;

export const UnsettledButton = styled(Button)`
  width: auto;

  padding: ${(props) => `${props.theme.pxToRem(8)} ${props.theme.pxToRem(11)}`};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.bolder};

  font-size: ${(props) => props.theme.pxToRem(12)};
  color: ${(props) => props.theme.colors.bolder};
  background: ${(props) => props.theme.colors.white};

  ${(props) =>
    props.isDisabled &&
    `
  color: ${props.theme.colors.gray};
  border-color: ${props.theme.colors.gray};
  `}
`;

export const UnsettledLink = styled(Link)`
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line2};
`;

export const UnsettledStrong = styled(Strong)`
  font-size: ${(props) => props.theme.pxToRem(20)};
  color: ${(props) => props.theme.colors.bolder};
`;
