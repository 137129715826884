import { Form, FormSubmitButton, SubTitle } from "@/styles/common";
import { SettingUpdateContainer } from "./Style";
import SettingInput from "@/components/common/tableForm/settingInput";
import { FormProvider, useForm } from "react-hook-form";
import { admin } from "@/text";
import { useAdminProfileUpdateMutation } from "@/hooks/api/profile";

const {
  setting: {
    update: {
      title,
      readOnly,
      required,
      button: { confirm },
      alert: { success },
    },
  },
} = admin;

const defaultValues = {
  id: "",
  checkId: true,
  name: "",
  password: "",
  passwordConfirm: "",
  accessCategory: [],
  checkAccessCategory: false,
  isEdit: true,
};

const useSettingForm = (admin) => {
  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...admin,
    },
  });

  const { handleSubmit, setError, getValues, setValue } = form;
  const adminUpdateMutation = useAdminProfileUpdateMutation(() => {
    window.alert(success);
  });

  const checkAccessCategory = () => {
    const { accessCategory } = getValues();

    if (accessCategory.length === 0) {
      setValue("checkAccessCategory", true);
      return false;
    } else {
      setValue("checkAccessCategory", false);
      return true;
    }
  };

  const checkId = () => {
    const { checkId } = getValues();

    if (!checkId) {
      setError("id", {
        type: "checkId",
      });
      return false;
    } else {
      return true;
    }
  };

  const checkForm = () => {
    const accessCategory = checkAccessCategory();
    const id = checkId();

    if (accessCategory && id) {
      return true;
    } else {
      return false;
    }
  };

  const submit = (data) => {
    if (checkForm()) {
      adminUpdateMutation(data);
    }
  };

  return {
    form,
    handleSubmit: handleSubmit(submit),
  };
};

const Update = ({ admin }) => {
  const { form, handleSubmit } = useSettingForm(admin);

  return (
    <FormProvider {...form}>
      <SettingUpdateContainer>
        <SubTitle>{title}</SubTitle>

        <Form onSubmit={handleSubmit}>
          <SettingInput readOnly={readOnly} required={required} />
          <FormSubmitButton type="submit">{confirm}</FormSubmitButton>
        </Form>
      </SettingUpdateContainer>
    </FormProvider>
  );
};

export default Update;
