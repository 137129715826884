import { Form, FormSubmitButton, SubTitle } from "@/styles/common";
import { SettingCreateContainer } from "./Style";
import SettingInput from "@/components/common/tableForm/settingInput";
import { FormProvider, useForm } from "react-hook-form";
import { admin } from "@/text";
import { useCreateAdminMutation } from "@/hooks/api/admin";

const {
  setting: {
    create: {
      title,
      readOnly,
      required,
      button: { confirm },
      alert: { idCheck, success },
    },
  },
} = admin;

const defaultValues = {
  id: "",
  checkId: false,
  name: "",
  password: "",
  passwordConfirm: "",
  accessCategory: [],
  checkAccessCategory: false,
  isEdit: true,
};

const useSettingForm = () => {
  const form = useForm({
    defaultValues,
  });

  const { handleSubmit, getValues, setValue, reset } = form;
  const adminCreateMutation = useCreateAdminMutation(() => {
    window.alert(success);
    reset();
  });

  const checkAccessCategory = () => {
    const { accessCategory } = getValues();

    if (accessCategory.length === 0) {
      setValue("checkAccessCategory", true);
      return false;
    } else {
      setValue("checkAccessCategory", false);
      return true;
    }
  };

  const checkId = () => {
    const { checkId } = getValues();

    if (!checkId) {
      window.alert(idCheck);
    } else {
      return true;
    }
  };

  const checkForm = () => {
    const accessCategory = checkAccessCategory();
    const id = checkId();

    if (accessCategory && id) {
      return true;
    } else {
      return false;
    }
  };

  const submit = (data) => {
    if (checkForm()) {
      adminCreateMutation(data);
    }
  };

  return {
    form,
    handleSubmit: handleSubmit(submit),
  };
};

const Create = () => {
  const { form, handleSubmit } = useSettingForm();

  return (
    <FormProvider {...form}>
      <SettingCreateContainer>
        <SubTitle>{title}</SubTitle>

        <Form onSubmit={handleSubmit}>
          <SettingInput readOnly={readOnly} required={required} />
          <FormSubmitButton type="submit">{confirm}</FormSubmitButton>
        </Form>
      </SettingCreateContainer>
    </FormProvider>
  );
};

export default Create;
