import { Button, ContentWrapper } from "@/styles/common";
import styled from "styled-components";

export const InformationContainer = styled(ContentWrapper)`
  ${(props) => props.theme.sort.center.height};
  flex-direction: column;
`;

export const InformationTable = styled.table`
  width: 100%;
`;

export const InformationButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  margin-top: ${(props) => props.theme.pxToRem(24)};
`;
