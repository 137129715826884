import styled from "styled-components";

export const ExcelBox = styled.button`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: ${(props) => props.theme.pxToRem(16)};
    height: ${(props) => props.theme.pxToRem(16)};
  }
  span {
    margin-left: ${(props) => props.theme.pxToRem(5)};
    border-bottom: ${(props) => props.theme.line} solid
      ${(props) => props.theme.colors.line2};
  }
`;
