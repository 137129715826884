import { LoginDesc, LoginTitleLogo } from "./Style";
import Auth from "@/components/root/auth";
import { Button } from "@/styles/common";
import { auth } from "@text";
import useLoginForm from "@/hooks/utils/useLoginForm";
import { useLoginMutation } from "@/hooks/api/auth";
import { ROLE } from "@/utils/constants";

const {
  login: { logo, desc, admin, inputList, button },
} = auth;

const Login = () => {
  const { register, handleSubmit, errors } = useLoginForm(ROLE.admin);
  const submit = useLoginMutation();

  return (
    <Auth>
      <Auth.Inner>
        <Auth.Title>
          <LoginTitleLogo alt="logo" src={logo} />
        </Auth.Title>

        <Auth.Desc>
          <LoginDesc>
            {desc[0]}
            {admin}
            {desc[1]}
          </LoginDesc>
        </Auth.Desc>

        <Auth.Form
          submit={handleSubmit(submit)}
          inputList={inputList}
          register={register}
          errors={errors}
        >
          <Button type="submit">{button.submit}</Button>
        </Auth.Form>
      </Auth.Inner>
      <Auth.Info />
    </Auth>
  );
};

export default Login;
