import Input from "@/components/common/form/input";
import Info from "@/components/common/info";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentInfo,
  ContentMobileTitle,
  ContentTable,
  ContentTableBody,
  ContentTableHead,
  ContentTableInputBox,
  ContentTitle,
} from "../Style";
import { createGrave } from "@/utils/function";
import { InputListBox, InputListLine } from "./Style";

const InputList = ({ config: { title, info, list, required } }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { isEdit } = watch();

  const copyClipboard = (e) => {
    e.preventDefault();

    const textarea = document.createElement("textarea");
    textarea.value = e.target.innerText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>
        <>
          <ContentTable>
            <tbody>
              {list.map(({ title, name, readOnly, errorMessage }) => {
                const error = errors[name]
                  ? errorMessage[errors[name].type]
                  : "";

                return (
                  <tr key={title}>
                    <ContentTableHead>{title}</ContentTableHead>
                    <ContentTableBody>
                      <ContentMobileTitle isRequired={isEdit && required}>
                        {title}
                      </ContentMobileTitle>
                      {isEdit ? (
                        <ContentTableInputBox>
                          <Input>
                            <Input.InputForm
                              small
                              isError={error}
                              option={{
                                ...register(name, {
                                  required,
                                }),
                                readOnly,
                              }}
                            />

                            {error && <Input.Error>{error}</Input.Error>}
                          </Input>
                        </ContentTableInputBox>
                      ) : (
                        <InputListBox>
                          <InputListLine onClick={copyClipboard}>
                            {watch(name)}
                          </InputListLine>
                        </InputListBox>
                      )}
                    </ContentTableBody>
                  </tr>
                );
              })}
            </tbody>
          </ContentTable>

          {isEdit && info && (
            <ContentInfo>
              <Info>{createGrave(info)}</Info>
            </ContentInfo>
          )}
        </>
      </ContentArea>
    </ContentContainer>
  );
};

export default InputList;
