import ArtistUpdate from "@/components/admin/artist/update";
import Loading from "@/components/common/loading";
import { useArtistProfile } from "@/hooks/api/profile";
import useUrl from "@/hooks/utils/useUrl";

const ArtistUpdatePage = () => {
  const {
    params: { id },
  } = useUrl();

  const { profile, isSuccess, ...status } = useArtistProfile({
    id,
  });

  return (
    <>
      <Loading {...status} />
      {isSuccess && <ArtistUpdate artistDetail={profile.success.data} />}
    </>
  );
};

export default ArtistUpdatePage;
