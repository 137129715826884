import styled from "styled-components";

export const FileContainer = styled.label`
  display: block;
  height: 100%;
`;

export const FileButton = styled.div`
  width: ${(props) => props.theme.pxToRem(140)};
  padding: ${(props) => props.theme.pxToRem(5)} 0;

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.bolder};
  border-radius: ${(props) => props.theme.radius.normal};

  color: ${(props) => props.theme.colors.bolder};
  text-align: center;

  cursor: pointer;
`;
