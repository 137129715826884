import { Strong, spinner } from "@/styles/common";
import styled from "styled-components";

export const TableContainer = styled.div`
  ${(props) => props.theme.medias.mobile} {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const TableWrapper = styled.table`
  width: 100%;

  td {
    text-align: center;
    vertical-align: middle;

    font-size: ${(props) => props.theme.pxToRem(13)};
  }

  ${(props) => props.theme.medias.mobile} {
    min-width: ${(props) => props.theme.pxToRem(1000)};
  }
`;

export const TableTd = styled.td`
  line-height: 18.82px;
  padding: ${(props) => `${props.theme.pxToRem(7)}`} 0;
`;

export const TableHeader = styled(TableTd)`
  width: ${(props) => props.width};
  background: ${(props) => props.theme.colors.gray3};

  font-weight: 500;
`;

export const TableStatusBox = styled(TableTd)`
  border: none;

  svg {
    animation: ${spinner} 1s linear infinite;
  }
`;

export const TableProductBox = styled.div`
  display: flex;

  padding: ${(props) => props.theme.pxToRem(8)};
`;

export const TableProductImage = styled.img`
  width: ${(props) => props.theme.pxToRem(36)};
  height: ${(props) => props.theme.pxToRem(36)};

  border: ${(props) => props.theme.pxToRem(1)} solid
    ${(props) => props.theme.colors.gray3};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const TableProductInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  text-align: left;

  margin-left: ${(props) => props.theme.pxToRem(7)};
`;

export const TableProductTitle = styled.p`
  margin-bottom: ${(props) => props.theme.pxToRem(4)};

  font-size: ${(props) => props.theme.pxToRem(13)};
`;

export const TableProductOption = styled.p`
  font-size: ${(props) => props.theme.pxToRem(11)};
  color: ${(props) => props.theme.colors.bolder};
`;

export const TableStrong = styled(Strong)`
  color: ${(props) => props.theme.colors.bolder};
`;

export const TableBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: ${(props) => props.theme.pxToRem(13)};
`;

export const TableSelectBoxWrapper = styled.div`
  margin-left: ${(props) => props.theme.pxToRem(8)};
`;

export const TableColspanTd = styled.td`
  padding: ${(props) => props.theme.pxToRem(8)};
`;

export const TableSortTd = styled(TableColspanTd)`
  height: ${(props) => props.theme.pxToRem(46)};
  padding-left: ${(props) => props.theme.pxToRem(16)};
`;

export const TablePageTd = styled.td`
  padding-bottom: ${(props) => props.theme.pxToRem(20)};
  border: none !important;
  background: none;
`;
