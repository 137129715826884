import styled from "styled-components";

export const ImageContainer = styled.label`
  display: block;
  height: 100%;
`;
export const ImageThumbnail = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background: ${(props) => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: ${(props) => props.theme.radius.normal};

  aspect-ratio: ${(props) => props.aspectRatio};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line3};

  cursor: pointer;
`;

export const ImageThumbnailIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ImageButton = styled.div`
  width: ${(props) => props.theme.pxToRem(140)};
  padding: ${(props) => props.theme.pxToRem(5)} 0;
  margin-top: ${(props) => props.theme.pxToRem(8)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.bolder};
  border-radius: ${(props) => props.theme.radius.normal};

  color: ${(props) => props.theme.colors.bolder};
  text-align: center;

  cursor: pointer;
`;

export const ImageError = styled.p`
  color: red;
  width: auto;
  margin-top: ${(props) => props.theme.pxToRem(5)};
  width: 200px;

  ${(props) => props.theme.medias.mobile} {
    font-size: ${(props) => props.theme.pxToRem(10)};
  }
`;
