import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

export const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button`
  width: 100%;

  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.primary};
  padding: ${(props) => props.theme.pxToRem(17)} 0;

  border-radius: ${(props) => props.theme.radius.normal};
`;

export const CustomLink = styled(NavLink)`
  display: inline-block;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.pxToRem(1170)};

  padding: ${(props) => `${props.theme.pxToRem(24)}`};

  border-radius: ${(props) => props.theme.radius.normal};

  ${(props) => props.theme.medias.tablet} {
    width: auto !important;
  }

  ${(props) => props.theme.medias.mobile} {
    width: auto !important;
  }
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const InfoBox = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(36)};
  padding: ${(props) => props.theme.pxToRem(25)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};
  background: ${(props) => props.theme.colors.gray3};
`;

export const SubTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-weight: 700;
  font-size: ${(props) => props.theme.pxToRem(16)};

  margin-bottom: ${(props) => props.theme.pxToRem(10)};

  ${(props) => props.isMargin && `margin-top: ${props.theme.pxToRem(40)};`}
`;

export const UpdateDate = styled.span`
  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 400;

  color: ${(props) => props.theme.colors.gray};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormSubmitButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(120)};

  padding: ${(props) => props.theme.pxToRem(10)} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;
