import { useFormContext } from "react-hook-form";
import EditInfo from "../../inputTable/editInfo";
import FileForm from "../../inputTable/fileForm";
import InputList from "../../inputTable/inputList";
import { ContentContainer } from "../Style";
import { common } from "@text";
import SettlementTypeInput from "../../inputTable/settlementTypeInput";

const {
  tableForm: {
    settlement: { bank, businessRegister, residentNumber },
  },
} = common;

const SettlementInput = ({ required, isInfo }) => {
  const { watch } = useFormContext();
  const isEdit = watch("isEdit");

  // const isUseBusinessRegistration =
  //   watch("useBusinessRegistration") === "USE";

  return (
    <ContentContainer>
      <tbody>
        {isEdit && <EditInfo />}
        <InputList config={{ ...bank, required: required.bank }} />
        {/* <SettlementTypeInput /> */}
        {/* {isUseBusinessRegistration ? ( */}
        <FileForm
          config={{
            ...businessRegister,
            required: required.businessRegister,
            isInfo,
          }}
        />
        {/* ) : (
          <InputList
            config={{
              ...residentNumber,
              required: true,
            }}
          />
        )} */}
      </tbody>
    </ContentContainer>
  );
};

export default SettlementInput;
