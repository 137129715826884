import { Strong } from "@/styles/common";
import styled from "styled-components";

export const ResultTableTd = styled.td`
  padding: ${(props) => props.theme.pxToRem(15)} 0;
`;

export const ResultStrong = styled(Strong)`
  color: ${(props) => props.theme.colors.bolder};
`;
