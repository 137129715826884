import { Button } from "@/styles/common";
import styled from "styled-components";

export const InitialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  padding: ${(props) => props.theme.pxToRem(60)} 0;

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const InitialInfo = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(24)};

  p {
    text-align: center;
    line-height: 19px;
  }
`;

export const InitialButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(115)};
  padding: ${(props) => props.theme.pxToRem(10)} 0;
`;
