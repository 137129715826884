import styled from "styled-components";

export const SalesTotalPrice = styled.span`
  display: inline-block;
  padding: ${(props) => props.theme.pxToRem(16)} 0;

  font-size: ${(props) => props.theme.pxToRem(20)};
  font-weight: 700;
`;

export const SalesTotalSettlement = styled(SalesTotalPrice)`
  color: ${(props) => props.theme.colors.bolder};
`;
