import { Button, ContentWrapper, SubTitle } from "@/styles/common";
import styled from "styled-components";

export const ArtistUpdateContainer = styled(ContentWrapper)`
  h2:first-child {
    margin-top: 0;
  }
`;

export const ArtistUpdateSubTitle = styled(SubTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

export const ArtistUpdateForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const ArtistUpdateButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  padding: ${(props) => `${props.theme.pxToRem(10)}`} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;

export const ArtistUpdateInfo = styled.span`
  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray};
`;
