import { createBrowserRouter } from "react-router-dom";

import Dashboard from "@pages/admin/dashboard";
import Product from "@pages/admin/product";
import AdminLogin from "@pages/admin/login";
import ArtistList from "@pages/admin/artist/list";
import ArtistCreate from "@pages/admin/artist/create";
import ArtistUpdate from "@/pages/admin/artist/update";
import SettlementRequest from "@pages/admin/settlement/request";
import SettlementCreate from "@/pages/admin/settlement/create";
import SettlementList from "@pages/admin/settlement/list";
import SettlementRegistration from "@/pages/admin/settlement/list/registration";
import Setting from "@pages/admin/setting";
import SettingCreate from "@pages/admin/setting/create";
import SettingUpdate from "@pages/admin/setting/update";

import ArtistSettlement from "@pages/artist/settlement";
import ArtistDashboard from "@pages/artist/dashboard";
import ArtistLogin from "@pages/artist/login";
import Password from "@pages/artist/password";
import Issuance from "@pages/artist/issuance";
import Profile from "@pages/artist/profile";
import Sales from "@pages/artist/sales";
import Information from "@pages/artist/information";

import Error from "@pages/404";
import SearchPopup from "@/pages/admin/settlement/list/search";
import ResultPopup from "@/pages/admin/settlement/list/result";
import LayoutProvider from "@/components/layout/Provider";

const getQueries = (list, url) => {
  const queries = {};
  for (const key of list) {
    const value = url.searchParams.get(key);
    if (value) {
      queries[key] = value;
    }
  }
  return queries;
};

const Router = createBrowserRouter([
  { path: "/", element: <ArtistLogin /> },
  { path: "/artist/login", element: <ArtistLogin /> },
  {
    path: "/artist/password",
    element: <Password />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      return getQueries(["type"], url);
    },
  },
  { path: "/artist/issuance", element: <Issuance /> },
  {
    path: "/artist",
    element: <LayoutProvider />,
    children: [
      { path: "", element: <ArtistDashboard /> },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "work/sales",
        element: <Sales />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(
            ["startDate", "endDate", "shoppingMall", "type", "page"],
            url
          );
        },
      },
      {
        path: "work/settlement",
        element: <ArtistSettlement />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(["startDate", "endDate", "page"], url);
        },
      },
      {
        path: "work/information",
        element: <Information />,
      },
    ],
  },
  { path: "/admin/login", element: <AdminLogin /> },
  {
    path: "/admin",
    element: <LayoutProvider />,
    children: [
      { path: "", element: <Dashboard /> },
      {
        path: "artist",
        element: <ArtistList />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(["type", "data", "page", "sort", "limit"], url);
        },
      },
      {
        path: "artist/create",
        element: <ArtistCreate />,
      },
      {
        path: "artist/update/:id",
        element: <ArtistUpdate />,
      },
      {
        path: "product",
        element: <Product />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(
            [
              "type",
              "data",
              "productSettlementSetting",
              "page",
              "sort",
              "limit",
            ],
            url
          );
        },
      },
      {
        path: "settlement/request",
        element: <SettlementRequest />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(
            [
              "startDate",
              "endDate",
              "artistId",
              "settlementStatus",
              "sort",
              "page",
              "limit",
            ],
            url
          );
        },
      },
      {
        path: "settlement/create",
        element: <SettlementCreate />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(["id", "limit", "page"], url);
        },
      },
      {
        path: "settlement/list",
        element: <SettlementList />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(
            ["id", "startDate", "endDate", "type", "page", "limit", "sort"],
            url
          );
        },
      },
      {
        path: "settlement/list/registration",
        element: <SettlementRegistration />,
      },
      {
        path: "setting",
        element: <Setting />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          return getQueries(["type", "data"], url);
        },
      },
      {
        path: "setting/create",
        element: <SettingCreate />,
      },
      {
        path: "setting/update/:id",
        element: <SettingUpdate />,
      },
    ],
  },
  {
    path: "searchPopup",
    element: <SearchPopup />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      return getQueries(["id", "page", "limit"], url);
    },
  },
  {
    path: "resultPopup",
    element: <ResultPopup />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      return getQueries(["id"], url);
    },
  },
  {
    path: "*",
    element: <Error />,
  },
]);

export default Router;
