import { Link } from "react-router-dom";
import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;

  background: ${(props) => props.theme.colors.gray3};
`;

export const ErrorTitle = styled.h1`
  margin: 200px 0 50px;

  font-size: ${(props) => props.theme.pxToRem(50)};
  font-weight: 700;
  line-height: 72.4px;

  color: ${(props) => props.theme.colors.bolder};
`;

export const ErrorInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
  padding: 60px 0;

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};

  font-size: ${(props) => props.theme.pxToRem(13)};
  line-height: 18.82px;
`;

export const ErrorLink = styled(Link)`
  padding: 10px 40px;
  margin-top: 24px;

  background: ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(14)};
  color: ${(props) => props.theme.colors.white};
`;
