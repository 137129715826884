import Input from "@/components/common/form/input";
import {
  AuthContainer,
  AuthDesc,
  AuthDescBox,
  AuthForm,
  AuthFormBox,
  AuthFormTitle,
  AuthInfo,
  AuthInnerContainer,
  AuthOption,
  AuthTitle,
} from "./Style";

const Title = ({ children }) => {
  return <AuthTitle>{children}</AuthTitle>;
};

const DescBox = ({ children }) => {
  return <AuthDescBox>{children}</AuthDescBox>;
};

const Desc = ({ children }) => {
  return <AuthDesc>{children}</AuthDesc>;
};

const Form = ({
  submit,
  register,
  errors,
  inputList,
  pattern = {},
  children,
}) => {
  //
  return (
    <AuthForm onSubmit={submit}>
      {inputList.map(
        ({ title, name, required, option, errorMessage, info }) => {
          const error = errors[name] ? errorMessage[errors[name].type] : "";

          return (
            <AuthFormBox key={title}>
              <Input>
                <Input.Title isFull>
                  <AuthFormTitle>{title}</AuthFormTitle>
                </Input.Title>
                <Input.InputForm
                  option={{
                    ...register(name, {
                      required: required,
                      pattern: pattern[name],
                    }),
                    ...option,
                  }}
                />
                {info && <Input.Info>{info}</Input.Info>}
                {error && <Input.Error>{error}</Input.Error>}
              </Input>
            </AuthFormBox>
          );
        }
      )}

      {children}
    </AuthForm>
  );
};

const Info = () => {
  return <AuthInfo>Copyright Ⓒ 일러니즘 All right reserved.</AuthInfo>;
};

const Option = ({ children }) => {
  return <AuthOption>{children}</AuthOption>;
};

const Inner = ({ children }) => {
  return <AuthInnerContainer>{children}</AuthInnerContainer>;
};

const Auth = ({ children }) => {
  return <AuthContainer>{children}</AuthContainer>;
};

Auth.Inner = Inner;
Auth.Title = Title;
Auth.DescBox = DescBox;
Auth.Desc = Desc;
Auth.Form = Form;
Auth.Info = Info;
Auth.Option = Option;

export default Auth;
