import { SubTitle } from "@/styles/common";
import styled from "styled-components";

export const SalesSubTitle = styled(SubTitle)`
  display: flex;
  justify-content: space-between;
`;

export const SalesSpan = styled.span`
  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 400;

  color: ${(props) => props.theme.colors.gray};
`;

export const SalesListWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(30)};
`;

export const SalesListCategoryTd = styled.td`
  padding: ${(props) =>
    `${props.theme.pxToRem(20)} ${props.theme.pxToRem(16)}`};
`;

export const SalesListCategoryBox = styled.div`
  display: flex;
`;

export const SalesListSortWrapper = styled.div`
  ${(props) => props.theme.sort.left.width};
  ${(props) => props.theme.sort.center.height};
`;

export const SalesListSortButton = styled.button`
  padding: ${(props) => `${props.theme.pxToRem(8)}`};
  margin-right: ${(props) => props.theme.pxToRem(5)};

  color: ${(props) => props.theme.colors.gray4};
  border-bottom: ${(props) => props.theme.pxToRem(3)} solid transparent;

  ${(props) =>
    props.isActive &&
    `
    color: ${props.theme.colors.bolder};
    border-bottom: ${props.theme.pxToRem(3)} solid ${props.theme.colors.bolder};
  `}
`;

export const SalesListTooltip = styled.div`
  position: relative;

  ${(props) => props.theme.sort.center.width};
  ${(props) => props.theme.sort.center.height};

  width: ${(props) => props.theme.pxToRem(20)};
  height: ${(props) => props.theme.pxToRem(20)};

  background: lightgray;
  border-radius: 50%;

  color: white;
  font-weight: 400;

  cursor: pointer;
`;
