import {
  createSettlement,
  createSettlementReport,
  getSettlement,
  getSettlementDetail,
  getSettlementReport,
  getSettlementRequest,
  getSettlementRequestCode,
  getUserSettlementInfo,
  updateUserSettlementInfo,
} from "@/api/settlement";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useUserSettlementInfo = (params = { id: "" }) => {
  const {
    data: settlementInfo,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["settlementInfo", params], () =>
    getUserSettlementInfo(params)
  );

  return {
    settlementInfo,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlementRequest = ({
  startDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
  endDate = moment().format("YYYY-MM-DD"),
  artistId = "",
  settlementStatus = "",
  sort = "artistNameEng",
  page = "0",
  limit = "10",
}) => {
  const {
    data: settlementRequest,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [
      "settlementRequest",
      startDate,
      endDate,
      artistId,
      settlementStatus,
      sort,
      page,
      limit,
    ],
    () =>
      getSettlementRequest({
        startDate,
        endDate,
        artistId,
        settlementStatus,
        sort,
        page,
        limit,
      })
  );

  return {
    settlementRequest,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlementRequestCode = ({
  id = "",
  limit = 10,
  page = 0,
}) => {
  const {
    data: settlementRequestCode,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["settlementRequestCode", id, limit, page], () =>
    getSettlementRequestCode({
      id,
      limit,
      page,
    })
  );

  return {
    settlementRequestCode,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlementReport = ({ page = "0" }) => {
  const {
    data: settlementReport,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["settlementReport", page], () =>
    getSettlementReport({ page })
  );

  return {
    settlementReport,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlement = ({
  id = "",
  startDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
  endDate = moment().format("YYYY-MM-DD"),
  type = "",
  page = "0",
  limit = "10",
  sort = "date",
}) => {
  const {
    data: settlement,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    ["settlement", id, startDate, endDate, type, page, limit, sort],
    () =>
      getSettlement({
        id,
        startDate,
        endDate,
        type,
        page,
        limit,
        sort,
      })
  );

  return {
    settlement,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlementDetail = ({ reportCode }) => {
  const {
    data: settlementDetail,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["settlementDetail", reportCode], () =>
    getSettlementDetail({ reportCode })
  );

  return {
    settlementDetail,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useUpdateUserSettlementInfoMutation = (
  successCallback
) => {
  const queryClient = useQueryClient();

  const updateUserSettlementInfoMutation = useMutation(
    updateUserSettlementInfo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("information");
        successCallback();
      },
    }
  );

  const submit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    updateUserSettlementInfoMutation.mutate(formData);
  };

  return submit;
};

export const useCreateSettlementReportMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createSettlementReport, {
    onSuccess: () => {
      queryClient.invalidateQueries("settlementReport");
    },
  });

  const submit = (data) => {
    mutate(data);
  };

  return [submit, isLoading];
};

export const useCreateSettlementMutation = (
  successCallback,
  errorCallback
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createSettlement, {
    onSuccess: () => {
      queryClient.invalidateQueries("settlement");
      successCallback();
    },
    onError: (e) => {
      errorCallback();
    },
  });

  const submit = (data) => {
    const formData = new FormData();

    formData.append("id", data.artist);
    formData.append("type", data.settlementType);
    formData.append("requestCode", data.requestCode);
    formData.append("file", data.settlementFile);
    formData.append("startDate", data.startDate);
    formData.append("endDate", data.endDate);

    mutate(formData);
  };

  return [submit, isLoading];
};
