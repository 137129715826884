import QueryString from "qs";
import {
  createSearchParams,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const getPath = (pathname) => {
  const blank = new Array(10).fill("");

  const path = pathname.split("/");
  path.shift();

  return path.concat(blank);
};

const getQuery = (search) => {
  return QueryString.parse(search, { ignoreQueryPrefix: true });
};

const useUrl = () => {
  const { pathname, search } = useLocation();
  const searchQuires = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const changeQuery = (query) => {
    const params = {};

    Object.keys(searchQuires).forEach((key) => {
      params[key] = searchQuires[key];
    });

    navigate({
      search: `?${createSearchParams({
        ...params,
        ...query,
        page: 0,
      })}`,
    });
  };

  const changeLink = (link) => {
    navigate(link);
  };

  return {
    path: getPath(pathname),
    params,
    query: getQuery(search),
    changeQuery,
    changeLink,
  };
};

export default useUrl;
