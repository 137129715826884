import { common } from "@text";
import { EditInfoContainer, EditInfoStrong } from "./Style";
import { useFormContext } from "react-hook-form";

const {
  editInfo: { text, strong, text2 },
} = common;

const EditInfo = () => {
  const { watch } = useFormContext();
  const { isEdit } = watch();

  if (!isEdit) return null;

  return (
    <tr>
      <EditInfoContainer colSpan={2}>
        {text} <EditInfoStrong>{strong}</EditInfoStrong>
        {text2}
      </EditInfoContainer>
    </tr>
  );
};

export default EditInfo;
