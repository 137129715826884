import axios from "axios";
import { TYPE } from "./constants";
import { common } from "@text";

const {
  imageForm: { alert },
} = common;

export const stopEventBubbling = (e) => {
  e.stopPropagation();
};

export const numberWithCommas = (value) => {
  if (value == null) {
    return "-";
  }
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithoutCommas = (value) => {
  return Number(String(value).replace(/,/g, ""));
};

export const exchangeDate = (date) => {
  const dateObject =
    typeof date === TYPE.string ? new Date(date) : date;

  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(dateObject.getTime() + offset);

  return koreaNow.toISOString().replace("T", " ");
};

export const sliceDate = (date, type = "default") => {
  if (type === "default") {
    return exchangeDate(date).slice(0, 10);
  } else if (type === "min") {
    return exchangeDate(date).slice(0, 16);
  }
};

export const createGrave = (list) => {
  return list.reduce((acc, cur) => `${acc}${cur}\n`, ``);
};

export const convertByte = (size) => {
  return (size / 1000000).toFixed(0);
};

export const sendPopupMessage = (message) => {
  window.opener.postMessage(message, window.location.origin);
  window.close();
};

export const convertFile = (url) => {
  return url ? `${process.env.REACT_APP_SERVER_URL}/${url}` : "";
};

export const downloadFile = (url, fileName) => {
  axios
    .get(url, {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        // if (percentCompleted === 100) callback();
      },
    })
    .then((res) => {
      const href = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(href);
    });
};

export const imageExtension = (e) => {
  const file = e.target.files[0];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (
    fileExtension !== "png" &&
    fileExtension !== "jpg" &&
    fileExtension !== "jpeg"
  ) {
    window.alert(alert + "( PNG / JPG / JPEG )");
    e.target.value = "";
    return false;
  }

  return true;
};

export const excelExtension = (e) => {
  const file = e.target.files[0];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (fileExtension !== "xlsx" && fileExtension !== "xls") {
    window.alert(alert + "( XLSX / XLS )");
    e.target.value = "";
    return false;
  }

  return true;
};
