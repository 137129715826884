import { useEffect, useState } from "react";
import {
  ImageButton,
  ImageContainer,
  ImageError,
  ImageThumbnail,
  ImageThumbnailIcon,
} from "./Style";
import { common } from "@text";
import { imageExtension } from "@/utils/function";

const {
  imageForm: { button },
} = common;

const exchangeImage = (image, callback) => {
  if (typeof image === "object") {
    const reader = new FileReader();

    reader.onloadend = () => {
      callback(reader.result);
    };

    reader.readAsDataURL(image);
  } else {
    callback(image);
  }
};

const Image = ({ option, aspectRatio, error, image, isEdit, children }) => {
  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    exchangeImage(image, setImgUrl);
  }, [image]);

  console.log(imgUrl);
  console.log(image);

  const changeImage = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    imageExtension(e);

    option.onChange({
      target: {
        name: option.name,
        value: file,
      },
    });
  };

  return (
    <ImageContainer>
      <input
        type="file"
        hidden
        multiple={false}
        disabled={!isEdit}
        onChange={changeImage}
        accept=".png, .jpg, .jpeg"
      />

      <ImageThumbnail background={imgUrl} aspectRatio={aspectRatio}>
        {!image && <ImageThumbnailIcon>{children}</ImageThumbnailIcon>}
      </ImageThumbnail>

      {isEdit && <ImageButton>{button}</ImageButton>}
      {error && <ImageError>{error}</ImageError>}
    </ImageContainer>
  );
};

export default Image;
