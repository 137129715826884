import styled from "styled-components";

export const LoginTitleLogo = styled.img``;

export const LoginDesc = styled.span`
  display: inline-block;
  padding: ${(props) =>
    `${props.theme.pxToRem(18)} 0 ${props.theme.pxToRem(60)}`};
  color: ${(props) => props.theme.colors.darkgray};
`;
