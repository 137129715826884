import { CgSpinner } from "@react-icons/all-files/cg/CgSpinner";
import { LoadingBox } from "./Style";
import { common } from "@text";

const { error } = common;

const Loading = ({ isLoading, isError }) => {
  return (
    <>
      {isLoading && (
        <LoadingBox>
          <CgSpinner />
        </LoadingBox>
      )}

      {isError && <LoadingBox>{error}</LoadingBox>}
    </>
  );
};

export default Loading;
