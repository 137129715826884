import { FormProvider, useForm } from "react-hook-form";
import { ProfileButton, ProfileForm } from "./Style";
import Initial from "@/components/common/initial";
import { artist } from "@text";
import ProfileInput from "@/components/common/tableForm/profileInput";
import { useArtistProfileUpdateMutation } from "@/hooks/api/profile";
import { convertFile } from "@/utils/function";

const {
  profile: {
    initial,
    button: { edit, content },
    required,
    alert: { first, update },
  },
} = artist;

const defaultValues = {
  id: "",
  password: "",
  passwordConfirm: "",
  nameKor: "",
  nameEng: "",
  profileImg: "",
  storeBannerImg: "",
  introKor: "",
  introEng: "",
  abroadSaleOption: "",
  phoneNum: "",
  email: "",
  snsInstagram: "",
  snsTwitter: "",
  snsBlog: "",
  snsYoutube: "",
  snsGrafolio: "",
  snsEtc1: "",
  snsEtc2: "",

  lastLoginDate: "",
  isEdit: false,
};

const useProfileForm = (userData) => {
  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...userData.data,
      profileImg: convertFile(userData.data.profileImg),
      storeBannerImg: convertFile(userData.data.storeBannerImg),
      isInitial: userData.message === "EMPTY PROFILE",
    },
  });

  const successCallback = () => {
    changeEdit();
    alert(userData.isInitial ? first : update);
  };
  const profileMutation = useArtistProfileUpdateMutation(successCallback);

  const changeEdit = () => {
    form.setValue("isEdit", !form.getValues("isEdit"));
  };

  const onSubmit = (data) => {
    const { isEdit } = data;

    if (isEdit) {
      profileMutation(data);
    } else {
      changeEdit();
    }
  };

  return {
    form,
    submit: form.handleSubmit(onSubmit),
    isEdit: form.watch("isEdit"),
    isInitial: form.watch("isInitial"),
  };
};

const Profile = ({ userData }) => {
  const { form, submit, isEdit, isInitial } = useProfileForm(userData);
  const button = isEdit ? edit : content;

  return (
    <FormProvider {...form}>
      {isInitial ? (
        <Initial form={initial} />
      ) : (
        <ProfileForm onSubmit={submit}>
          <ProfileInput required={required} />
          <ProfileButton type="submit">{button}</ProfileButton>
        </ProfileForm>
      )}
    </FormProvider>
  );
};

export default Profile;
