import styled from "styled-components";

export const MenuListContainer = styled.div`
  display: inline-block;
  width: 100%;

  padding-top: ${(props) => props.theme.pxToRem(82)};
`;

export const MenuListTitle = styled.h2`
  font-size: ${(props) => props.theme.pxToRem(11)};

  padding: 0 ${(props) => props.theme.pxToRem(36)};
  margin-bottom: ${(props) => props.theme.pxToRem(12)};
  font-weight: 700;
`;

export const MenuListWrapper = styled.ul``;
