import Table from "@/components/common/table";
import { admin } from "@text";
import { SubTitle } from "@/styles/common";
import CreateItem from "./item";
import useUrl from "@/hooks/utils/useUrl";
import { useSettlementReport } from "@/hooks/api/settlement";

const {
  settlement: {
    create: {
      title,
      table: { header },
    },
  },
} = admin;

const Result = () => {
  const { query } = useUrl();

  const { settlementReport, isSuccess, ...status } = useSettlementReport(query);

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>

      <Table>
        <Table.Head>
          <Table.Row>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Loading {...status} />
        {isSuccess && (
          <CreateItem settlementData={settlementReport.success.data} />
        )}
      </Table>
    </>
  );
};

export default Result;
