import styled from "styled-components";

export const HeaderProfileContainer = styled.div`
  position: relative;
`;

export const HeaderProfileButton = styled.button`
  width: ${(props) => props.theme.pxToRem(32)};
  height: ${(props) => props.theme.pxToRem(32)};

  border-radius: 50%;

  overflow: hidden;
`;

export const HeaderProfileMenu = styled.div`
  position: absolute;
  top: ${(props) => `calc(100% + ${props.theme.pxToRem(10)})`};
  right: 0;
  z-index: 20;

  ${(props) => props.theme.sort.center.height};
  flex-direction: column;

  width: ${(props) => props.theme.pxToRem(200)};
  max-height: ${(props) => (props.isOpen ? "300px" : "0")};

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.5s;

  overflow: hidden;
  background-color: lightgray;

  padding: ${(props) => `${props.theme.pxToRem(20)}`};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.radius.normal};

  background: ${(props) => props.theme.colors.white};
`;

export const HeaderProfileImage = styled.img`
  width: ${(props) => props.theme.pxToRem(props.size)};
  height: ${(props) => props.theme.pxToRem(props.size)};

  border-radius: 50%;
`;

export const HeaderProfileTitle = styled.p`
  margin-top: ${(props) => props.theme.pxToRem(10)};
  margin-bottom: ${(props) => props.theme.pxToRem(17)};

  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 500;
`;

export const HeaderProfileTitleItem = styled.span`
  display: block;
  width: 100%;

  text-align: center;
`;

export const HeaderProfileItemButton = styled.button`
  margin-top: ${(props) => props.theme.pxToRem(7)};
  width: 100%;
  padding: ${(props) => props.theme.pxToRem(8)} 0;

  font-size: ${(props) => props.theme.fonts.xs};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.radius.normal};

  background: ${(props) => props.theme.colors.white};

  :hover {
    color: ${(props) => props.theme.colors.bolder};
    border-color: ${(props) => props.theme.colors.bolder};
  }
`;
