import { useRef } from "react";
import useExternalToggle from "@hooks/utils/useExternalToggle";
import { TooltipContainer } from "./Style";

const optionList = {
  left: "position-left",
  right: "position-right",
  top: "position-top",
  bottom: "position-bottom",
  moveable: "movable",
  fade: "fade",
  smooth: "smooth",
  half: "half-arrow",
  multiline: "multiline",
  pre: "pre",
  none: "none",
};

const getOption = (option) => {
  return option.reduce((acc, cur) => acc + " " + optionList[cur], "");
};

const Tooltip = ({ children, tooltip, type, isToggle, ...option }) => {
  const tooltipRef = useRef();
  const [toggle, toggleOpen] = useExternalToggle({
    defaultValue: false,
    target: tooltipRef,
  });

  return (
    <TooltipContainer
      {...option}
      isToggle={isToggle}
      toggle={toggle}
      ref={tooltipRef}
    >
      <span
        data-tooltip={tooltip}
        className={getOption(type)}
        onClick={toggleOpen}
      >
        {children}
      </span>
    </TooltipContainer>
  );
};

export default Tooltip;
