import Search from "@/components/common/search";
import { CreateContainer } from "./Style";
import Result from "@/components/admin/settlement/create";
import { admin } from "@text";
import { useArtistIdList } from "@/hooks/api/artist";
import { useCreateSettlementReportMutation } from "@/hooks/api/settlement";
import dayjs from "dayjs";
import Loading from "@/components/common/loading";

const {
  settlement: {
    create: {
      search: {
        button,
        select: { title: selectTitle },
        calendar: { title: calendarTitle },
      },
    },
  },
} = admin;

const Create = () => {
  const { artistIdList, isSuccess: isArtistSuccess } =
    useArtistIdList();
  const [createSettlementReportMutation, isLoading] =
    useCreateSettlementReportMutation();

  const submit = ({ endDate, startDate, idList }) => {
    if (idList?.length) {
      const body = {
        idList,
        endDate,
        startDate,
      };

      createSettlementReportMutation(body);
    }
  };

  const checkEndDate = (date) => {
    const current = dayjs().subtract(7, "day");

    if (current.isBefore(date)) {
      alert("오늘 포함 이후 날짜는 선택할 수 없습니다.");
      return true;
    }

    return false;
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <CreateContainer>
      <Search button={button} submit={submit}>
        {isArtistSuccess && (
          <Search.MultiSelect
            title={selectTitle}
            artistList={artistIdList.success.data}
            name={"id"}
            multiName={"idList"}
            blank={"선택"}
          />
        )}
        <Search.Calendar
          isMonth={true}
          title={calendarTitle}
          checkEndDate={checkEndDate}
        />
      </Search>

      <Result />
    </CreateContainer>
  );
};

export default Create;
