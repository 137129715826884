import { SubTitle } from "@/styles/common";
import {
  DashboardCancelButton,
  DashboardContainer,
  DashboardContentBox,
  DashboardContentSplitBox,
  DashboardContentSplitBoxContent,
  DashboardContentSplitBoxTitle,
  DashboardPopup,
} from "./Style";
import { artist } from "@text";
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const {
  dashboard: { info, support, request },
} = artist;

const Dashboard = () => {
  const [isPopup, setIsPopup] = useState(false);

  useEffect(() => {
    const current = dayjs();
    const start = dayjs("2021-01-15");

    if (current.isAfter(start)) {
      // setIsPopup(true);
    }
  }, []);

  return (
    <DashboardContainer>
      {isPopup && (
        <DashboardPopup>
          <h5>사이트 이용 안내</h5>
          <p>
            정산 사이트 오픈 준비중 입니다.
            <br />
            1월 15일부터 사이트 정상 이용 가능합니다.
          </p>
          <DashboardCancelButton onClick={() => setIsPopup(false)}>
            확인
          </DashboardCancelButton>
        </DashboardPopup>
      )}

      <SubTitle>{info.title}</SubTitle>
      <DashboardContentBox>
        {info.descList.map((desc) => (
          <p key={desc}>{desc}</p>
        ))}
      </DashboardContentBox>

      <SubTitle isMargin>{support.title}</SubTitle>
      <DashboardContentSplitBox>
        <DashboardContentSplitBoxTitle>
          {support.desc.head}
        </DashboardContentSplitBoxTitle>

        <DashboardContentSplitBoxContent>
          {support.desc.list.map((desc) => (
            <p key={desc}>{desc}</p>
          ))}
        </DashboardContentSplitBoxContent>
      </DashboardContentSplitBox>

      <SubTitle isMargin>{request.title}</SubTitle>
      <DashboardContentSplitBox>
        <a
          href="https://drive.google.com/drive/folders/1lBA-kW7yF8hgELJK-T_R5Jve-el0FxZ3"
          target="_blank"
          rel="noreferrer"
        >
          <DashboardContentSplitBoxTitle isLink>
            {request.desc.head}
          </DashboardContentSplitBoxTitle>
        </a>

        <DashboardContentSplitBoxContent>
          {request.desc.list.map((desc) => (
            <p key={desc}>{desc}</p>
          ))}
        </DashboardContentSplitBoxContent>
      </DashboardContentSplitBox>
    </DashboardContainer>
  );
};

export default Dashboard;
