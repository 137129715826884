import { artist } from "@text";
import { SubTitle, UpdateDate } from "@/styles/common";
import Table from "@/components/common/table";
import {
  ModalButtonBox,
  ModalCommonButton,
  ModalConfirmButton,
  UnsettlementInfoText,
  UnsettlementTooltip,
  UnsettlementTooltipBox,
} from "./Style";
import { useState } from "react";
import useToggle from "@/hooks/utils/useToggle";
import Modal from "@/components/common/modal";
import { createGrave, sliceDate } from "@/utils/function";
import { STATUS } from "@/utils/constants";
import UnsettledResult from "./item";
import { useCreateSettlementMutation } from "@/hooks/api/report";
import Tooltip from "@/components/common/tooltip";
import { SalesListTooltip } from "../../sales/result/Style";
import Loading from "@/components/common/loading";

const {
  sales: {
    listUpdate,
    tooltip: { icon },
  },
  settlement: { unsettledTitle, unsettledTableHeader, modal },
} = artist;

const useModal = () => {
  const [state, setState] = useState(STATUS.alert);
  const [isShow, updateModal] = useToggle(false);

  const update = () => {
    updateModal();
    setState(STATUS.alert);
  };

  const [createSettlementMutation, isLoading] =
    useCreateSettlementMutation(() => setState(STATUS.success));
  const { modalTitle, modalDesc, modalButton } = modal[state];

  const modalEvent = {
    alert: () => {
      updateModal();
    },
    success: () => {
      update();
    },
    fail: () => {
      update();
    },
  };

  const ableModal = () => {
    updateModal();
  };

  const disableModal = () => {
    setState(STATUS.fail);
    updateModal();
  };

  const amountModal = () => {
    // setState(STATUS.amount);
    // updateModal();
  };

  const modalActive = (settlementRequest, amount) => {
    const isActive = settlementRequest && amount !== 0;

    return isActive
      ? ableModal()
      : amount === 0
      ? amountModal()
      : disableModal();
  };

  return {
    state,
    isShow,
    updateModal,
    modal: {
      modalTitle,
      modalDesc,
      modalButton,
      modalEvent: modalEvent[state],
    },
    modalActive,
    submit: createSettlementMutation,
    isLoading,
  };
};

const Unsettled = ({ unsettlementInfo }) => {
  const {
    state,
    isShow,
    modal: { modalTitle, modalDesc, modalButton, modalEvent },
    modalActive,
    submit,
    isLoading,
  } = useModal();

  const lastUpdateDate = sliceDate(
    unsettlementInfo.lastUpdateDate,
    "min"
  );

  if (isLoading) {
    <Loading isLoading={isLoading} />;
  }

  return (
    <>
      <SubTitle>
        <UnsettlementTooltipBox>
          {unsettledTitle}
          <Tooltip
            isToggle
            type={["bottom", "fade", "smooth", "pre", "none"]}
            tooltip={createGrave([
              "각 국가 몰의 금액을 클릭하면 해당 기간의 판매 내역을 조회할 수 있습니다.",
            ])}
            width={480}
            background={"white"}
            color={"#333333"}
          >
            <UnsettlementTooltip>{icon}</UnsettlementTooltip>
          </Tooltip>
        </UnsettlementTooltipBox>
        <UpdateDate>
          {listUpdate} {lastUpdateDate}
        </UpdateDate>
      </SubTitle>
      <Table>
        <Table.Head>
          <Table.Row>
            {unsettledTableHeader.map(({ name, width }) => (
              <Table.Header width={width} key={name}>
                {name}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <UnsettledResult
            {...unsettlementInfo}
            modalActive={modalActive}
          />
        </Table.Body>
      </Table>
      <UnsettlementInfoText>
        * 정산 금액은 정산 승인일로부터 전월 마지막 날까지 집계됩니다.
      </UnsettlementInfoText>

      <Modal isShow={isShow}>
        <Modal.Title>{modalTitle}</Modal.Title>
        {modalDesc.map((desc) => {
          return <Modal.Desc key={desc}>{desc}</Modal.Desc>;
        })}
        {state !== STATUS.alert && (
          <Modal.Button onClick={modalEvent}>
            {modalButton}
          </Modal.Button>
        )}
        {state === STATUS.alert && (
          <ModalButtonBox>
            <ModalCommonButton onClick={modalEvent}>
              {modalButton.cancel}
            </ModalCommonButton>
            <ModalConfirmButton disabled={isLoading} onClick={submit}>
              {isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                modalButton.confirm
              )}
            </ModalConfirmButton>
          </ModalButtonBox>
        )}
      </Modal>
    </>
  );
};

export default Unsettled;
