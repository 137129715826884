import { useFormContext } from "react-hook-form";
import Banner from "@/components/common/inputTable/banner";
import Intro from "@/components/common/inputTable/intro";
import RadioList from "@/components/common/inputTable/radioList";
import ProfileImage from "@/components/common/inputTable/profileImage";
import InputList from "@/components/common/inputTable/inputList";
import EditInfo from "@/components/common/inputTable/editInfo";
import SingleInput from "@/components/common/inputTable/singleInput";
import usePattern from "@/hooks/utils/usePattern";
import { common } from "@text";
import { ContentContainer } from "../Style";
import ButtonToggle from "../../inputTable/button";
import { PrimaryButton } from "../../inputTable/button/Style";
import { useIssuanceMutation } from "@/hooks/api/auth";
import { SingleButton } from "../../inputTable/singleInput/Style";

const {
  tableForm: {
    profile: {
      id,
      passwordButton,
      password,
      passwordConfirm,
      name,
      sns,
      banner,
      intro,
      increase,
      contact,
      image,
    },
  },
} = common;

const ProfileInput = ({ required, isAdmin }) => {
  const { watch, setValue, getValues } = useFormContext();
  const [issuanceMutation, _] = useIssuanceMutation(
    () => {
      window.alert("임시 비밀번호를 발급했습니다.");
    },
    () => {
      window.alert("임시 비밀번호 발급에 실패했습니다.");
    }
  );

  const isEdit = watch("isEdit");
  const isUpdatePassword = watch("isUpdatePassword");
  const currPassword = watch("password");
  const pattern = usePattern(currPassword);

  const clickIssuance = () => {
    const { id, email } = getValues();

    issuanceMutation({ id, email });
  };

  return (
    <ContentContainer>
      <tbody>
        <EditInfo />
        <SingleInput
          config={{
            ...id,
            required: required.id,
            option: {
              readOnly: true,
            },
          }}
        ></SingleInput>
        {isEdit &&
          (isUpdatePassword ? (
            <>
              <SingleInput
                config={{
                  ...password,
                  required: required.password,
                  option: {
                    type: "password",
                  },
                  pattern: pattern.password,
                }}
              >
                <SingleButton
                  onClick={() =>
                    setValue("isUpdatePassword", !isUpdatePassword)
                  }
                  type="button"
                >
                  {passwordConfirm.button}
                </SingleButton>
              </SingleInput>
              <SingleInput
                config={{
                  ...passwordConfirm,
                  option: {
                    type: "password",
                  },
                  pattern: pattern.passwordConfirm,
                  required: currPassword,
                }}
              />
            </>
          ) : (
            <ButtonToggle
              config={{
                ...passwordButton,
              }}
            >
              {isAdmin && (
                <PrimaryButton onClick={clickIssuance} type="button">
                  {passwordButton.buttonList.reset}
                </PrimaryButton>
              )}
              <PrimaryButton
                onClick={() => setValue("isUpdatePassword", !isUpdatePassword)}
                type="button"
              >
                {passwordButton.buttonList.edit}
              </PrimaryButton>
            </ButtonToggle>
          ))}
        <InputList config={{ ...name, required: required.name }} />
        <ProfileImage
          config={{ ...image, required: required.image, isAdmin }}
        />
        <Banner config={{ ...banner, required: required.banner, isAdmin }} />
        <Intro
          config={{
            ...intro,
            type: intro.typeName["kr"],
            required: required.intro,
          }}
        />
        <Intro
          config={{
            ...intro,
            type: intro.typeName["en"],
            required: required.intro,
          }}
        />
        <RadioList config={{ ...increase, required: required.increase }} />
        <SingleInput
          config={{
            ...contact.call,
            pattern: pattern.call,
            required: required.call,
          }}
        />
        <SingleInput
          config={{
            ...contact.email,
            pattern: pattern.email,
            required: required.email,
          }}
        />
        <InputList config={{ ...sns, required: required.sns }} />
      </tbody>
    </ContentContainer>
  );
};

export default ProfileInput;
