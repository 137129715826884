import { Button } from "@/styles/common";
import styled from "styled-components";

export const SearchPopupButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  padding: ${(props) => props.theme.pxToRem(10)} 0;
  margin-left: ${(props) => props.theme.pxToRem(6)};
`;

export const SearchPopupCancelButton = styled(SearchPopupButton)`
  color: ${(props) => props.theme.colors.primary};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.white};

  margin: 0;
  margin-right: ${(props) => props.theme.pxToRem(6)};
`;
