import { useFormContext } from "react-hook-form";
import Checkbox from "../../form/checkbox";
import { CheckBoxListTableBox } from "./Style";
import {
  ContentArea,
  ContentContainer,
  ContentError,
  ContentTable,
  ContentTableBody,
  ContentTableHead,
  ContentTitle,
} from "../Style";

const CheckboxList = ({ config: { title, list, required, errorMessage } }) => {
  const { register, setValue, getValues, watch } = useFormContext();
  const { isEdit } = watch();

  const changeChecked = (e, value, children) => {
    if (children.length !== 0) {
      const { accessCategory } = getValues();
      let checkList = [];
      if (e.target.checked) {
        const childrenValue = children.map(({ value }) => value);
        checkList = [...new Set([...accessCategory, ...childrenValue, value])];
      } else {
        const childrenValue = children.map(({ value }) => value);
        const filtered = accessCategory.filter((item) => {
          return !childrenValue.includes(item) && item !== value;
        });
        checkList = filtered;
      }

      setValue("accessCategory", checkList);
    } else {
      register("accessCategory").onChange(e);
    }
  };

  const changeChildChecked = (e, value, childValue) => {
    if (e.target.checked) {
      register("accessCategory").onChange(e);
    } else {
      const { accessCategory } = getValues();
      setValue("accessCategory", [
        ...accessCategory.filter(
          (item) => item !== value && item !== childValue
        ),
      ]);
    }
  };

  const error = watch("checkAccessCategory") ? errorMessage["required"] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>
        <ContentTable>
          <tbody>
            {list.map(({ title, value, children }) => {
              return (
                <tr key={title}>
                  <ContentTableHead>
                    <CheckBoxListTableBox>
                      <Checkbox>
                        <Checkbox.Form
                          option={{
                            value,
                            ...register("accessCategory"),
                            onChange: (e) => {
                              changeChecked(e, value, children);
                            },
                          }}
                        />
                        <Checkbox.Title>{title}</Checkbox.Title>
                      </Checkbox>
                    </CheckBoxListTableBox>
                  </ContentTableHead>

                  <ContentTableBody>
                    <CheckBoxListTableBox>
                      {children.map(
                        ({ title: childTitle, value: childValue }) => {
                          return (
                            <Checkbox key={childTitle}>
                              <Checkbox.Form
                                option={{
                                  ...register("accessCategory"),
                                  value: childValue,
                                  onChange: (e) =>
                                    changeChildChecked(e, value, childValue),
                                }}
                              />
                              <Checkbox.Title>{childTitle}</Checkbox.Title>
                            </Checkbox>
                          );
                        }
                      )}
                    </CheckBoxListTableBox>
                  </ContentTableBody>
                </tr>
              );
            })}
          </tbody>
        </ContentTable>

        {error && <ContentError>{error}</ContentError>}
      </ContentArea>
    </ContentContainer>
  );
};

export default CheckboxList;
