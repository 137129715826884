import { Button } from "@/styles/common";
import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  ${(props) => props.theme.sort.center.width};
  ${(props) => props.theme.sort.center.height};

  display: ${(props) => (props.isShow ? "flex" : "none")};

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.1);
`;

export const ModalInnerContainer = styled.div`
  ${(props) => props.theme.sort.center.width};
  ${(props) => props.theme.sort.center.height};
  flex-direction: column;

  width: ${(props) => props.theme.pxToRem(480)};

  padding: ${(props) =>
    props.isFull
      ? props.theme.pxToRem(16)
      : `${props.theme.pxToRem(40)} ${props.theme.pxToRem(42)}`};
  background: white;

  border-radius: ${(props) => props.theme.radius.normal};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
`;

export const ModalTitle = styled.h2`
  margin-bottom: ${(props) => props.theme.pxToRem(8)};

  line-height: ${(props) => props.theme.pxToRem(31)};

  font-size: ${(props) => props.theme.pxToRem(22)};
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
`;

export const ModalDesc = styled.p`
  font-size: ${(props) => props.theme.pxToRem(14)};
  color: ${(props) => props.theme.colors.line2};

  line-height: ${(props) => props.theme.pxToRem(20)};
`;

export const ModalButton = styled(Button)`
  width: 100%;

  margin-top: ${(props) => props.theme.pxToRem(28)};
`;
