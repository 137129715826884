import styled from "styled-components";

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .none:before {
    display: none;
  }

  .none:after {
    border: 1px solid #d9dee3;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
  }

  [data-tooltip] {
    position: relative;
    display: inline-block;
  }

  [data-tooltip]:before,
  [data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 999999;
  }

  ${(props) =>
    props.isToggle
      ? props.toggle &&
        `
    [data-tooltip]:before,
    [data-tooltip]:after {
      visibility: visible;
      opacity: 1;
    }
  `
      : `
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after {
    visibility: visible;
    opacity: 1;
  }
  `}

  [data-tooltip]:before {
    content: "";
    position: absolute;
    border-width: ${(props) => props.theme.pxToRem(6)};
    border-style: solid;
    border-color: transparent;
  }

  [data-tooltip]:after {
    height: ${(props) => props.theme.pxToRem(22)};
    padding: ${(props) => props.theme.pxToRem(11)}
      ${(props) => props.theme.pxToRem(11)} 0
      ${(props) => props.theme.pxToRem(11)};
    font-size: ${(props) => props.theme.pxToRem(13)};
    line-height: ${(props) => props.theme.pxToRem(11)};
    content: attr(data-tooltip);
    white-space: nowrap;
    font-weight: 400;
  }

  .pre:after {
    height: auto;
    width: ${(props) => props.theme.pxToRem(props.width)};
    padding: ${(props) => props.theme.pxToRem(11)};
    line-height: ${(props) => props.theme.pxToRem(19)};
    white-space: pre-wrap;
    text-align: left;
  }

  [data-tooltip].position-top:before {
    border-top-color: ${(props) => props.background};
  }

  [data-tooltip].position-top:after {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  [data-tooltip].position-bottom:before {
    border-bottom-color: ${(props) => props.background};
  }

  [data-tooltip].position-bottom:after {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  [data-tooltip].position-left:before {
    border-left-color: ${(props) => props.background};
  }

  [data-tooltip].position-left:after {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  [data-tooltip].position-right:before {
    border-right-color: ${(props) => props.background};
  }

  [data-tooltip].position-right:after {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }

  [data-tooltip].position-top.half-arrow:before {
    border-right: ${(props) => props.theme.pxToRem(7)} solid
      ${(props) => props.background};
  }

  [data-tooltip].position-bottom.half-arrow:before {
    border-right: ${(props) => props.theme.pxToRem(7)} solid
      ${(props) => props.background};
  }

  [data-tooltip]:hover,
  [data-tooltip]:focus {
    background-color: transparent;
  }

  .position-right.movable:before,
  .position-right.movable:after,
  .position-left.movable:before,
  .position-left.movable:after,
  .position-top.movable:before,
  .position-top.movable:after,
  .position-bottom.movable:before,
  .position-bottom.movable:after {
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }

  .position-right:before,
  .position-right:after,
  .position-left:before,
  .position-left:after {
    bottom: 50%;
  }

  .position-right:before,
  .position-left:before {
    margin-bottom: ${(props) => props.theme.pxToRem(-5)};
  }

  .position-right:after,
  .position-left:after {
    margin-bottom: ${(props) => props.theme.pxToRem(-14.66667)};
  }

  .position-right.half-arrow:before,
  .position-left.half-arrow:before {
    bottom: ${(props) => props.theme.pxToRem(16)};
    border-style: none;
    border-top: ${(props) => props.theme.pxToRem(7)} solid transparent;
  }

  .position-right:before,
  .position-right:after {
    left: 100%;
  }

  .position-right:before {
    margin-left: ${(props) => props.theme.pxToRem(-2)};
  }

  .position-right:after {
    margin-left: ${(props) => props.theme.pxToRem(10)};
  }

  .position-right.movable:before {
    margin-left: ${(props) => props.theme.pxToRem(-10)};
  }

  .position-right.movable:after {
    margin-left: ${(props) => props.theme.pxToRem(2)};
  }

  .position-right.movable:hover:before,
  .position-right.movable:hover:after {
    -webkit-transform: translateX(${(props) => props.theme.pxToRem(10)});
    -moz-transform: translateX(${(props) => props.theme.pxToRem(10)});
    -ms-transform: translateX(${(props) => props.theme.pxToRem(10)});
    -o-transform: translateX(${(props) => props.theme.pxToRem(10)});
    transform: translateX(${(props) => props.theme.pxToRem(10)});
  }

  .position-right.movable.half-arrow:before {
    margin-left: ${(props) => props.theme.pxToRem(-5)};
  }

  .position-right.half-arrow:before {
    margin-left: ${(props) => props.theme.pxToRem(3)};
    border-right: ${(props) => props.theme.pxToRem(7)} solid
      ${(props) => props.background};
  }

  .position-left:before,
  .position-left:after {
    right: 100%;
  }

  .position-left:before {
    margin-right: ${(props) => props.theme.pxToRem(-2)};
  }

  .position-left:after {
    margin-right: ${(props) => props.theme.pxToRem(10)};
  }

  .position-left.movable:before {
    margin-right: ${(props) => props.theme.pxToRem(-10)};
  }

  .position-left.movable:after {
    margin-right: ${(props) => props.theme.pxToRem(2)};
  }

  .position-left.movable:hover:before,
  .position-left.movable:hover:after {
    -webkit-transform: translateX(${(props) => props.theme.pxToRem(-10)});
    -moz-transform: translateX(${(props) => props.theme.pxToRem(-10)});
    -ms-transform: translateX(${(props) => props.theme.pxToRem(-10)});
    -o-transform: translateX(${(props) => props.theme.pxToRem(-10)});
    transform: translateX(${(props) => props.theme.pxToRem(-10)});
  }

  .position-left.movable.half-arrow:before {
    margin-right: ${(props) => props.theme.pxToRem(-5)};
  }

  .position-left.half-arrow:before {
    margin-right: ${(props) => props.theme.pxToRem(3)};
    border-left: ${(props) => props.theme.pxToRem(7)} solid
      ${(props) => props.background};
  }

  .position-bottom:before,
  .position-bottom:after,
  .position-top:before,
  .position-top:after {
    left: 50%;
  }

  .position-bottom:before,
  .position-top:before {
    transform: translateX(-50%);
  }

  .position-bottom:after,
  .position-top:after {
    margin-left: ${(props) => props.theme.pxToRem(-18)};
  }

  .position-bottom.half-arrow:before,
  .position-top.half-arrow:before {
    border-style: none;
    border-right: ${(props) => props.theme.pxToRem(7)} solid
      ${(props) => props.background};
  }

  .position-bottom:before,
  .position-bottom:after {
    top: 100%;
  }

  .position-bottom:before {
    margin-top: ${(props) => props.theme.pxToRem(-5)};
  }

  .position-bottom:after {
    margin-top: ${(props) => props.theme.pxToRem(7)};
  }

  .position-bottom.movable:before {
    margin-top: ${(props) => props.theme.pxToRem(-15)};
  }

  .position-bottom.movable:after {
    margin-top: ${(props) => props.theme.pxToRem(-3)};
  }

  .position-bottom.movable:hover:before,
  .position-bottom.movable:hover:after {
    -webkit-transform: translateY(${(props) => props.theme.pxToRem(10)});
    -moz-transform: translateY(${(props) => props.theme.pxToRem(10)});
    -ms-transform: translateY(${(props) => props.theme.pxToRem(10)});
    -o-transform: translateY(${(props) => props.theme.pxToRem(10)});
    transform: translateY(${(props) => props.theme.pxToRem(10)});
  }

  .position-bottom.movable.half-arrow:before {
    margin-top: ${(props) => props.theme.pxToRem(-10)};
  }

  .position-bottom.half-arrow:before {
    margin-top: 0;
    border-top: ${(props) => props.theme.pxToRem(7)} solid transparent;
  }

  .position-top:before,
  .position-top:after {
    bottom: 100%;
  }

  .position-top:before {
    margin-bottom: ${(props) => props.theme.pxToRem(-5)};
  }

  .position-top:after {
    margin-bottom: ${(props) => props.theme.pxToRem(7)};
  }

  .position-top.movable:before {
    margin-bottom: ${(props) => props.theme.pxToRem(-15)};
  }

  .position-top.movable:after {
    margin-bottom: ${(props) => props.theme.pxToRem(-3)};
  }

  .position-top.movable:hover:before,
  .position-top.movable:hover:after {
    -webkit-transform: translateY(${(props) => props.theme.pxToRem(-10)});
    -moz-transform: translateY(${(props) => props.theme.pxToRem(-10)});
    -ms-transform: translateY(${(props) => props.theme.pxToRem(-10)});
    -o-transform: translateY(${(props) => props.theme.pxToRem(-10)});
    transform: translateY(${(props) => props.theme.pxToRem(-10)});
  }

  .position-top.movable.half-arrow:before {
    margin-bottom: ${(props) => props.theme.pxToRem(-10)};
  }

  .position-top.half-arrow:before {
    margin-bottom: 0;
    border-bottom: ${(props) => props.theme.pxToRem(7)} solid transparent;
  }

  .smooth:after {
    -moz-border-radius: ${(props) => props.theme.pxToRem(4)};
    -webkit-border-radius: ${(props) => props.theme.pxToRem(4)};
    -ms-border-radius: ${(props) => props.theme.pxToRem(4)};
    -o-border-radius: ${(props) => props.theme.pxToRem(4)};
    border-radius: ${(props) => props.theme.pxToRem(4)};
  }

  .fade:before,
  .fade:after {
    -webkit-transition: opacity 0.2s linear, visibility 0.2s linear;
    -moz-transition: opacity 0.2s linear, visibility 0.2s linear;
    -ms-transition: opacity 0.2s linear, visibility 0.2s linear;
    -o-transition: opacity 0.2s linear, visibility 0.2s linear;
    transition: opacity 0.2s linear, visibility 0.2s linear;
  }
`;
