import { Button, ContentWrapper, SubTitle } from "@/styles/common";
import styled from "styled-components";

export const ArtistCreateContainer = styled(ContentWrapper)``;

export const ArtistCreateTitle = styled(SubTitle)`
  width: 100%;
`;

export const ArtistCreateTable = styled.table`
  width: 100%;
`;

export const ArtistCreateCheckButton = styled(Button)`
  width: auto;
  padding: ${(props) => `${props.theme.pxToRem(6)} ${props.theme.pxToRem(13)}`};
  margin-left: ${(props) => props.theme.pxToRem(8)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};

  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 500;
`;

export const ArtistCreateSubmitButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(112)};

  padding: ${(props) => `${props.theme.pxToRem(10)}`} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;
