import Table from "@/components/common/table";
import { ListTableButtonBox, ListTableCreateButton } from "./Style";
import { admin } from "@text";
import { SubTitle } from "@/styles/common";
import useUrl from "@/hooks/utils/useUrl";
import { useSettlement } from "@/hooks/api/settlement";
import ListItem from "./item";

const {
  settlement: {
    list: {
      title,
      table: {
        sort: { unit, list },
        header,
        button,
      },
    },
  },
} = admin;

const Result = () => {
  const { query, changeLink } = useUrl();
  const { settlement, isSuccess, ...status } = useSettlement(query);

  const total = settlement?.success.data.totalItem ?? 0;

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={6}
              length={total}
              unit={unit}
              sortWidth={"150"}
              sortList={list}
            />
          </Table.Row>

          <Table.Row>
            <Table.Colspan colspan={6}>
              <ListTableButtonBox>
                <ListTableCreateButton
                  onClick={() => changeLink("registration")}
                >
                  {button}
                </ListTableCreateButton>
              </ListTableButtonBox>
            </Table.Colspan>
          </Table.Row>

          <Table.Row>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Loading {...status} />
        {isSuccess && <ListItem settlementData={settlement.success.data} />}
      </Table>
    </>
  );
};

export default Result;
