import styled from "styled-components";

export const FileFormBox = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;

  width: calc(100% - 30px);
  max-width: ${(props) => props.theme.pxToRem(480)};
  height: 100%;
  min-height: ${(props) => props.theme.pxToRem(37)};

  background: ${(props) => props.theme.colors.gray5};
  padding: ${(props) => props.theme.pxToRem(12)};
  margin-bottom: ${(props) => props.theme.pxToRem(8)};

  font-size: ${(props) => props.theme.pxToRem(12)};

  cursor: pointer;

  ${(props) => props.theme.medias.mobile} {
    width: 100%;
  }
`;

export const FileFormBoxTitle = styled.h2`
  border-bottom: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.black};
`;

export const FileFormBoxSize = styled.p`
  color: ${(props) => props.theme.colors.gray6};
`;

export const FileCancelButton = styled.button`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(0, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileError = styled.p`
  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(5)};

  color: ${(props) => props.theme.colors.error};

  ${(props) => props.theme.medias.mobile} {
    font-size: ${(props) => props.theme.pxToRem(10)};
  }
`;
