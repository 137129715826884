import { Button } from "@/styles/common";
import styled from "styled-components";

export const ContentContainer = styled.table`
  width: 100%;

  tbody > tr:first-child {
    > td {
      border-top: ${(props) => props.theme.line} solid
        ${(props) => props.theme.colors.line};
    }

    > td:first-child {
      border-top-left-radius: ${(props) => props.theme.radius.normal};
    }

    /* top-right border-radius */
    > td:last-child {
      border-top-right-radius: ${(props) => props.theme.radius.normal};
    }
  }
`;

export const ContentCheckButton = styled(Button)`
  width: auto;
  padding: ${(props) => `${props.theme.pxToRem(6)} ${props.theme.pxToRem(13)}`};
  margin-left: ${(props) => props.theme.pxToRem(8)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};

  font-size: ${(props) => props.theme.pxToRem(12)};
  font-weight: 500;
`;
