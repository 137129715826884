import Profile from "@/components/artist/profile";
import { ProfileContainer } from "./Style";
import Loading from "@/components/common/loading";
import { useArtistProfile } from "@/hooks/api/profile";

const ProfilePage = () => {
  const { profile, isSuccess, ...status } = useArtistProfile();

  return (
    <ProfileContainer>
      <Loading status={status} />
      {isSuccess && <Profile userData={profile.success} />}
    </ProfileContainer>
  );
};

export default ProfilePage;
