import {
  ModalButton,
  ModalContainer,
  ModalDesc,
  ModalInnerContainer,
  ModalTitle,
} from "./Style";
import useScrollDisable from "@/hooks/utils/useSrollDisable";

const Title = ({ children }) => {
  return <ModalTitle>{children}</ModalTitle>;
};

const Desc = ({ children }) => {
  return <ModalDesc>{children}</ModalDesc>;
};

const Button = ModalButton;

const Modal = ({ isShow, isFull, children }) => {
  useScrollDisable(isShow);

  return (
    <ModalContainer isShow={isShow}>
      <ModalInnerContainer isFull={isFull}>{children}</ModalInnerContainer>
    </ModalContainer>
  );
};

Modal.Title = Title;
Modal.Desc = Desc;
Modal.Button = Button;

export default Modal;
