import { Button } from "@/styles/common";
import styled from "styled-components";

export const ListTableButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

export const ListTableCreateButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(100)};
  padding: ${(props) => props.theme.pxToRem(5)} 0;

  font-size: ${(props) => props.theme.pxToRem(12)};
`;
