import { Button } from "@/styles/common";
import styled from "styled-components";

export const ModalButtonBox = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(28)};
`;

export const ModalCommonButton = styled(Button)`
  width: ${(props) => `calc(50% - ${props.theme.pxToRem(6)})`};

  color: ${(props) => props.theme.colors.line2};
  border-color: ${(props) => props.theme.colors.line2};
  background: ${(props) => props.theme.colors.white};
`;

export const ModalConfirmButton = styled(Button)`
  width: ${(props) => `calc(50% - ${props.theme.pxToRem(6)})`};
`;

export const UnsettlementInfoText = styled.p`
  margin-top: 5px;

  font-size: ${(props) => props.theme.pxToRem(12)};
  color: ${(props) => props.theme.colors.gray};
`;

export const UnsettlementTooltipBox = styled.div`
  display: flex;
`;

export const UnsettlementTooltip = styled.div`
  position: relative;

  ${(props) => props.theme.sort.center.width};
  ${(props) => props.theme.sort.center.height};

  width: ${(props) => props.theme.pxToRem(20)};
  height: ${(props) => props.theme.pxToRem(20)};

  background: lightgray;
  border-radius: 50%;

  color: white;
  font-weight: 400;

  cursor: pointer;

  margin-left: 10px;
`;
