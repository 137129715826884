import { Button } from "@/styles/common";
import styled from "styled-components";

export const PrimaryButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(140)};
  padding: ${(props) => props.theme.pxToRem(5)} 0;
  margin-right: ${(props) => props.theme.pxToRem(6)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.bolder};
  border-radius: ${(props) => props.theme.radius.normal};
  background: ${(props) => props.theme.colors.white};

  color: ${(props) => props.theme.colors.bolder};
  text-align: center;
`;
