import { CheckboxContainer, CheckboxForm, CheckboxTitle } from "./Style";

const Form = ({ option }) => {
  return <CheckboxForm type="checkbox" {...option} />;
};

const Title = ({ children }) => {
  return <CheckboxTitle>{children}</CheckboxTitle>;
};

const Checkbox = ({ children }) => {
  return <CheckboxContainer>{children}</CheckboxContainer>;
};

Checkbox.Title = Title;
Checkbox.Form = Form;

export default Checkbox;
