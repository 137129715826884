import Table from "@/components/common/table";
import {
  SettingTableBox,
  SettingTableButton,
  SettingTableDeleteButton,
} from "./Style";
import Checkbox from "@/components/common/form/checkbox";
import useCheckbox from "@/hooks/utils/useCheckbox";
import { admin } from "@/text";
import { SubTitle } from "@/styles/common";
import { useNavigate } from "react-router-dom";
import { useAdmin, useDeleteAdminMutation } from "@/hooks/api/admin";
import SettingResult from "./item";
import useUrl from "@/hooks/utils/useUrl";

const {
  setting: {
    title,
    table: {
      sort: { unit },
      button: { del, create },
      header,
    },
    alert,
  },
} = admin;

const Result = () => {
  const { query } = useUrl();
  const { admin, isSuccess, ...status } = useAdmin(query);

  const { checkedList, updateAll, updateChecked } = useCheckbox({
    initialState: false,
    length: admin ? admin.success.data.adminList.length : 0,
  });
  const adminDeleteMutation = useDeleteAdminMutation(() => {
    window.alert(alert.success);
  });

  const navigate = useNavigate();
  const total = admin ? admin.success.data.totalItem : 0;

  const changeLink = () => {
    navigate("create");
  };

  const checkDelete = () => {
    const checked = admin.success.data.adminList
      .filter((_, idx) => checkedList[idx])
      .map(({ id }) => id);

    if (checked.length !== 0) {
      const result = window.confirm(alert.del);

      if (result) {
        adminDeleteMutation({ id: checked });
      }
    }
  };

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={5}
              length={total}
              unit={unit}
              isLimit={false}
            />
          </Table.Row>

          <Table.Row>
            <Table.Colspan colspan={6}>
              <SettingTableBox>
                <SettingTableDeleteButton onClick={checkDelete}>
                  <img src={del.img} alt="delete" />
                  {del.text}
                </SettingTableDeleteButton>

                <SettingTableButton onClick={changeLink}>
                  {create}
                </SettingTableButton>
              </SettingTableBox>
            </Table.Colspan>
          </Table.Row>
          <Table.Row>
            <Table.Header width={"4%"}>
              <Checkbox>
                <Checkbox.Form option={{ onChange: updateAll }} />
              </Checkbox>
            </Table.Header>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Loading {...status} />
        {isSuccess && (
          <SettingResult
            admin={admin.success.data}
            checkedList={checkedList}
            updateChecked={updateChecked}
          />
        )}
      </Table>
    </>
  );
};

export default Result;
