import { Button } from "@/styles/common";
import styled from "styled-components";

export const IssuanceSubmitButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.pxToRem(16)};
`;

export const IssuanceBackButton = styled(IssuanceSubmitButton)`
  color: ${(props) => props.theme.colors.black};
  border-color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
`;

export const IssuanceText = styled.p`
  text-align: center;

  font-size: ${(props) => props.theme.pxToRem(12)};
  line-height: ${(props) => props.theme.pxToRem(17)};
`;

export const IssuanceEmail = styled.p`
  margin-top: ${(props) => props.theme.pxToRem(16)};

  text-align: center;

  font-size: ${(props) => props.theme.pxToRem(14)};
  font-weight: 500;

  color: ${(props) => props.theme.colors.bolder};
`;
