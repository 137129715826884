import Excel from "@/components/common/excel";
import Table from "@/components/common/table";
import usePagination from "@/hooks/utils/usePagination";
import usePopup from "@/hooks/utils/usePopup";
import { ListTableButton, ListTableItem } from "./Style";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { common } from "@text";

const { blank } = common;

const ListItem = ({ settlementData }) => {
  const { pageRender } = usePagination({
    totalPage: settlementData.totalPage,
    pagePerBlock: 10,
  });

  const { handleOpenPopup } = usePopup({
    submit: () => {
      // navigate("/admin/settlement/list");
    },
    width: 1072,
    height: 929,
  });

  return (
    <>
      <Table.Body>
        {settlementData.settlementReport.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan={6}>{blank}</Table.Colspan>
          </Table.Row>
        )}
        {settlementData.settlementReport.map(
          (
            {
              requestCode,
              date,
              artistNameEng,
              amount,
              period: { start, end },
              type,
              reportFile,
            },
            idx
          ) => (
            <Table.Row key={requestCode}>
              <ListTableItem>{sliceDate(date)}</ListTableItem>
              <ListTableItem>{artistNameEng}</ListTableItem>
              <ListTableItem>
                {sliceDate(start)} ~ {sliceDate(end)}
              </ListTableItem>
              <ListTableItem>{numberWithCommas(amount)}</ListTableItem>
              <ListTableItem>
                <ListTableButton
                  onClick={() =>
                    handleOpenPopup(`/resultPopup?reportCode=${requestCode}`)
                  }
                >
                  {type}
                </ListTableButton>
              </ListTableItem>
              <ListTableItem>
                <Excel link={reportFile} />
              </ListTableItem>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default ListItem;
