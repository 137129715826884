import Table from "@/components/common/table";
import { admin } from "@/text";
import { SubTitle } from "@/styles/common";
import { useSettlementRequest } from "@/hooks/api/settlement";
import useUrl from "@/hooks/utils/useUrl";
import RequestItem from "./item";

const {
  settlement: {
    request: {
      title,
      table: {
        sort: { unit, list },
        header,
      },
    },
  },
} = admin;

const Result = () => {
  const { query } = useUrl();
  const { settlementRequest, isSuccess, ...status } =
    useSettlementRequest(query);

  const total = settlementRequest?.success.data.totalItem ?? 0;

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={6}
              length={total}
              unit={unit}
              sortWidth={"150"}
              sortList={list}
            />
          </Table.Row>

          <Table.Row>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Loading {...status} />
        {isSuccess && (
          <RequestItem requestData={settlementRequest.success.data} />
        )}
      </Table>
    </>
  );
};

export default Result;
