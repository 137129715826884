import {
  RadioContainer,
  RadioError,
  RadioItem,
  RadioLabel,
  RadioText,
  RadioTitle,
} from "./Style";

const Title = ({ children, isFull }) => {
  return <RadioTitle isFull={isFull}>{children}</RadioTitle>;
};

const Label = ({ children }) => {
  return <RadioLabel>{children}</RadioLabel>;
};

const Form = ({ value, option }) => {
  return <RadioItem type="radio" value={value} {...option} />;
};

const Text = ({ children }) => {
  return <RadioText>{children}</RadioText>;
};

const Item = ({ value, option, children }) => {
  return (
    <RadioLabel>
      <RadioItem type="radio" value={value} {...option} />
      <RadioText>{children}</RadioText>
    </RadioLabel>
  );
};

const Error = ({ children }) => {
  return <RadioError>{children}</RadioError>;
};

const Radio = ({ children }) => {
  return <RadioContainer>{children}</RadioContainer>;
};

Radio.Title = Title;
Radio.Item = Item;
Radio.Error = Error;
Radio.Label = Label;
Radio.Form = Form;
Radio.Text = Text;

export default Radio;
