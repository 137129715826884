import { Button, ContentWrapper } from "@/styles/common";
import styled from "styled-components";

export const RegistrationContainer = styled(ContentWrapper)``;

export const RegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RegistrationButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(120)};

  padding: ${(props) => props.theme.pxToRem(10)} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;

export const RegistrationButtonBox = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(28)};
`;

export const RegistrationSubmitButton = styled(Button)`
  width: ${(props) => props.width ?? props.theme.pxToRem(192)};
  padding: ${(props) => props.theme.pxToRem(17)} 0;
`;

export const RegistrationCancelButton = styled(RegistrationSubmitButton)`
  border-color: ${(props) => props.theme.colors.line};
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.white};
`;
