import Blank from "@/components/common/blank";
import { DeniedContainer } from "./Style";

const Denied = () => {
  return (
    <DeniedContainer>
      <Blank>이 메뉴에 권한이 없습니다.</Blank>
    </DeniedContainer>
  );
};

export default Denied;
