import { useMutation } from "react-query";
import { issuance, login, logout, password } from "@/api/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "@/text";

const {
  login: { alert },
} = auth;

export const useLoginMutation = () => {
  const navigate = useNavigate();

  const loginMutation = useMutation(login, {
    onSuccess: ({ success }) => {
      const message = success.message;
      if (message !== "SUCCESS") {
        let type = "";
        if (message === "FIRST LOGIN") {
          type = "new";
        } else {
          type = "temp";
        }
        navigate(`/artist/password?type=${type}`);
      } else {
        navigate(`/${success.data}`);
      }
    },
    onError: () => {
      window.alert(alert);
    },
  });

  const submit = ({ id, password, type }) => {
    loginMutation.mutate({
      id: id,
      pw: password,
      type: type,
    });
  };

  return submit;
};

export const useLogoutMutation = (path) => {
  const navigate = useNavigate();

  const logoutMutation = useMutation(logout, {
    onSuccess: () => {
      navigate(`/${path}/login`);
    },
    onError: () => {
      window.alert("로그아웃을 하지 못했습니다.");
    },
  });

  const submit = () => {
    logoutMutation.mutate();
  };

  return submit;
};

export const usePasswordMutation = (successCallback, failCallback) => {
  const passwordMutation = useMutation(password, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ password, currPassword }) => {
    passwordMutation.mutate({
      newPassword: password,
      currentPassword: currPassword,
    });
  };

  return submit;
};

export const useIssuanceMutation = (successCallback, failCallback) => {
  const issuanceMutation = useMutation(issuance, {
    onSuccess: successCallback,
    onError: failCallback,
  });

  const submit = ({ id, email }) => {
    issuanceMutation.mutate({
      id,
      email,
    });
  };

  return [submit, issuanceMutation.isLoading];
};
