import { useNavigate } from "react-router-dom";
import Layout from ".";
import useUrl from "@/hooks/utils/useUrl";
import Loading from "../common/loading";
import { useEffect } from "react";
import { useProfile } from "@/hooks/api/profile";

const LayoutProvider = () => {
  const navigate = useNavigate();
  const { path } = useUrl();

  const { profile, isLoading, isSuccess, isError } = useProfile(
    path[0]
  );
  const root = path[0];

  useEffect(() => {
    if (isError) {
      navigate(`/${root}/login`);
      alert("권한이 없습니다.");
    }
  }, [isError, navigate, root]);

  if (isLoading) return <Loading />;
  return isSuccess && <Layout userData={profile.success.data} />;
};

export default LayoutProvider;
