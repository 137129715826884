import useExternalSelection from "./useExternalSelection";
import useToggle from "./useToggle";

const useExternalToggle = ({ defaultValue, target, exception }) => {
  const [toggle, toggleChange, setToggle] = useToggle(defaultValue);

  const handleOutsideClose = (e) => {
    if (e.target.classList.contains(exception)) return;
    if (!target.current.contains(e.target)) setToggle(false);
  };

  useExternalSelection(handleOutsideClose);

  return [toggle, toggleChange, setToggle];
};

export default useExternalToggle;
