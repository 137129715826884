import { ContentWrapper } from "@/styles/common";
import styled from "styled-components";

export const DashboardContainer = styled(ContentWrapper)``;

export const DashboardContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  width: 100%;
  padding: 60px 40px;

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};
  line-height: 18.82px;
`;

export const DashboardContentSplitBox = styled.div`
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};
  line-height: 18.82px;
`;

export const DashboardContentSplitBoxTitle = styled.p`
  display: inline-block;
  margin: 20px 16px;

  font-weight: 700;
  line-height: 17px;
  color: ${(props) => props.theme.colors.bolder};

  ${(props) =>
    props.isLink &&
    `
    border-bottom: ${props.theme.line} solid ${props.theme.colors.bolder};
  `}
`;

export const DashboardContentSplitBoxContent = styled.div`
  padding: 14px 16px;
  border-top: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
`;

export const DashboardPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);

  width: 480px;

  padding: 40px;
  background-color: white;

  border-radius: 3px;
  border: 1px solid var(--line-color, #d9dee3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

  h5 {
    font-size: ${(props) => props.theme.pxToRem(22)};
    color: ${(props) => props.theme.colors.primary};

    font-weight: 500;
    line-height: normal;
    text-align: center;

    margin: 0;
  }

  p {
    font-size: ${(props) => props.theme.pxToRem(14)};
    color: ${(props) => props.theme.colors.black};
    text-align: center;
    line-height: normal;

    margin: 8px 0 30px;
  }
`;

export const DashboardCancelButton = styled.button`
  width: 100%;

  padding: 17px 0;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;

  font-size: ${(props) => props.theme.pxToRem(16)};
  font-weight: 500;
`;
