import styled from "styled-components";

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CheckboxForm = styled.input`
  -webkit-appearance: auto;

  width: ${(props) => props.theme.pxToRem(14)};
  height: ${(props) => props.theme.pxToRem(14)};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.black};

  color: ${(props) => props.theme.colors.white};
  accent-color: ${(props) => props.theme.colors.bolder};
`;

export const CheckboxTitle = styled.span``;
