import Update from "@/components/admin/setting/update";
import Loading from "@/components/common/loading";
import { useAdminProfile } from "@/hooks/api/profile";
import useUrl from "@/hooks/utils/useUrl";

const UpdatePage = () => {
  const { params } = useUrl();
  const { profile, isSuccess, ...status } = useAdminProfile(params);

  return (
    <>
      <Loading {...status} />
      {isSuccess && <Update admin={profile.success.data} />}
    </>
  );
};

export default UpdatePage;
