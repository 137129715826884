import styled from "styled-components";

export const ContentContainer = styled.section`
  width: 100%;
  min-height: 100vh;

  padding-left: ${(props) => props.theme.pxToRem(260)};
  background-color: ${(props) => props.theme.colors.gray3};

  ${(props) => props.theme.medias.mobile} {
    padding-left: 0;
    width: 100vw;
  }
`;
