import {
  createAdmin,
  deleteAdmin,
  getAdmin,
  getAdminDuplication,
} from "@/api/admin";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useAdmin = ({ type = "id", data = "" }) => {
  const {
    data: admin,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(["admin", type, data], () => getAdmin({ type, data }));

  return {
    admin,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useAdminDuplication = (id, enabled) => {
  const {
    data: adminDuplication,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["adminDuplication", id], () => getAdminDuplication({ id }), {
    enabled,
  });
  return {
    adminDuplication,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useCreateAdminMutation = (successCallback) => {
  const queryClient = useQueryClient();

  const createAdminMutation = useMutation(createAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries("admin");
      successCallback();
    },
  });

  const submit = (data) => {
    createAdminMutation.mutate(data);
  };

  return submit;
};

export const useDeleteAdminMutation = (successCallback) => {
  const queryClient = useQueryClient();

  const deleteAdminMutation = useMutation(deleteAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries("admin");
      successCallback();
    },
  });

  const submit = (data) => {
    deleteAdminMutation.mutate(data);
  };

  return submit;
};
