import { useLoginMutation } from "@/hooks/api/auth";
import { LoginDesc, LoginPasswordLine, LoginTitleLogo } from "./Style";
import Auth from "@/components/root/auth";
import useLoginForm from "@/hooks/utils/useLoginForm";
import { Button } from "@/styles/common";
import { auth } from "@text";
import { ROLE } from "@/utils/constants";

const {
  login: {
    logo,
    desc,
    artist,
    inputList,
    button,
    find: { title, link },
  },
} = auth;

const Login = () => {
  const { register, handleSubmit, errors } = useLoginForm(ROLE.artist);
  const submit = useLoginMutation();

  return (
    <Auth>
      <Auth.Inner>
        <Auth.Title>
          <LoginTitleLogo alt="logo" src={logo} />
        </Auth.Title>

        <Auth.Desc>
          <LoginDesc>
            {desc[0]}
            {artist}
            {desc[1]}
          </LoginDesc>
        </Auth.Desc>

        <Auth.Form
          submit={handleSubmit(submit)}
          inputList={inputList}
          register={register}
          errors={errors}
        >
          <Button type="submit">{button.submit}</Button>
        </Auth.Form>

        <Auth.Option>
          <LoginPasswordLine to={link}>{title}</LoginPasswordLine>
        </Auth.Option>
      </Auth.Inner>
      <Auth.Info />
    </Auth>
  );
};

export default Login;
