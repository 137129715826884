import Search from "@/components/common/search";
import { ProductContainer } from "./Style";
import Result from "@/components/admin/product";
import useUrl from "@/hooks/utils/useUrl";
import { admin } from "@/text";
import Loading from "@/components/common/loading";
import { useProductSettlement } from "@/hooks/api/product";

const {
  product: {
    search: {
      input: { title: inputTitle, typeList },
      radio: { title: radioTitle, radioList },
    },
  },
} = admin;

const Product = () => {
  const { changeQuery, query } = useUrl();

  const { productSettlement, isSuccess, ...status } =
    useProductSettlement(query);

  return (
    <ProductContainer>
      <Search submit={changeQuery}>
        <Search.Input title={inputTitle} typeList={typeList} width={"130"} />
        <Search.Radio
          title={radioTitle}
          radioList={radioList}
          name={"productSettlementSetting"}
        />
      </Search>

      <Loading {...status} />
      {isSuccess && <Result productData={productSettlement.success.data} />}
    </ProductContainer>
  );
};

export default Product;
