import { POST, PUT } from "../info";

// 로그인
export const login = async (body) => {
  return await POST("/auth/login", body);
};
// 임시 비밀번호 발급
export const issuance = async (body) => await POST("/auth/password", body);
// 비밀번호 변경
export const password = async (body) => await PUT("/auth/password", body);
// 로그아웃
export const logout = async () => await PUT("/auth/logout");
