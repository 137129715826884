import styled from "styled-components";

export const ContentContainer = styled.tr`
  height: 100%;
  margin-top: ${(props) => props.theme.pxToRem(40)};

  font-size: ${(props) => props.theme.pxToRem(13)};
`;

export const ContentTitle = styled.td`
  width: 16%;

  padding: ${(props) =>
    `${props.theme.pxToRem(14)} ${props.theme.pxToRem(16)}`};

  font-weight: 500;

  background: ${(props) => props.theme.colors.gray3};

  vertical-align: top;
  text-align: left;

  :after {
    content: " *";

    color: ${(props) => props.theme.colors.bolder};
    visibility: ${(props) => (props.isRequired ? "visible" : "hidden")};
  }

  ${(props) => props.theme.medias.mobile} {
    display: none;
  }
`;

export const ContentArea = styled.td`
  width: 84%;
  max-width: ${(props) => props.theme.pxToRem(710)};

  padding: ${(props) => props.theme.pxToRem(12)};
  white-space: pre-wrap;

  ${(props) => props.theme.medias.mobile} {
    width: 100%;
    border-left: ${(props) => props.theme.pxToRem(1)} solid
      ${(props) => props.theme.colors.line};
  }
`;

export const ContentInfo = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(8)};
`;

export const ContentDesc = styled.div`
  white-space: pre-wrap;
`;

export const ContentTable = styled.table`
  ${(props) => props.theme.medias.mobile} {
    width: 100%;
  }
`;

export const ContentTableHead = styled.td`
  width: ${(props) => props.theme.pxToRem(120)};
  padding: ${(props) =>
    `${props.theme.pxToRem(12)} ${props.theme.pxToRem(17)}`};
  background: ${(props) => props.theme.colors.gray3};

  font-size: ${(props) => props.theme.pxToRem(13)};
  font-weight: 500;

  ${(props) => props.theme.medias.mobile} {
    display: none;
  }
`;

export const ContentTableBody = styled.td`
  width: ${(props) => props.theme.pxToRem(360)};
  height: 100%;

  padding: ${(props) => props.theme.pxToRem(5)};

  font-size: ${(props) => props.theme.pxToRem(13)};

  ${(props) => props.theme.medias.mobile} {
    width: 100%;
    padding: ${(props) => props.theme.pxToRem(5)} 0;
    border: none !important;
  }
`;

export const ContentTableInputBox = styled.div`
  width: ${(props) => props.theme.pxToRem(240)};

  ${(props) => props.theme.medias.mobile} {
    width: 100%;
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ContentIcon = styled.img``;

export const ContentMobileTitle = styled.h2`
  display: none;

  font-weight: 500;

  :after {
    content: " *";

    color: ${(props) => props.theme.colors.bolder};
    visibility: ${(props) => (props.isRequired ? "visible" : "hidden")};
  }

  ${(props) => props.theme.medias.mobile} {
    display: block;
    width: 100%;

    margin-bottom: ${(props) => props.theme.pxToRem(5)};
  }
`;

export const ContentError = styled.p`
  width: 100%;
  margin-top: ${(props) => props.theme.pxToRem(5)};

  color: ${(props) => props.theme.colors.error};

  ${(props) => props.theme.medias.mobile} {
    font-size: ${(props) => props.theme.pxToRem(10)};
  }
`;
