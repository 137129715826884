import Input from "@/components/common/form/input";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentDesc,
  ContentInfo,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";
import { SingleInputBox } from "./Style";
import Info from "../../info";
import { createGrave } from "@/utils/function";

const SingleInput = ({
  config: { title, name, info, required, errorMessage, option, pattern },
  children,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { isEdit } = watch();
  const desc = watch(name);

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>
      <ContentArea>
        <Input>
          <SingleInputBox>
            <ContentMobileTitle isRequired={required}>
              {title}
            </ContentMobileTitle>
            {isEdit ? (
              <Input.InputForm
                small
                isError={error}
                option={{
                  ...register(name, {
                    required,
                    pattern,
                  }),
                  ...option,
                }}
              />
            ) : (
              <ContentDesc>{desc}</ContentDesc>
            )}
          </SingleInputBox>
          {children}
          {isEdit && info && (
            <ContentInfo>
              <Info>{createGrave(info)}</Info>
            </ContentInfo>
          )}

          {error && <Input.Error>{error}</Input.Error>}
        </Input>
      </ContentArea>
    </ContentContainer>
  );
};

export default SingleInput;
