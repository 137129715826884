import Auth from "@/components/root/auth";
import { useForm } from "react-hook-form";
import { auth } from "@text";
import {
  IssuanceBackButton,
  IssuanceEmail,
  IssuanceSubmitButton,
  IssuanceText,
} from "./Style";
import { InfoBox } from "@/styles/common";
import Modal from "@/components/common/modal";
import useToggle from "@/hooks/utils/useToggle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useIssuanceMutation } from "@/hooks/api/auth";
import Loading from "@/components/common/loading";

const {
  issuance: {
    title,
    desc,
    inputList,
    button: { submitButton, backButton },
    info: { infoDesc, infoEmail },
    modal,
  },
} = auth;

const defaultValues = {
  id: "",
  email: "",
};

const useIssuanceForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  return {
    register,
    handleSubmit,
    watch,
    errors,
  };
};

const useModal = () => {
  const navigate = useNavigate();
  const [state, setState] = useState("success");
  const [isShow, updateModal] = useToggle(false);

  const { modalTitle, modalDesc, modalButton } = modal[state];
  const modalEvent = {
    success: () => {
      navigate("/artist/login");
    },
    fail: () => {
      updateModal();
    },
  };

  const success = () => {
    setState("success");
    updateModal();
  };

  const fail = () => {
    setState("fail");
    updateModal();
  };

  return {
    isShow,
    modal: {
      modalTitle,
      modalDesc,
      modalButton,
      modalEvent: modalEvent[state],
    },
    success,
    fail,
  };
};

const Issuance = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, errors } = useIssuanceForm();
  const {
    isShow,
    modal: { modalTitle, modalDesc, modalButton, modalEvent },
    success,
    fail,
  } = useModal();
  const [issuanceMutation, isLoading] = useIssuanceMutation(success, fail);

  const blank = (e) => {
    e.preventDefault();
  };

  const back = () => {
    navigate("/artist/login");
  };

  return (
    <>
      <Auth>
        <Auth.Inner>
          <Auth.Title>{title}</Auth.Title>
          <Auth.DescBox>
            {desc.map((desc) => {
              return <Auth.Desc key={desc}>{desc}</Auth.Desc>;
            })}
          </Auth.DescBox>

          <Auth.Form
            submit={isLoading ? blank : handleSubmit(issuanceMutation)}
            inputList={inputList}
            register={register}
            errors={errors}
          >
            <IssuanceSubmitButton type="submit">
              {isLoading ? <Loading isLoading /> : submitButton}
            </IssuanceSubmitButton>
            <IssuanceBackButton type="button" onClick={back}>
              {backButton}
            </IssuanceBackButton>

            <InfoBox>
              {infoDesc.map((data) => (
                <IssuanceText key={data}>{data}</IssuanceText>
              ))}

              <IssuanceEmail>{infoEmail}</IssuanceEmail>
            </InfoBox>
          </Auth.Form>

          <Auth.Info />
        </Auth.Inner>
      </Auth>

      <Modal isShow={isShow}>
        <Modal.Title>{modalTitle}</Modal.Title>
        {modalDesc.map((data) => (
          <Modal.Desc key={data}>{data}</Modal.Desc>
        ))}

        <Modal.Button onClick={modalEvent}>{modalButton}</Modal.Button>
      </Modal>
    </>
  );
};

export default Issuance;
