import Auth from "@/components/root/auth";
import { Button } from "@/styles/common";
import { useForm } from "react-hook-form";
import { auth } from "@text";
import { useState } from "react";
import Modal from "@/components/common/modal";
import useToggle from "@/hooks/utils/useToggle";
import { useNavigate } from "react-router-dom";
import useUrl from "@/hooks/utils/useUrl";
import usePattern from "@/hooks/utils/usePattern";
import { usePasswordMutation } from "@/hooks/api/auth";

const {
  password: {
    title,
    desc,
    inputList,
    button: { submitButton },
    modal,
  },
} = auth;

const usePasswordForm = () => {
  const defaultValues = inputList.map((name) => {
    return name;
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });
  const { password } = watch();
  const pattern = usePattern(password);

  return {
    register,
    handleSubmit,
    pattern,
    errors,
  };
};

const useModal = () => {
  const navigate = useNavigate();
  const [state, setState] = useState("fail");
  const [isShow, updateModal] = useToggle(false);

  const { modalTitle, modalDesc, modalButton } = modal[state];
  const modalEvent = {
    success: () => {
      navigate("/artist");
    },
    fail: () => {
      updateModal();
    },
  };

  const success = () => {
    setState("success");
    updateModal();
  };

  const fail = () => {
    setState("fail");
    updateModal();
  };

  return {
    isShow,
    modal: {
      modalTitle,
      modalDesc,
      modalButton,
      modalEvent: modalEvent[state],
    },
    success,
    fail,
  };
};

const Password = () => {
  const { register, handleSubmit, pattern, errors } = usePasswordForm();
  const {
    isShow,
    modal: { modalTitle, modalDesc, modalButton, modalEvent },
    success,
    fail,
  } = useModal();
  const passwordMutation = usePasswordMutation(success, fail);
  const { query } = useUrl();
  const type = query["type"] ?? "new";

  return (
    <>
      <Auth>
        <Auth.Inner>
          <Auth.Title>{title}</Auth.Title>
          <Auth.DescBox>
            {desc[type].map((desc) => {
              return <Auth.Desc key={desc}>{desc}</Auth.Desc>;
            })}
          </Auth.DescBox>

          <Auth.Form
            submit={handleSubmit(passwordMutation)}
            inputList={inputList}
            register={register}
            errors={errors}
            pattern={pattern}
          >
            <Button type="submit">{submitButton}</Button>
          </Auth.Form>
        </Auth.Inner>
        <Auth.Info />
      </Auth>

      <Modal isShow={isShow}>
        <Modal.Title>{modalTitle}</Modal.Title>
        {modalDesc.map((desc) => (
          <Modal.Desc key={desc}>{desc}</Modal.Desc>
        ))}
        <Modal.Button onClick={modalEvent}>{modalButton}</Modal.Button>
      </Modal>
    </>
  );
};

export default Password;
