import SalesResult from "@/components/artist/sales/result";
import Search from "@/components/common/search";
import { SalesContainer } from "./Style";
import { artist } from "@/text";
import useUrl from "@/hooks/utils/useUrl";
import { useSellInfo } from "@/hooks/api/report";
import Loading from "@/components/common/loading";
import dayjs from "dayjs";

const {
  sales: { search },
  common: {
    search: { calendar, radio },
  },
} = artist;

const Sales = () => {
  const { changeQuery, query } = useUrl();
  const { sellInfo, isSuccess, ...status } = useSellInfo(query);

  const checkEndDate = (data) => {
    const current = dayjs();

    const lastDayOfLastMonth = current.subtract(1, "month").endOf("month");

    if (lastDayOfLastMonth.isBefore(data)) {
      alert("지난달 이후 날짜는 선택할 수 없습니다.");
      return true;
    }
  };

  return (
    <SalesContainer>
      <Search submit={changeQuery}>
        <Search.Calendar
          title={calendar}
          checkEndDate={checkEndDate}
          checkStartDate={checkEndDate}
          isSale
          isMonth
        />
        <Search.Radio title={radio} radioList={search} name="shoppingMall" />
      </Search>

      <Loading {...status} />
      {isSuccess && <SalesResult sellData={sellInfo.success.data} />}
    </SalesContainer>
  );
};

export default Sales;
