import {
  HeaderContainer,
  HeaderMenu,
  HeaderMenuButton,
  HeaderTitle,
} from "./Style";
import { header } from "@text";
import HeaderProfile from "./profile";
import { useLocation } from "react-router-dom";
import { AiOutlineMenu } from "@react-icons/all-files/ai/AiOutlineMenu";

const useOption = () => {
  const location = useLocation();
  let option = {};

  Object.keys(header.option).forEach((key) => {
    if (location.pathname.includes(key)) {
      option = header.option[key];
    }
  });

  return {
    option,
  };
};

const Header = ({ toggleMenu, profile }) => {
  const { option } = useOption();

  return (
    <HeaderContainer>
      <HeaderTitle>{option.title}</HeaderTitle>

      <HeaderMenu>
        <HeaderProfile
          isProfile={option.isProfile}
          isAdmin={option.isAdmin}
          {...profile}
        />
      </HeaderMenu>

      <HeaderMenuButton onClick={toggleMenu}>
        <AiOutlineMenu />
      </HeaderMenuButton>
    </HeaderContainer>
  );
};

export default Header;
