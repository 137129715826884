import { DELETE, GET, POST } from "../info";

export const getArtist = async (params) => await GET("/artist", { params });

export const getArtistDuplication = async (params) =>
  await GET("/artist/duplication", { params });

export const getArtistIdList = async () => await GET("/artist/id");

export const createArtist = async (body) => await POST("/artist", body);

export const deleteArtist = async (body) => await DELETE("/artist", body);
