import { useForm } from "react-hook-form";

const defaultValues = {
  id: "",
  password: "",
};

const useLoginForm = (type) => {
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm({ defaultValues: { ...defaultValues, type } });

  return {
    register,
    handleSubmit,
    watch,
    errors,
  };
};

export default useLoginForm;
