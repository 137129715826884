import Result from "@/components/artist/settlement/result";
import Unsettled from "@/components/artist/settlement/unsettled";
import { SettlementContainer } from "./Style";
import { useUnsettlementInfo } from "@/hooks/api/report";
import Loading from "@/components/common/loading";

const Settlement = () => {
  const { unsettlementInfo, isSuccess, isLoading } = useUnsettlementInfo();

  return (
    <SettlementContainer>
      <Loading isLoading={isLoading} />
      {isSuccess && (
        <>
          <Unsettled unsettlementInfo={unsettlementInfo.success.data} />
          <Result />
        </>
      )}
    </SettlementContainer>
  );
};

export default Settlement;
