import Table from "@/components/common/table";
import {
  ArtistTableBox,
  ArtistTableButton,
  ArtistTableDeleteButton,
} from "./Style";
import Checkbox from "@/components/common/form/checkbox";
import useCheckbox from "@/hooks/utils/useCheckbox";
import { admin } from "@/text";
import { SubTitle } from "@/styles/common";
import useUrl from "@/hooks/utils/useUrl";
import ArtistResult from "./item";
import { useArtist, useDeleteArtistMutation } from "@/hooks/api/artist";

const {
  artist: {
    list: {
      title,
      table: {
        sort: { unit, list },
        button: { del, create, fail },
        header,
      },
      alert,
    },
  },
} = admin;

const Result = () => {
  const { changeLink, query } = useUrl();
  const { artist, isSuccess, ...status } = useArtist(query);
  const { checkedList, updateAll, updateChecked } = useCheckbox({
    initialState: false,
    length: artist ? artist.success.data.artistList.length : 0,
  });

  const successCallback = () => {
    window.alert(alert.success);
  };

  const failCallback = () => {
    window.alert(alert.fail);
  };

  const artistDeleteMutation = useDeleteArtistMutation(
    successCallback,
    failCallback
  );

  const deleteArtist = () => {
    const filteredList = artist.success.data.artistList
      .filter((_, idx) => checkedList[idx])
      .map(({ id }) => id);

    if (filteredList.length !== 0) {
      const result = window.confirm(alert.del);

      if (result) artistDeleteMutation({ id: filteredList });
    }
  };

  const totalLength = artist ? artist.success.data.totalItem : 0;

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={6}
              length={totalLength}
              unit={unit}
              sortWidth={200}
              sortList={list}
            />
          </Table.Row>

          <Table.Row>
            <Table.Colspan colspan={6}>
              <ArtistTableBox>
                <ArtistTableDeleteButton onClick={deleteArtist}>
                  <img src={del.img} alt="delete" />
                  {del.text}
                </ArtistTableDeleteButton>

                <ArtistTableButton onClick={() => changeLink("create")}>
                  {create}
                </ArtistTableButton>
              </ArtistTableBox>
            </Table.Colspan>
          </Table.Row>
          <Table.Row>
            <Table.Header width={"4%"}>
              <Checkbox>
                <Checkbox.Form option={{ onChange: updateAll }} />
              </Checkbox>
            </Table.Header>
            {header.map(({ width, text }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Loading {...status} />
        {isSuccess && (
          <ArtistResult
            artistData={artist.success.data}
            checkedList={checkedList}
            updateChecked={updateChecked}
          />
        )}
      </Table>
    </>
  );
};

export default Result;
