import styled from "styled-components";

export const BlankContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  padding: ${(props) => props.theme.pxToRem(90)} 0;

  background: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};
`;
