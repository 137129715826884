import usePattern from "@/hooks/utils/usePattern";
import { useFormContext } from "react-hook-form";
import CheckboxList from "../../inputTable/checkBoxList";
import EditInfo from "../../inputTable/editInfo";
import SingleInput from "../../inputTable/singleInput";
import { ContentCheckButton, ContentContainer } from "../Style";
import { common } from "@/text";
import { useAdminDuplication } from "@/hooks/api/admin";
import useToggle from "@/hooks/utils/useToggle";
import { useEffect } from "react";

const {
  tableForm: {
    setting: { id, name, password, passwordConfirm, permission },
  },
} = common;

const SettingInput = ({ required, readOnly }) => {
  const { watch, setValue, getValues, setError, clearErrors } =
    useFormContext();
  const [searchToggle, setSearchToggle] = useToggle(false);
  const { isSuccess, isError } = useAdminDuplication(watch("id"), searchToggle);

  const checkId = () => {
    const { id } = getValues();

    if (id) {
      setSearchToggle();
    } else {
      setError("id", { type: "required" });
    }
  };

  const currPassword = watch("password");
  const pattern = usePattern(currPassword);

  const isCheck = watch("checkId");
  const idReadOnly = readOnly[id.name] || isCheck;

  useEffect(() => {
    if (id) {
      clearErrors("id");
    }
  }, [id]);

  useEffect(() => {
    if (searchToggle)
      if (isSuccess) {
        setValue("checkId", true);
        setSearchToggle();
        window.alert("사용 가능한 아이디입니다.");
      } else if (isError) {
        setValue("checkId", false);
        setSearchToggle();
        window.alert("사용중인 아이디입니다.");
      }
  }, [isSuccess, isError, searchToggle]);

  return (
    <ContentContainer>
      <tbody>
        <EditInfo />
        <SingleInput
          config={{
            ...id,
            required: required[id.name],
            option: {
              readOnly: idReadOnly,
            },
          }}
        >
          {!readOnly[id.name] && (
            <ContentCheckButton type="button" onClick={checkId}>
              {id.checkButton}
            </ContentCheckButton>
          )}
        </SingleInput>
        <SingleInput
          config={{
            ...name,
            required: required[name.name],
          }}
        />

        <SingleInput
          config={{
            ...password,
            option: {
              type: "password",
            },
            pattern: pattern.password,
            required: required[password.name],
          }}
        />

        <SingleInput
          config={{
            ...passwordConfirm,
            option: {
              type: "password",
            },
            pattern: pattern.passwordConfirm,
            required: required[passwordConfirm.name],
          }}
        />

        <CheckboxList
          config={{
            ...permission,
            required: required[permission.name],
          }}
        />
      </tbody>
    </ContentContainer>
  );
};

export default SettingInput;
