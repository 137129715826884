import styled from "styled-components";

export const SelectBoxContainer = styled.div`
  position: relative;

  width: ${(props) =>
    props.width ? props.theme.pxToRem(props.width) : "100%"};
`;

export const SelectBoxButton = styled.button`
  position: relative;

  width: 100%;
  padding: ${(props) =>
    `${props.theme.pxToRem(5)} ${props.theme.pxToRem(10)}`};

  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};

  text-align: left;
`;

export const SelectBoxIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: ${(props) => (props.isShow ? "visible" : "hidden")};
`;

export const SelectBoxList = styled.ul`
  position: absolute;
  top: ${(props) => `calc(100% + ${props.theme.pxToRem(5)})`};
  left: 0;
  z-index: 1;

  width: 100%;
  max-height: 500px;

  overflow-y: scroll;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};

  padding: ${(props) => props.theme.pxToRem(3)};

  border-radius: 5px;
  background: white;
  border-radius: ${(props) => props.theme.radius.normal};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  text-align: left;

  cursor: pointer;
`;

export const SelectBoxItem = styled.li`
  border-radius: ${(props) => props.theme.radius.normal};

  :hover {
    background: ${(props) => props.theme.colors.gray3};
  }
`;

export const SelectBoxLabel = styled.label`
  display: block;
  width: 100%;

  cursor: pointer;

  padding: ${(props) =>
    `${props.theme.pxToRem(10)} ${props.theme.pxToRem(15)}`};
  font-size: ${(props) => props.theme.pxToRem(13)};

  ${(props) =>
    props.isActive &&
    `
    background: ${props.theme.colors.primary};
    color: ${props.theme.colors.white};
  `}
`;
