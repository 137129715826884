import styled from "styled-components";

export const ProductBox = styled.div`
  display: flex;

  padding: ${(props) => `0 ${props.theme.pxToRem(8)}`};
`;

export const ProductImage = styled.img`
  width: ${(props) => props.theme.pxToRem(36)};
  height: ${(props) => props.theme.pxToRem(36)};

  border: ${(props) => props.theme.pxToRem(1)} solid
    ${(props) => props.theme.colors.gray3};
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const ProductInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  text-align: left;

  margin-left: ${(props) => props.theme.pxToRem(7)};
`;

export const ProductTitle = styled.p`
  margin-bottom: ${(props) => props.theme.pxToRem(4)};

  font-size: ${(props) => props.theme.pxToRem(13)};
  color: ${(props) => props.theme.colors.line2};

  line-height: 18.82px;
`;

export const ProductOption = styled.p`
  font-size: ${(props) => props.theme.pxToRem(11)};
  color: ${(props) => props.theme.colors.bolder};
`;

export const ProductInputBox = styled.div`
  padding: ${(props) => `0 ${props.theme.pxToRem(14)}`};
`;

export const ProductInput = styled.input`
  width: 100%;

  padding: ${(props) =>
    `${props.theme.pxToRem(7)} ${props.theme.pxToRem(20)}}`};
  border: ${(props) => props.theme.line} solid
    ${(props) => props.theme.colors.line};
  border-radius: ${(props) => props.theme.radius.normal};

  font-size: ${(props) => props.theme.pxToRem(13)};
`;
