import Table from "@/components/common/table";
import {
  ProductTableBox,
  ProductTableButton,
  ProductSettingButton,
} from "./Style";
import { numberWithoutCommas } from "@/utils/function";
import Checkbox from "@/components/common/form/checkbox";
import { useState } from "react";
import useCheckbox from "@/hooks/utils/useCheckbox";
import ProductModal from "./modal";
import { admin } from "@/text";
import { SubTitle } from "@/styles/common";
import useToggle from "@/hooks/utils/useToggle";
import ProductResult from "./item";
import { useProductUpdateSettlementMutation } from "@/hooks/api/product";
import { useEffect } from "react";

const {
  product: {
    title,
    table: {
      sort: { unit, list },
      button: {
        setting,
        update: { edit, save },
      },
      header,
    },
  },
} = admin;

const useProductForm = (productList) => {
  const [settlement, setSettlement] = useState(
    productList.map(
      ({
        productCode,
        price: { nationSettlement, abroadSettlement, japanSettlement },
      }) => {
        return {
          productCode,
          nationSettlement,
          abroadSettlement,
          japanSettlement,
        };
      }
    )
  );

  const changeSettlement = (e, idx, type) => {
    const { value } = e.target;
    const updateValue = numberWithoutCommas(value);

    if (isNaN(updateValue)) {
      return;
    }

    const settlementList = settlement.map((item, index) => {
      if (index === idx) {
        return {
          ...item,
          [type]: numberWithoutCommas(value),
        };
      }

      return item;
    });

    setSettlement(settlementList);
  };

  useEffect(() => {
    setSettlement(
      productList.map(
        ({
          productCode,
          price: { nationSettlement, abroadSettlement, japanSettlement },
        }) => {
          return {
            productCode,
            nationSettlement,
            abroadSettlement,
            japanSettlement,
          };
        }
      )
    );
  }, [productList]);

  return {
    settlement,
    changeSettlement,
  };
};

const Result = ({ productData }) => {
  const { checkedList, updateAll, updateChecked } = useCheckbox({
    initialState: false,
    length: productData.productSettlement.length,
  });
  const { settlement, changeSettlement } = useProductForm(
    productData.productSettlement
  );
  const [isEdit, toggleEdit] = useToggle(false);
  const [isModal, toggleModal] = useToggle(false);
  const productSettlementPriceMutation = useProductUpdateSettlementMutation();

  const submitProductSettlement = () => {
    productSettlementPriceMutation(settlement, toggleEdit);
  };

  const submitModalSettlement = (data, alert) => {
    const filterUpdateList = settlement
      .filter((_, idx) => checkedList[idx])
      .map((item) => {
        return {
          ...item,
          ...data,
        };
      });

    const successCallback = () => {
      alert();
      toggleModal();
    };

    productSettlementPriceMutation(filterUpdateList, successCallback);
  };

  return (
    <>
      <SubTitle isMargin>{title}</SubTitle>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Sort
              colspan={8}
              unit={unit}
              length={productData.totalItem}
              sortWidth={"120"}
              sortList={list}
            />
          </Table.Row>

          <Table.Row>
            <Table.Colspan colspan={8}>
              <ProductTableBox>
                <ProductSettingButton
                  onClick={() => checkedList.includes(true) && toggleModal()}
                >
                  {setting}
                </ProductSettingButton>

                <ProductTableButton
                  onClick={isEdit ? submitProductSettlement : toggleEdit}
                >
                  {isEdit ? save : edit}
                </ProductTableButton>
              </ProductTableBox>
            </Table.Colspan>
          </Table.Row>
          <Table.Row>
            <Table.Header width={"4%"}>
              <Checkbox>
                <Checkbox.Form option={{ onChange: updateAll }} />
              </Checkbox>
            </Table.Header>
            {header.map(({ text, width }) => (
              <Table.Header width={width} key={text}>
                {text}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <ProductResult
          productData={productData}
          isEdit={isEdit}
          checkedList={checkedList}
          settlement={settlement}
          updateChecked={updateChecked}
          changeSettlement={changeSettlement}
        />
      </Table>

      <ProductModal
        isShow={isModal}
        toggleModal={toggleModal}
        submitModal={submitModalSettlement}
      />
    </>
  );
};

export default Result;
