import { Button } from "@/styles/common";
import styled from "styled-components";

export const SearchContainer = styled.div`
  ${(props) => props.theme.medias.mobile} {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const SearchWrapper = styled.form`
  min-width: ${(props) => props.theme.pxToRem(500)};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SearchTable = styled.table`
  width: 100%;

  tr:first-child {
    td {
      border-top: ${(props) => props.theme.line} solid
        ${(props) => props.theme.colors.line};
    }

    td:first-child {
      border-top-left-radius: ${(props) => props.theme.radius.normal};
    }

    /* top-right border-radius */
    td:last-child {
      border-top-right-radius: ${(props) =>
        props.theme.radius.normal};
    }
  }

  td {
    height: ${(props) => props.theme.pxToRem(48)};
    padding: ${(props) =>
      `${props.theme.pxToRem(8)} ${props.theme.pxToRem(16)}`};

    vertical-align: middle;
  }
`;

export const SearchTableHead = styled.td`
  width: ${(props) => props.theme.pxToRem(120)};

  font-size: ${(props) => props.theme.pxToRem(13)};
  font-weight: 500;

  background: ${(props) => props.theme.colors.gray3};

  padding: ${(props) =>
    `${props.theme.pxToRem(14)} ${props.theme.pxToRem(16)}`};
`;

export const SearchTableContent = styled.td`
  width: ${(props) => `calc(100% - ${props.theme.pxToRem(120)})`};
`;

export const SearchCalendarBox = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin: 0 ${(props) => props.theme.pxToRem(10)};
  }
`;

export const SearchButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(120)};

  padding: ${(props) => props.theme.pxToRem(10)} 0;
  margin-top: ${(props) => props.theme.pxToRem(24)};
`;

export const SearchText = styled.span`
  font-size: ${(props) => props.theme.pxToRem(13)};
`;

export const SearchInputBox = styled.div`
  width: ${(props) => props.theme.pxToRem(600)};
`;

export const SearchSelectBox = styled.div`
  width: ${(props) => props.theme.pxToRem(300)};
`;

export const MultiSelectContainer = styled.div`
  margin: ${(props) => `${props.theme.pxToRem(8)} 0`};
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const MultiSelectItem = styled.span`
  display: inlie-block;
  padding: ${(props) => `${props.theme.pxToRem(4)}`};
  padding-left: ${(props) => `${props.theme.pxToRem(10)}`};
  padding-right: ${(props) => `${props.theme.pxToRem(20)}`};
  border: 1px solid rgb(122, 185, 243);
  color: ${(props) => `${props.theme.colors.white}`};
  background: ${(props) => `${props.theme.colors.primary}`};
  border-radius: 50px;
  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 50%;
    right: 5px;
    content: "X";
    transform: translate(0, -50%);
  }
`;
